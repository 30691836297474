import { gql } from '@apollo/client'

const UPDATE_PRODUCT_VARIANT = gql`
  mutation updateProductVariant(
    $variantId: uuid!
    $sku: String
    $calorieData: jsonb
    $limit: Int
    $restrictions: jsonb
  ) {
    update_product_variants(
      where: { id: { _eq: $variantId } }
      _set: {
        sku: $sku
        calorie_data: $calorieData
        limit: $limit
        restrictions: $restrictions
        updated_at: "now()"
      }
    ) {
      affected_rows
    }
  }
`

const UPSERT_PRODUCT_VARIANTS = gql`
  mutation upsertProductVariants($values: [product_variants_insert_input!]!) {
    insert_product_variants(
      objects: $values
      on_conflict: {
        constraint: product_variants_pkey
        update_columns: [sku, calorie_data, limit, restrictions, updated_at]
      }
    ) {
      affected_rows
    }
  }
`
const ADD_VARIANT_GROUP = gql`
  mutation addVariantGroup(
    $optionName: String!
    $optionValues: [String]!
    $productId: ID!
  ) {
    addVariantGroup(
      optionName: $optionName
      optionValues: $optionValues
      productId: $productId
    ) {
      name
    }
  }
`

const ADD_VARIANT_TO_GROUP = gql`
  mutation addVariantToGroup(
    $optionName: String!
    $optionValue: String!
    $productId: ID!
  ) {
    addVariantToGroup(
      optionName: $optionName
      optionValue: $optionValue
      productId: $productId
    ) {
      id
    }
  }
`

const REMOVE_PRODUCT_OPTION = gql`
  mutation removeProductOption($optionName: String!, $productId: ID!) {
    removeProductOption(optionName: $optionName, productId: $productId) {
      id
    }
  }
`

const ARCHIVE_VARIANT_OPTION = gql`
  mutation archiveVariantOptions(
    $productId: ID!
    $optionName: String!
    $optionValue: String!
  ) {
    archiveVariantOption(
      optionName: $optionName
      optionValue: $optionValue
      productId: $productId
    ) {
      id
    }
  }
`

const UPDATE_VARIANT_ALLERGENS = gql`
  mutation updateVariantAllergens(
    $productId: ID!
    $optionName: String!
    $optionValue: String!
    $optionAllergens: [String]
  ) {
    updateVariantAllergens(
      productId: $productId
      optionName: $optionName
      optionValue: $optionValue
      optionAllergens: $optionAllergens
    ) {
      name
      allergens
    }
  }
`

export {
  ADD_VARIANT_GROUP,
  ADD_VARIANT_TO_GROUP,
  UPDATE_PRODUCT_VARIANT,
  UPSERT_PRODUCT_VARIANTS,
  REMOVE_PRODUCT_OPTION,
  ARCHIVE_VARIANT_OPTION,
  UPDATE_VARIANT_ALLERGENS
}
