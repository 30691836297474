import { gql } from '@apollo/client'

const GET_DISCOUNT = gql`
  query GetDiscount($discount_id: uuid!, $slug: String!) {
    discounts(
      where: { id: { _eq: $discount_id }, merchant: { slug: { _eq: $slug } } }
    ) {
      id
      code
      description
      target
      type
      trigger
      value
      start_date
      end_date
      fulfillment_types
      quantity
      quantity_per_customer
      store_min_order_value_rule
      minimum_value
      published_at
      store_ids
      categories_discounts {
        category_id
      }
      products_discounts {
        product_id
        product {
          products_categories {
            category_id
          }
        }
      }
    }
  }
`

const CREATE_DISCOUNT = gql`
  mutation CreateDiscount(
    $code: String!
    $merchant_id: uuid!
    $target: DiscountTarget!
    $trigger: DiscountTrigger!
    $type: DiscountType!
    $value: Decimal!
    $description: String
    $quantity_per_customer: Int
    $quantity: Int
    $start_date: Date
    $end_date: Date
    $minimum_value: Decimal
    $published_at: utc_timestamp
    $store_ids: [uuid!]
    $product_ids: [uuid!]
    $category_ids: [uuid!]
    $store_min_order_value_rule: StoreMinOrderValueRule
    $fulfillment_types: [FulfillmentTypesParams]
  ) {
    createDiscount(
      code: $code
      merchantId: $merchant_id
      target: $target
      trigger: $trigger
      type: $type
      value: $value
      description: $description
      quantityPerCustomer: $quantity_per_customer
      quantity: $quantity
      startDate: $start_date
      endDate: $end_date
      minimumValue: $minimum_value
      publishedAt: $published_at
      storeIds: $store_ids
      productIds: $product_ids
      categoryIds: $category_ids
      storeMinOrderValueRule: $store_min_order_value_rule
      fulfillmentTypes: $fulfillment_types
    ) {
      id
    }
  }
`

const UPDATE_DISCOUNT = gql`
  mutation UpdateDiscount(
    $discount_id: uuid!
    $code: String!
    $merchant_id: uuid!
    $target: DiscountTarget!
    $trigger: DiscountTrigger!
    $type: DiscountType!
    $value: Decimal!
    $description: String
    $quantity_per_customer: Int
    $quantity: Int
    $start_date: Date
    $end_date: Date
    $minimum_value: Decimal
    $published_at: utc_timestamp
    $store_ids: [uuid!]
    $product_ids: [uuid!]
    $category_ids: [uuid!]
    $store_min_order_value_rule: StoreMinOrderValueRule
    $fulfillment_types: [FulfillmentTypesParams]
  ) {
    updateDiscount(
      discountId: $discount_id
      code: $code
      merchantId: $merchant_id
      target: $target
      trigger: $trigger
      type: $type
      value: $value
      description: $description
      quantityPerCustomer: $quantity_per_customer
      quantity: $quantity
      startDate: $start_date
      endDate: $end_date
      minimumValue: $minimum_value
      publishedAt: $published_at
      storeIds: $store_ids
      productIds: $product_ids
      categoryIds: $category_ids
      storeMinOrderValueRule: $store_min_order_value_rule
      fulfillmentTypes: $fulfillment_types
    ) {
      id
    }
  }
`

const GET_MERCHANT_STORES = gql`
  query getSameDayOrderStores($merchantId: uuid!) {
    stores(
      where: {
        archived_at: { _is_null: true }
        merchant_id: { _eq: $merchantId }
      }
    ) {
      id
      slug
      name
    }
  }
`

const GET_CATEGORIES = gql`
  query getCategories($merchantId: uuid!) {
    categories(
      where: {
        archived_at: { _is_null: true }
        merchant_id: { _eq: $merchantId }
      }
      order_by: { name: asc }
    ) {
      value: id
      label: name
    }
  }
`

const GET_PRODUCTS = gql`
  query getProducts($merchantId: uuid!) {
    products(
      where: {
        archived_at: { _is_null: true }
        merchant_id: { _eq: $merchantId }
      }
      order_by: { name: asc }
    ) {
      value: id
      label: name
    }
    categories(
      where: {
        archived_at: { _is_null: true }
        merchant_id: { _eq: $merchantId }
      }
      order_by: { name: asc }
    ) {
      id
      name
    }
  }
`

const TOGGLE_DISCOUNT_ACTIVATION_STATUS = gql`
  mutation toggleDiscountActivationStatus(
    $merchantId: uuid!
    $discountId: uuid!
    $published_at: timestamp
  ) {
    update_discounts(
      where: { merchant_id: { _eq: $merchantId }, id: { _eq: $discountId } }
      _set: { published_at: $published_at, updated_at: "now()" }
    ) {
      affected_rows
    }
  }
`

export {
  GET_DISCOUNT,
  CREATE_DISCOUNT,
  UPDATE_DISCOUNT,
  GET_MERCHANT_STORES,
  GET_CATEGORIES,
  GET_PRODUCTS,
  TOGGLE_DISCOUNT_ACTIVATION_STATUS
}
