import React, { useEffect, useState } from 'react'
import {
  Form,
  Input,
  Switch,
  Button,
  Divider,
  message,
  Row,
  Col,
  Tabs,
  Tooltip
} from 'antd'
import { useMutation, useApolloClient } from '@apollo/client'
import { UPDATE_SETTINGS } from './actions'
import UpdateNotice from '../Utils/UpdateNotice'
import { LocationSettingProps } from './Settings'
import QRCode from 'react-qr-code'
import { useSession, isAdmin, isManager } from '@slerp/accounts'
import { PageTooltip } from './../Widgets'
import { onBlurScrollNumber, onFocusScrollNumber } from 'components/Utils/price'
import { QuestionCircleOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'

const { useForm } = Form
const { TabPane } = Tabs

const TableSettings = (props: LocationSettingProps) => {
  const { merchant, store } = props
  const [form] = useForm()
  const [updateSetting, { loading, error }] = useMutation(UPDATE_SETTINGS, {
    fetchPolicy: 'no-cache'
  })
  const client = useApolloClient()

  const { merchant: merchantSession, user } = useSession()
  const canManage = isAdmin(user) || isManager(user)
  const tableUrl = store.settings.table_enabled
    ? `https://${merchantSession.slug}.slerp.com/order/table/${store.slug}`
    : undefined

  const [isTableCountRequired, setIsTableCountRequired] = useState<boolean>(
    !store?.settings?.table_choices
  )
  const [isPickupMessageRequired, setIsPickupMessageRequired] =
    useState<boolean>(store?.settings?.table_counter_pickup)

  useEffect(() => {
    const tableCoundInput = document.getElementById('tableCountInput')

    if (!tableCoundInput) {
      return
    }

    const inputHandler = (e: Event) => {
      const target = e.target as HTMLInputElement

      if (target.value === '0') {
        target.value = ''
      }

      tableCoundInput.setCustomValidity('')
    }

    const invalidHandler = () => {
      tableCoundInput.setCustomValidity('Please fill in a value of minimum 1.')
    }

    tableCoundInput.addEventListener('input', inputHandler)
    tableCoundInput.addEventListener('invalid', invalidHandler)

    return () => {
      tableCoundInput.removeEventListener('input', inputHandler)
      tableCoundInput.removeEventListener('invalid', invalidHandler)
    }
  }, [])

  useEffect(() => {
    const tableChoicesInput = document.getElementById('tableChoicesInput')
    const tableCoundInput = document.getElementById('tableCountInput')

    if (!tableChoicesInput) {
      return
    }

    const inputHandler = () => {
      tableChoicesInput.setCustomValidity('')
      tableCoundInput.setCustomValidity('')
    }

    const invalidHandler = () => {
      if (tableChoicesInput.validity.patternMismatch) {
        tableChoicesInput.setCustomValidity(
          'Invalid format. Use only comma-separated alphanumeric values without spaces (e.g. 1A,4A,5A).'
        )
      }
      tableCoundInput.setCustomValidity('')
    }

    tableChoicesInput.addEventListener('input', inputHandler)
    tableChoicesInput.addEventListener('invalid', invalidHandler)

    return () => {
      tableChoicesInput.removeEventListener('input', inputHandler)
      tableChoicesInput.removeEventListener('invalid', invalidHandler)
    }
  }, [])

  useEffect(() => {
    const tableCounterPickupMessage = document.getElementById(
      'tableCounterPickupMessage'
    )

    if (!tableCounterPickupMessage) {
      return
    }

    const inputHandler = () => {
      tableCounterPickupMessage.setCustomValidity('')
    }

    const invalidHandler = () => {
      tableCounterPickupMessage.setCustomValidity('Please fill in a message.')
    }

    tableCounterPickupMessage.addEventListener('input', inputHandler)
    tableCounterPickupMessage.addEventListener('invalid', invalidHandler)

    return () => {
      tableCounterPickupMessage.removeEventListener('input', inputHandler)
      tableCounterPickupMessage.removeEventListener('invalid', invalidHandler)
    }
  }, [])

  useEffect(() => {
    const tableCounterPickupMessage = document.getElementById(
      'tableCounterPickupMessage'
    )

    if (!tableCounterPickupMessage || isPickupMessageRequired) {
      return
    }

    tableCounterPickupMessage.setCustomValidity('')
  }, [isPickupMessageRequired])

  const onFinish = (values: any) => {
    const {
      table_enabled,
      table_welcome_message,
      table_count,
      table_counter_pickup,
      table_counter_pickup_message,
      table_choices
    } = values

    if (form.validateFields()) {
      message.loading('Updating... Please wait.')
      updateSetting({
        variables: {
          merchant_id: merchant.id,
          id: store.id,
          settings: {
            table_enabled,
            table_welcome_message,
            table_count: table_count || null,
            table_counter_pickup,
            table_counter_pickup_message,
            table_choices: table_choices || null
          }
        }
      }).then((result) => {
        message.destroy()
        client.resetStore()
        message.success('Order at Table Settings Updated', 1)
      })
    } else {
      message.destroy()
      message.error('Unable to update the table settings. Please try again.')
    }
  }

  return (
    <>
      <Tabs defaultActiveKey='table-settings'>
        <TabPane key='table-settings' tab='Settings'>
          <Row gutter={40} className='_mt-24 _pl-16'>
            <Col span={15}>
              <Form
                data-testid='table-settings-form'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                form={form}
                onFinish={onFinish}
              >
                <Form.Item name='table_enabled' label='Enable Order at Table'>
                  <Switch
                    defaultChecked={store.settings.table_enabled}
                    disabled={!canManage}
                  />
                </Form.Item>
                <Form.Item
                  name='table_welcome_message'
                  label={
                    <Label>
                      <span>Welcome Message</span>
                      <Tooltip title="If left empty, the default message 'Order at Table' will be displayed to customers.">
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </Label>
                  }
                >
                  <Input
                    data-testid='welcome-message-field'
                    defaultValue={store.settings.table_welcome_message}
                    placeholder='Order at table'
                    disabled={!canManage}
                  />
                </Form.Item>
                {store.settings.table_enabled && (
                  <Form.Item name='table_url' label='URL'>
                    <Input defaultValue={tableUrl} disabled={!canManage} />
                  </Form.Item>
                )}

                <Form.Item
                  name='table_choices'
                  label='Table Choices'
                  help='Enter comma-separated values for table identifiers (e.g. 1A,4A,5A). Only alphanumeric values are allowed, and no spaces. These custom identifiers will override the automatically generated table numbers.'
                >
                  <Input
                    id='tableChoicesInput'
                    type='string'
                    onChange={(e) => setIsTableCountRequired(!e.target.value)}
                    defaultValue={store.settings.table_choices}
                    pattern='[a-zA-Z0-9,]+'
                    required={false}
                    disabled={!canManage}
                  />
                </Form.Item>

                <Form.Item
                  name='table_count'
                  label='Table Count'
                  help="Specify the total number of tables to auto-generate sequential table numbers (e.g. 1, 2, 3…). This setting is ignored if custom identifiers are provided in 'Table Choices'."
                >
                  <Input
                    id='tableCountInput'
                    data-testid='table-count-field'
                    type='number'
                    defaultValue={store.settings.table_count || ''}
                    min={1}
                    required={isTableCountRequired}
                    disabled={!canManage}
                    onFocus={onFocusScrollNumber}
                    onBlur={onBlurScrollNumber}
                  />
                </Form.Item>

                <Form.Item
                  name='table_counter_pickup'
                  label='Allow Counter Pick-up'
                >
                  <Switch
                    onChange={(value) => setIsPickupMessageRequired(value)}
                    defaultChecked={store.settings.table_counter_pickup}
                    disabled={!canManage}
                  />
                </Form.Item>

                <Form.Item
                  name='table_counter_pickup_message'
                  label={
                    <Label>
                      <span>Pick-up at Counter Message</span>
                      <Tooltip title="For example, you can add: 'Please order and pick up at the counter with your order number.">
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </Label>
                  }
                >
                  <Input
                    id='tableCounterPickupMessage'
                    required={isPickupMessageRequired}
                    defaultValue={
                      store.settings.table_counter_pickup_message ||
                      'Please order and collect at the counter with your order number.'
                    }
                    disabled={!canManage}
                  />
                </Form.Item>

                <Form.Item className='_mt-32 _mb-40'>
                  <Button
                    title='Save'
                    htmlType='submit'
                    className='_ml-auto _center-vertical'
                    disabled={!canManage}
                  >
                    Save
                  </Button>
                  <UpdateNotice updating={loading} updateError={error} />
                </Form.Item>
              </Form>

              {store.settings.table_enabled && (
                <Row>
                  <Col className='_mb-40'>
                    {tableUrl && (
                      <>
                        <Label className='_mb-8'>
                          <span>Auto-Generated QR Code</span>
                          <Tooltip title='Your customers can scan this QR code to order and pay directly via their phone.'>
                            <QuestionCircleOutlined />
                          </Tooltip>
                        </Label>
                        <QRCode value={tableUrl} />
                      </>
                    )}
                  </Col>
                </Row>
              )}
            </Col>
            <Col>
              <Divider type='vertical' />
            </Col>
            <Col>
              <PageTooltip title='Related articles from our Help Centre'>
                <a
                  href='https://support.slerp.com/knowledge/order-at-table'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Order at Table Overview
                </a>
              </PageTooltip>
            </Col>
          </Row>
        </TabPane>
      </Tabs>
    </>
  )
}

const Label = styled.div`
  color: #141323;
  display: flex;
  gap: 8px;
  align-items: center;
`

export default TableSettings
