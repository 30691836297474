const hoursRangeList = (startHour: number, endHour: number) => {
  let runningHour = startHour
  const result = []

  while (runningHour < endHour) {
    const stateOfMeridiem = (timeOfDay: number) =>
      timeOfDay >= 12 ? 'PM' : 'AM'
    const hourFormat = (hour: number) => (hour > 12 ? hour % 12 : hour)

    result.push(
      `${hourFormat(runningHour)}:00 ${stateOfMeridiem(
        runningHour
      )} - ${hourFormat(runningHour)}:30 ${stateOfMeridiem(runningHour)}`
    )
    result.push(
      `${hourFormat(runningHour)}:30 ${stateOfMeridiem(
        runningHour
      )} - ${hourFormat(runningHour + 1)}:00 ${stateOfMeridiem(
        runningHour + 1
      )}`
    )
    runningHour++
  }
  return result
}

const print = (orderId: string) => {
  const url = `${window.location.origin}/print-order/${orderId}`
  const printPage = window.open(url, '_blank')
  if (printPage) printPage.onafterprint = printPage.close
}

export { hoursRangeList, print }
