import React, { useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useSession } from '@slerp/accounts'
import { QUERY_MERCHANT_INVOICES } from '../SettingsQueries'
import { Table, Tag, Button, Row, Col } from 'antd'
import {
  InvoiceStatus,
  Invoice,
  SelectFilterVariant,
  Pagination
} from './types'
import {
  getStatusColor,
  getBillingPeriod,
  getInvoiceParams,
  formatDate
} from './utils'
import styled from '@emotion/styled'
import { DownloadIcon } from 'packages/@slerp/assets'
import SelectFilter from './InvoiceFilter'
import { formatMoney } from 'packages/@slerp/designer-rework/shop/components/Cart/utils'

enum SortDirectionOptions {
  ascend = 'ASC',
  descend = 'DESC'
}

const Invoices = () => {
  const { merchant, user } = useSession()

  const [invoices, setInvoices] = React.useState<Invoice[]>([])
  const [pagination, setPagination] = React.useState<Pagination | null>()
  const [selectedStatus, setSelectedStatus] = React.useState<string[]>([])
  const [sortDirection, setSortDirection] =
    React.useState<SortDirectionOptions>(SortDirectionOptions.descend)

  const [fetchInvoices, { loading }] = useLazyQuery(QUERY_MERCHANT_INVOICES, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setInvoices(data.listInvoices.invoices)

      if (data.listInvoices.invoices.length < 10) {
        setPagination(null)
        return
      }

      setPagination(data.listInvoices.pagination)
    }
  })

  const [fetchMoreInvoices, { loading: loadingMore }] = useLazyQuery(
    QUERY_MERCHANT_INVOICES,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        setInvoices((prev) =>
          [...prev, ...data.listInvoices.invoices].filter((value) => !!value)
        )
        setPagination(data.listInvoices.pagination)
      }
    }
  )

  useEffect(() => {
    fetchInvoices({
      variables: getInvoiceParams({
        merchantId: merchant.id,
        paymentStatus: selectedStatus,
        sortBy: 'ACCOUNTING_DATE',
        sortDirection
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStatus, sortDirection])

  const COLUMNS = [
    {
      title: 'Invoice #',
      dataIndex: 'invoiceReference',
      key: 'invoiceReference',
      render: (reference: string) => <CellSpan>#{reference}</CellSpan>
    },
    {
      title: (
        <div style={{ textAlign: 'center' }}>
          <span>Status</span>
        </div>
      ),
      dataIndex: 'status',
      render: (status: InvoiceStatus) => (
        <Row justify='center' align='middle' style={{ marginBottom: 0 }}>
          <Col>
            <StatusTag text={getStatusColor(status)}>
              {status.toLowerCase()}
            </StatusTag>
          </Col>
        </Row>
      )
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (amount: string) => (
        <CellSpan>{formatMoney(parseFloat(amount))}</CellSpan>
      )
    },
    {
      title: 'Billing Period',
      render: (record: Invoice) => {
        return (
          <CellSpan>
            {getBillingPeriod(
              record.billingPeriodStart,
              record.billingPeriodEnd
            )}
          </CellSpan>
        )
      }
    },
    {
      title: (
        <SorterTitle
          onClick={() =>
            sortDirection === SortDirectionOptions.ascend
              ? setSortDirection(SortDirectionOptions.descend)
              : setSortDirection(SortDirectionOptions.ascend)
          }
        >
          <div>
            <span>Date</span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <TableSorter
              type='asc'
              isActive={sortDirection === SortDirectionOptions.ascend}
            />
            <TableSorter
              type='desc'
              isActive={sortDirection === SortDirectionOptions.descend}
            />
          </div>
        </SorterTitle>
      ),
      dataIndex: 'date',
      render: (date: string) => <CellSpan>{formatDate(date)}</CellSpan>
    },
    {
      title: 'Due',
      dataIndex: 'due',
      render: (due: string) => <CellSpan>{formatDate(due)}</CellSpan>
    },
    {
      title: (
        <div style={{ textAlign: 'center' }}>
          <span>Action</span>
        </div>
      ),
      dataIndex: 'downloadLink',
      render: (downloadLink: string) => (
        <Row justify='center' align='middle' style={{ marginBottom: 0 }}>
          <Col>
            <a
              href={`${downloadLink}?token=${user.api_key}`}
              target='_blank'
              rel='noreferrer'
            >
              <StyledButton>
                <DownloadIcon style={{ fontSize: '16px', marginTop: '2px' }} />
              </StyledButton>
            </a>
          </Col>
        </Row>
      )
    }
  ]

  const fetchNext = () => {
    if (!pagination?.after) {
      return
    }

    fetchMoreInvoices({
      variables: getInvoiceParams({
        merchantId: merchant.id,
        paymentStatus: selectedStatus,
        after: pagination.after,
        sortBy: 'ACCOUNTING_DATE',
        sortDirection
      })
    })
  }

  return (
    <>
      <Row>
        <Col>
          <SelectFilter
            setSelected={setSelectedStatus}
            selected={selectedStatus}
            variant={SelectFilterVariant.status}
          />
        </Col>
      </Row>
      <Table
        data-testid='campaigns-history-table'
        dataSource={invoices}
        columns={COLUMNS}
        pagination={false}
        loading={loading || loadingMore}
        rowKey='invoiceReference'
      />
      <Row style={{ marginTop: '10px' }} gutter={10} justify='end'>
        {pagination?.after && (
          <Col>
            <Button onClick={fetchNext}>Load more</Button>
          </Col>
        )}
      </Row>
    </>
  )
}

const SorterTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`

const TableSorter = styled.div(
  ({ type, isActive }: { type: string; isActive: boolean }) => `
  position: relative;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #4a4a68;
  opacity: ${isActive ? 1 : 0.5};
  transform: rotate(${type === 'asc' ? '180deg' : '0deg'});

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #f3f2f4;
    opacity: 1;
    top: -6px;
    left: -4px;
  }
`
)

const CellSpan = styled.span`
  color: #4a4a68;
  font-size: 13px;
  font-weight: 500;
`

const StatusTag = styled(Tag)(
  ({ text }: { text: string }) => `
  background-color: #fff;
  color: ${text};
  border: 1px solid #DBD9DF;
  text-transform: capitalize;
`
)

const StyledButton = styled(Button)`
  width: 28px;
  height: 28px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default Invoices
