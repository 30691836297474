import React, { useState, useEffect } from 'react'
import { Col, Table, Button, Spin, message, Row, Tooltip } from 'antd'
import { useLocation } from 'react-router-dom'
import OrderDrawer from '../Orders/OrderDrawer'
import { OrderStatusTag } from '../Orders/OrderStatuses'
import { Link } from 'react-router-dom'
import FormattedDate from '../Utils/FormattedDate'
import { ACCEPT_ORDERS, COMPLETE_ORDERS } from './OrderQueries'
import { useApolloClient, useMutation } from '@apollo/client'
import { useSession } from '@slerp/accounts'
import { PrinterOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { print } from './utils'

interface OrderStore {
  name: string
  address: {
    zip: string
  }
}

interface Order {
  store_id: string
  transaction_id: string
  key: string
  store: OrderStore
  subtotal: number
  total: number
  fulfillment_type: 'delivery' | 'pickup'
  fulfillment_date: string
  create_device_category: null | string
  create_platform: null | string
  create_os_type: null | string
  status: 'pending' | 'accepted' | 'fulfilled' | 'refunded' | 'rejected'
  payment_status: null | 'captured' | 'partially_refunded' | 'fully_refunded'
  inserted_at: string
}

interface OrdersListProps {
  orders: Order[]
  loading?: boolean
  refetch?: () => {}
}

interface OrderLinkProps {
  text: string
  onClick: () => void
}

const setSearchParams = (location: LocationType) => {
  return new URLSearchParams(location.search)
}

const humanizePlatform = (str: string) => {
  return str === 'ios' ? 'iOS' : str?.charAt(0).toUpperCase() + str?.slice(1)
}

export const OrderLink = ({ onClick, text }: OrderLinkProps) => (
  <Link to={`#`} onClick={onClick}>
    {text}
  </Link>
)

const OrdersList = (props: OrdersListProps) => {
  const client = useApolloClient()
  const { orders, loading } = props
  const { user } = useSession()
  const searchParams = setSearchParams(useLocation())
  const transactionId = searchParams.get('transaction_id') || ''

  const columns = [
    {
      title: (
        <>
          Order ID
          <Tooltip
            title={
              <span>Click the order's ID number to see full details.</span>
            }
          >
            <QuestionCircleOutlined className='_ml-8 _mr-8' />
          </Tooltip>
        </>
      ),
      dataIndex: 'transaction_id',
      key: 'key',
      render: (text: string, record: Order) => {
        return (
          <span className='transaction-id'>
            <OrderLink
              text={text}
              onClick={() => {
                showDrawer(record)
              }}
            />
          </span>
        )
      }
    },
    {
      title: 'Location',
      dataIndex: ['store', 'name'],
      key: 'store.name',
      render: (storeName: string, order: Order) => {
        return (
          <div className='order-store-info'>
            <p className='name'>{storeName}</p>
            <p className='zip'>{order?.store?.address?.zip}</p>
          </div>
        )
      }
    },
    {
      title: 'Type',
      dataIndex: 'fulfillment_type',
      key: 'fulfillment_type',
      render: (fulfillmentType: 'pickup' | 'delivery') => (
        <span className='order-fulfillment-type'>{fulfillmentType}</span>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (status: string, order: Order) =>
        OrderStatusTag(status, order?.payment_status)
    },
    {
      title: 'SubTotal',
      dataIndex: 'subtotal',
      key: 'subtotal',
      render: (subtotal: string) => <span>£{subtotal}</span>
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: (total: string) => <span>£{total}</span>
    },
    {
      title: <div className='_text-right'>Ordered At</div>,
      dataIndex: 'inserted_at',
      key: 'inserted_at',
      render: (orderedAt: Date) => {
        return (
          <span className='_text-right'>
            <FormattedDate>{orderedAt}</FormattedDate>
          </span>
        )
      }
    },
    {
      title: <div className='_text-right'>Fulfilment Date</div>,
      dataIndex: 'fulfillment_date',
      key: 'fulfillment_date',
      render: (fulfillmentDate: Date) => (
        <div className='_text-right'>
          {fulfillmentDate ? (
            <FormattedDate>{fulfillmentDate}</FormattedDate>
          ) : (
            'ASAP'
          )}
        </div>
      )
    },
    {
      title: 'Platform',
      dataIndex: 'platform',
      key: 'platform',
      render: (platform: string, order: Order) => (
        <span className='order-platform'>
          {humanizePlatform(order?.create_platform)} -{' '}
          {humanizePlatform(order?.create_device_category)}
        </span>
      )
    },
    {
      align: 'right',
      title: 'Print',
      dataIndex: 'transaction_id',
      key: 'print',
      render: (orderId: string) => (
        <Button
          size='small'
          icon={<PrinterOutlined />}
          onClick={() => print(orderId)}
        />
      )
    }
  ]

  const [activeOrder, setActiveOrder] = useState(null)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [selectedPendingOrders, setSelectedPendingOrders] = useState<
    Array<string>
  >([])
  const [selectedAcceptedOrders, setSelectedAcceptedOrders] = useState<
    Array<string>
  >([])

  const showDrawer = (record: Order) => {
    setDrawerVisible(true)
    setActiveOrder(record)
    return true
  }

  const onDrawerClose = () => {
    setDrawerVisible(false)
    setActiveOrder(null)
  }

  const onSelectChange = (
    transactionIds: Array<String>,
    orders: Array<Order>
  ) => {
    const pendingOrders = orders.filter(
      (order: Order) => order.status === 'pending'
    )
    const acceptedOrders = orders.filter(
      (order: Order) => order.status === 'accepted'
    )
    setSelectedPendingOrders(
      pendingOrders.map((pendingOrder: order) => pendingOrder.transaction_id)
    )
    setSelectedAcceptedOrders(
      acceptedOrders.map((acceptedOrder: order) => acceptedOrder.transaction_id)
    )
  }

  const rowSelection = {
    selectedRowKeys: [...selectedPendingOrders, ...selectedAcceptedOrders],
    onChange: onSelectChange,
    getCheckboxProps: ({ status }: string) => ({
      disabled: !['pending', 'accepted'].includes(status)
    })
  }

  // Reset selection when we change the date
  useEffect(() => {
    setSelectedPendingOrders([])
    setSelectedAcceptedOrders([])
  }, [orders && orders.length])

  useEffect(() => {
    if (transactionId && orders) {
      const order = orders.filter(
        (ord) => ord.transaction_id === transactionId
      )[0]
      showDrawer(order)
    }
  }, [transactionId, orders])

  const [acceptOrders, { loading: acceptLoading, error: acceptError }] =
    useMutation(ACCEPT_ORDERS, {
      fetchPolicy: 'no-cache'
    })

  const [
    completeOrders,
    { loading: completingOrdersLoading, error: completingOrdersError }
  ] = useMutation(COMPLETE_ORDERS, {
    fetchPolicy: 'no-cache'
  })

  const acceptOrdersHandler = (e: React.MouseEvent) => {
    if (selectedPendingOrders.length > 0) {
      message.loading('Updating...')
      acceptOrders({
        variables: { transactionIds: selectedPendingOrders, userId: user.id }
      })
        .then((result) => {
          setSelectedPendingOrders([])
          client.resetStore()
        })
        .catch((error: Error) => {
          message.destroy()
          message.error(
            `Bulk accept orders encountered an error ${error.message}`,
            3
          )
        })
    }
  }

  const setOrderToCompleteHandler = (e: React.MouseEvent) => {
    if (selectedAcceptedOrders.length > 0) {
      message.loading('Updating...')

      completeOrders({
        variables: { transactionIds: selectedAcceptedOrders, userId: user.id }
      })
        .then((result) => {
          setSelectedAcceptedOrders([])
          client.resetStore()
        })
        .catch((error: Error) => {
          message.destroy()
          message.error(
            `Bulk fulfill orders encountered an error ${error.message}`,
            3
          )
        })
    }
  }

  const ordersInfo = () => (
    <>
      {selectedPendingOrders.length > 0 && (
        <Row gutter={[8, 8]} align='middle'>
          <Col>
            Total pending orders selected: {selectedPendingOrders.length}
          </Col>
          <Col>
            <Button
              type='primary'
              size='small'
              onClick={acceptOrdersHandler}
              data-testid='order-list-bulk-accept-order-btn'
            >
              Accept
            </Button>
          </Col>
          {acceptLoading ? (
            <Col>
              Updating... <Spin />
            </Col>
          ) : (
            <></>
          )}
          {acceptError ? (
            <Col> - Error updating... Please try again later.</Col>
          ) : (
            <></>
          )}
        </Row>
      )}
      {selectedAcceptedOrders.length > 0 && (
        <Row gutter={[8, 8]} align='middle'>
          <Col>
            Total accepted orders selected: {selectedAcceptedOrders.length}
          </Col>
          <Col>
            <Button
              type='primary'
              size='small'
              data-testid='order-list-bulk-fulfill-order-btn'
              onClick={setOrderToCompleteHandler}
            >
              Fulfill
            </Button>
          </Col>
          {completingOrdersLoading ? (
            <Col>
              Updating... <Spin />
            </Col>
          ) : (
            <></>
          )}
          {completingOrdersError ? (
            <Col> - Error updating... Please try again later.</Col>
          ) : (
            <></>
          )}
        </Row>
      )}
    </>
  )

  return (
    <>
      <div style={{ marginBottom: 15 }}>{ordersInfo()}</div>
      <Table
        data-testid='orders-list-table'
        rowSelection={rowSelection}
        dataSource={orders}
        columns={columns}
        pagination={{
          defaultPageSize: 100,
          position: ['bottomCenter'],
          showSizeChanger: true
        }}
        loading={loading}
        footer={ordersInfo}
      />

      <OrderDrawer
        order={activeOrder}
        visible={drawerVisible}
        onClose={onDrawerClose}
        ordersRefetch={props.refetch}
      />
    </>
  )
}

export default OrdersList
