export type Invoice = {
  amount: string
  billingPeriodEnd: null | string
  billingPeriodStart: null | string
  date: string
  downloadLink: string
  due: string
  invoiceReference: string
  status: InvoiceStatus
}

export type Pagination = {
  after: string
  before: string
  perPage: string
  totalItems: string
  totalPages: string
}

export enum SelectFilterVariant {
  status = 'STATUS'
}

export enum InvoiceStatus {
  paid = 'PAID',
  unpaid = 'UNPAID'
}

export type Card = {
  id: string
  last4: string
  expMonth: number
  expYear: number
  expired: boolean
  defaultSource: string
}

export type AttributionFormObject = {
  nationwideDelivery: number
  delivery: number
  pickup: number
  selfDelivery: number
  table: number
  storeIds: string[]
}

export type Location = { name: string; id: string; hasStripeId: boolean }

export type AttributionKeys =
  | 'nationwideDelivery'
  | 'delivery'
  | 'pickup'
  | 'selfDelivery'
  | 'table'

export type CommissionRateKeys =
  | 'delivery'
  | 'same_day_self_delivery'
  | 'preorder_delivery'
  | 'preorder_self_delivery'
  | 'nationwide_delivery'
  | 'pickup'
  | 'preorder_pickup'
  | 'table'
