import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { SlerpLogo } from '@slerp/assets'
import {
  Input,
  Form,
  Button,
  Col,
  Row,
  Layout,
  Collapse,
  message,
  Select,
  InputNumber,
  Radio,
  Space,
  Checkbox
} from 'antd'
import {
  requiredRule,
  noWhiteSpaceRule,
  slugInputRule,
  nameRule
} from './rules'
import { slugify } from '@slerp/helpers'
import { publishNewMerchant, PublishNewMerchantParams } from '@slerp/accounts'
import { Redirect } from 'react-router-dom'
import { StepIndicator } from 'components/Utils'
import { companySuffixRule } from 'components/Settings/Rules'
import { ApolloError } from '@apollo/client'
import { checkboxDataSanitizer, handleCompanyWebsiteFormat } from './utils'
import {
  trackPixelLead,
  trackUserActionsGA4,
  usePageViewTracker
} from 'packages/@slerp/tracker'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import TextArea from 'antd/lib/input/TextArea'
import { UKPhoneValidatorRule } from 'components/Settings/GeneralSettings'
import { onBlurScrollNumber, onFocusScrollNumber } from 'components/Utils/price'
import { addressValidatorRule } from 'components/Locations/LocationForm/rules'
import AddressInput from 'components/Locations/LocationForm/AddressInput'

type CreateYourSlerpSpace = Array<
  | 'merchant_name'
  | 'slug'
  | 'setting_email'
  | 'setting_website'
  | 'registered_company_name'
  | 'firstname'
  | 'email'
  | 'firstname'
  | 'lastname'
  | 'instagram'
  | 'phone'
  | 'registered_company_address'
>

type CompanyDetails = Array<
  'trading_since' | 'location_count' | 'has_expansion_plans' | 'is_franchisee'
>

type DigitalChannels = Array<
  | 'delivery_marketplaces'
  | 'orders_per_week'
  | 'average_order_value'
  | 'existing_white_label_solution'
>

type FinalQuestions = Array<
  | 'has_crm'
  | 'has_loyalty'
  | 'has_paid_marketing_ads'
  | 'pos_system'
  | 'how_can_we_help'
  | 'live_at'
  | 'cyss_pos'
>

type EnterYourPassword = Array<'password'>

type PublishNewMerchantErrors =
  | 'unique_merchant_slug_constraint'
  | 'unique_user_email_constraint'
  | 'exceeded_40_character_limit'
  | 'invalid_stripe_account'
  | 'no_registration_details'
  | 'multiple_default_transaction_plans'
  | 'no_default_transaction_plan'
  | 'invalid_hutk'
  | 'unknown_error'

interface FormError {
  errorFields: Record<'name', CreateYourSlerpSpace>[] &
    Record<'errors', EnterYourPassword>[]
  values: Record<string, CreateYourSlerpSpace & EnterYourPassword>
  outOfDate: boolean
}

const CREATE_YOUR_SLERPSPACE_FIELDS: CreateYourSlerpSpace = [
  'merchant_name',
  'slug',
  'setting_email',
  'setting_website',
  'registered_company_name',
  'firstname',
  'lastname',
  'email',
  'instagram',
  'phone',
  'registered_company_address'
]
const COMPANY_DETAILS_FIELDS: CompanyDetails = [
  'trading_since',
  'location_count',
  'has_expansion_plans',
  'is_franchisee'
]
const DIGITAL_CHANNELS_FIELDS: DigitalChannels = [
  'delivery_marketplaces',
  'orders_per_week',
  'average_order_value',
  'existing_white_label_solution'
]
const FINAL_QUESTIONS_FIELDS: FinalQuestions = [
  'has_crm',
  'has_loyalty',
  'has_paid_marketing_ads',
  'pos_system',
  'how_can_we_help',
  'live_at',
  'cyss_pos'
]
const ENTER_YOUR_PASSWORD_FIELDS: EnterYourPassword = ['password']

const PUBLISH_ONBOARDING_ERROR_LIST: Record<PublishNewMerchantErrors, string> =
  {
    unique_merchant_slug_constraint:
      'This Slerpspace has already been registered.',
    unique_user_email_constraint:
      'This user has an account already, please try a different email.',
    exceeded_40_character_limit: 'Slug has exceeded the 40 character limit.',
    invalid_stripe_account:
      'The provided stripe key does not have access to account (or that account does not exist). Application access may have been revoked.',
    no_registration_details: 'No registration details found.',
    multiple_default_transaction_plans: 'Multiple default transaction plans.',
    no_default_transaction_plan: 'No default transaction plan.',
    invalid_hutk:
      'Invalid hubspot tracking code. Please disable your adblocker, do a hard refresh or clear your cookies.',
    unknown_error: 'Unknown error opon submit, please contact support.'
  }

const OnboardingForm = () => {
  usePageViewTracker()
  document.title = 'Slerp Controls - Create Your Slerpspace'
  const { useForm } = Form
  const { Header } = Layout
  const { Panel } = Collapse
  const firstStepRef = useRef(null)
  const secondStepRef = useRef(null)
  const thirdStepRef = useRef(null)
  const fourthStepRef = useRef(null)
  const fifthStepRef = useRef(null)

  const [form] = useForm()
  const [activeKey, setActiveKey] = useState<string>('')
  const [stepState, setStepState] = useState<
    Record<
      'step-1' | 'step-2' | 'step-3' | 'step-4' | 'step-5',
      'inactive' | 'finished'
    >
  >({
    'step-1': 'inactive',
    'step-2': 'inactive',
    'step-3': 'inactive',
    'step-4': 'inactive',
    'step-5': 'inactive'
  })
  const [deliveryMarketplaces, setDeliveryMarketplaces] = useState<
    'Yes' | 'No'
  >('Yes')
  const [whiteLabelSolution, setWhiteLabelSolution] = useState<
    CheckboxValueType[]
  >([])

  const [sso, setSso] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [hubspotTrackingCode, setHubspotTrackingCode] = useState('')

  useEffect(() => {
    localStorage.removeItem('token')
    localStorage.removeItem('merchant')
    localStorage.removeItem('user')

    const timer = setTimeout(() => {
      setActiveKey('step-1')
    }, 200)

    const cookie = document.cookie
      .split(';')
      .find((cookie) => cookie.trim().startsWith('hubspotutk='))
    if (cookie) {
      setHubspotTrackingCode(cookie.split('=')[1])
    }

    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    const scrollTimer = setTimeout(() => {
      switch (activeKey) {
        case 'step-1':
          firstStepRef?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          })
          break

        case 'step-2':
          secondStepRef?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          })
          break

        case 'step-3':
          thirdStepRef?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          })
          break

        case 'step-4':
          fourthStepRef?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          })
          break

        case 'step-5':
          fifthStepRef?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          })
          break

        default:
          return
      }
    }, 250)

    return () => clearTimeout(scrollTimer)
  }, [activeKey])

  const handleSlugFromEvent = (e: ChangeEvent<HTMLInputElement>): string => {
    if (!e.currentTarget.value) return ''
    const slugified = slugify(e.currentTarget.value, true)

    if ((e.nativeEvent as MessageEvent).data === (' ' || '-')) {
      return slugified + '-'
    }

    return slugified
  }

  const handleCompanyNameFromEvent = (
    e: ChangeEvent<HTMLInputElement>
  ): string => {
    if (!e.currentTarget.value) return ''
    const slugified = slugify(e.currentTarget.value)
    form.setFieldsValue({ slug: slugified })

    return e.currentTarget.value
  }

  const handleCompanyEmailFromEvent = (
    e: ChangeEvent<HTMLInputElement>
  ): string => {
    if (!e.currentTarget.value) return ''

    return e.currentTarget.value.trim()
  }

  const handleCurrentStep = (step: number) => {
    switch (step) {
      case 1:
        const settingWebsite = form.getFieldValue('setting_website')
        form.setFieldsValue({
          setting_website: handleCompanyWebsiteFormat(settingWebsite)
        })
        validateCurrentStep(1, CREATE_YOUR_SLERPSPACE_FIELDS)
        break
      case 2:
        validateCurrentStep(2, COMPANY_DETAILS_FIELDS)
        break
      case 3:
        validateCurrentStep(3, DIGITAL_CHANNELS_FIELDS)
        break
      case 4:
        validateCurrentStep(4, FINAL_QUESTIONS_FIELDS)
        break
      case 5:
        validateCurrentStep(5, ENTER_YOUR_PASSWORD_FIELDS)
        break
    }
  }

  const validateCurrentStep = (
    currentStep: number,
    fieldsToValidate:
      | CreateYourSlerpSpace
      | CompanyDetails
      | DigitalChannels
      | FinalQuestions
      | EnterYourPassword
  ) => {
    setIsLoading(true)

    form
      .validateFields(fieldsToValidate)
      .then((values) => {
        if (currentStep <= 5) {
          setStepState((prev) => ({
            ...prev,
            [`step-${currentStep}`]: 'finished'
          }))
          setActiveKey(`step-${currentStep + 1}`)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        setIsLoading(false)
        console.log('error', error)
      })
  }

  const handleOnSubmitErrors = (
    errorCodes: Array<PublishNewMerchantErrors | string>
  ) => {
    if (errorCodes.includes('unique_merchant_slug_constraint')) {
      setActiveKey('step-1')
      form.setFields([
        {
          name: 'slug',
          errors: [
            PUBLISH_ONBOARDING_ERROR_LIST['unique_merchant_slug_constraint']
          ]
        }
      ])
    } else if (errorCodes.includes('unique_user_email_constraint')) {
      setActiveKey('step-2')
      form.setFields([
        {
          name: 'email',
          errors: [
            PUBLISH_ONBOARDING_ERROR_LIST['unique_user_email_constraint']
          ]
        }
      ])
    } else {
      return
    }
  }

  const onFinish = (values: PublishNewMerchantParams) => {
    setIsLoading(true)

    const {
      slug,
      setting_website,
      existing_white_label_solution,
      instagram,
      delivery_marketplaces,
      orders_per_week,
      average_order_value,
      has_consented,
      how_can_we_help,
      phone,
      registered_company_address
    } = values

    const {
      line1,
      line2,
      city,
      country,
      postalCode: zip
    } = registered_company_address

    // Ensures that no invalid characters retains on submit, e.g. "trailing '-', etc."
    const slugified = slugify(slug)
    const sanitisedValues = {
      ...values,
      slug: slugified,
      existing_white_label_solution: checkboxDataSanitizer(
        existing_white_label_solution as unknown as string[]
      ),
      hutk: hubspotTrackingCode,
      page_uri: window.location.href,
      instagram: instagram ?? '',
      orders_per_week: delivery_marketplaces === 'No' ? 0 : orders_per_week,
      average_order_value:
        delivery_marketplaces === 'No' ? 0 : average_order_value,
      has_consented: has_consented[0] === 'Yes',
      how_can_we_help: how_can_we_help ?? '',
      phone: `+44${phone}`,
      address: {
        line_1: line1,
        line_2: line2,
        city,
        country,
        zip
      }
    }

    form.setFieldsValue({
      setting_website: handleCompanyWebsiteFormat(setting_website)
    })

    form
      .validateFields([
        ...CREATE_YOUR_SLERPSPACE_FIELDS,
        ...COMPANY_DETAILS_FIELDS,
        ...DIGITAL_CHANNELS_FIELDS,
        ...FINAL_QUESTIONS_FIELDS,
        ...ENTER_YOUR_PASSWORD_FIELDS
      ])
      .then(async () => {
        publishNewMerchant(sanitisedValues)
          .then(({ result }) => {
            setStepState((prev) => ({
              ...prev,
              'step-2': 'finished'
            }))

            trackUserActionsGA4({
              category: 'Onboarding',
              action: 'Lead'
            })
            trackPixelLead()

            setIsLoading(false)
            if (!result) return
            const { sso } = result
            setSso(sso)
          })
          .catch(({ errors }) => {
            console.log('error', errors)
            setIsLoading(false)

            const errorCodes: Array<PublishNewMerchantErrors | string> = (
              errors ?? [{ message: 'unknown_error' }]
            ).reduce((acc: string[], error: ApolloError) => {
              return [...acc, error.message]
            }, [])

            const normalizedErrors: string = errorCodes
              .reduce((acc: string, code: string) => {
                const errorMessage =
                  PUBLISH_ONBOARDING_ERROR_LIST[
                    code as PublishNewMerchantErrors
                  ] ?? code

                return acc + `${errorMessage} `
              }, '')
              .trim()

            handleOnSubmitErrors(errorCodes)
            message.error(
              `Unable to create slerpspace due to: "${normalizedErrors}"`,
              10
            )
          })
      })
      .catch((error) => {
        setIsLoading(false)
        console.error('publishNewMerchant error: ', error)
      })
  }

  const onFinishFailed = (errorInfo: FormError) => {
    const { errorFields } = errorInfo
    if (!errorFields.length) return
    if (CREATE_YOUR_SLERPSPACE_FIELDS.includes(errorFields[0].name[0]))
      return setActiveKey('step-1')
  }

  if (sso) return <Redirect to={`/${sso}`} />
  return (
    <div className='onboarding-page'>
      <Header className='site-layout-background main-header' />
      <div className='onboarding-container' data-testid='onboarding-form'>
        <Row justify='center' className='_mb-40'>
          <SlerpLogo
            className='onboarding-logo '
            data-testid='onboarding-slerp-logo'
          />
        </Row>
        <Form
          form={form}
          className='onboarding-form'
          labelCol={{ span: 24, style: { paddingBottom: '2px' } }}
          wrapperCol={{ span: 24 }}
          requiredMark={false}
          onFinish={onFinish}
          // Requires ValidateErrorEntity<PublishNewMerchantParams>,
          // but can't find any 'ValidateErrorEntity' within ANTD, seems like an issue
          onFinishFailed={onFinishFailed as any}
          initialValues={{
            has_expansion_plans: 'Yes',
            is_franchisee: 'Yes',
            delivery_marketplaces: 'Yes',
            orders_per_week: 0,
            average_order_value: 0,
            has_crm: 'Yes',
            has_loyalty: 'Yes',
            has_paid_marketing_ads: 'Yes',
            location_count: 0,
            has_consented: ['Yes']
          }}
        >
          <Collapse
            accordion
            onChange={(key: string | string[]) => {
              setActiveKey(key as string)
            }}
            activeKey={activeKey}
            className='onboarding-panels'
            data-testid='onboarding-panels'
          >
            <Panel
              key='step-1'
              id='onboarding-create-your-slerpspace'
              header={
                <Row
                  justify='space-between'
                  className='_mb-0 onboarding-header'
                  data-testid='onboarding-create-your-slerpspace'
                  ref={firstStepRef}
                >
                  <Col className='onboarding-title' span={19}>
                    <h2 className='_mb-0'>Create your SlerpSpace</h2>
                    <div className='onboarding-subtitle'>
                      <h5>
                        Tell us about your company. You can edit these options
                        at a later time.
                      </h5>
                    </div>
                  </Col>
                  <Col>
                    <StepIndicator
                      step={1}
                      active={activeKey === 'step-1'}
                      finished={stepState['step-1'] === 'finished'}
                    />
                  </Col>
                </Row>
              }
              showArrow={false}
            >
              <div
                onKeyDown={(key) => {
                  const { key: keyType } = key
                  if (keyType !== 'Enter') return
                  key.preventDefault()
                  handleCurrentStep(1)
                }}
              >
                <Row justify='space-between' gutter={[24, 8]}>
                  <Col span={12}>
                    <Form.Item
                      label={`First name`}
                      rules={[...nameRule]}
                      name='firstname'
                      className='_mb-0'
                      validateTrigger='onSubmit'
                    >
                      <Input
                        placeholder='First name'
                        data-testid='onboarding-first-name-input'
                        autoFocus
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={`Last name`}
                      rules={[...nameRule]}
                      name='lastname'
                      className='_mb-0'
                      validateTrigger='onSubmit'
                    >
                      <Input
                        placeholder='Last name'
                        data-testid='onboarding-last-name-input'
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item label='Phone number:'>
                  <Input.Group>
                    <Row gutter={8} className='_mb-0'>
                      <Col span={5}>
                        <Input defaultValue='+44' disabled />
                      </Col>
                      <Col span={19}>
                        <Form.Item
                          name='phone'
                          noStyle
                          rules={[UKPhoneValidatorRule]}
                          validateTrigger='onSubmit'
                        >
                          <Input
                            placeholder='7911123456'
                            type='number'
                            data-testid='onboarding-phone-input'
                            onFocus={onFocusScrollNumber}
                            onBlur={onBlurScrollNumber}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Input.Group>
                </Form.Item>

                <Form.Item
                  label={`Email`}
                  rules={[
                    ...requiredRule,
                    {
                      type: 'email',
                      message: 'Please input a valid email.'
                    }
                  ]}
                  name='email'
                  className='_mt-4'
                  validateTrigger='onSubmit'
                >
                  <Input
                    placeholder='admin-user@slerp.com'
                    data-testid='onboarding-email-input'
                    autoComplete='off'
                  />
                </Form.Item>

                <Form.Item
                  label={`Brand name`}
                  rules={[...noWhiteSpaceRule]}
                  name='merchant_name'
                  className='_mt-4'
                  getValueFromEvent={handleCompanyNameFromEvent}
                  validateTrigger='onSubmit'
                >
                  <Input
                    placeholder='e.g. Slerp'
                    maxLength={40}
                    data-testid='onboarding-merchant-name-input'
                  />
                </Form.Item>

                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.merchant_name !== currentValues.merchant_name
                  }
                  className='_mb-0'
                >
                  {({ getFieldValue }) =>
                    getFieldValue('merchant_name')?.length > 0 ? (
                      <>
                        <Col span={24} className='_mb-8 _mt-4'>
                          Create a domain for your online Slerp store
                        </Col>
                        <Col>
                          <Form.Item
                            className='_mt-4'
                            getValueFromEvent={handleSlugFromEvent}
                            name='slug'
                            rules={[...noWhiteSpaceRule, ...slugInputRule]}
                            dependencies={['merchant_name']}
                            validateTrigger='onSubmit'
                          >
                            <Input
                              prefix='https://'
                              suffix='.slerp.com'
                              placeholder='your-domain'
                              value={slugify(getFieldValue('merchant_name'))}
                              className='onboarding-slug'
                              maxLength={40}
                              data-testid='onboarding-merchant-slug-input'
                            />
                          </Form.Item>
                        </Col>
                      </>
                    ) : null
                  }
                </Form.Item>

                <Form.Item
                  label={`Company email`}
                  rules={[
                    ...requiredRule,
                    {
                      type: 'email',
                      message: 'Please input a valid email address'
                    }
                  ]}
                  name='setting_email'
                  className='_mt-4'
                  validateTrigger='onSubmit'
                  getValueFromEvent={handleCompanyEmailFromEvent}
                >
                  <Input
                    placeholder='e.g. defaultemail@slerp.com'
                    data-testid='onboarding-merchant-email-input'
                  />
                </Form.Item>

                <Form.Item
                  name='registered_company_address'
                  label='Registered Company Address:'
                  rules={[addressValidatorRule()]}
                >
                  <AddressInput />
                </Form.Item>

                <Form.Item
                  label={`Company website`}
                  rules={[
                    ...noWhiteSpaceRule,
                    {
                      type: 'url',
                      message: 'Please enter a valid URL.'
                    }
                  ]}
                  name='setting_website'
                  className='_mt-4'
                  validateTrigger='onSubmit'
                >
                  <Input
                    placeholder='e.g. https://slerp.com'
                    data-testid='onboarding-setting-website-input'
                  />
                </Form.Item>

                <Form.Item
                  label={
                    <span>
                      Add your instagram username{' '}
                      <span className='onboarding-optional'>Optional</span>
                    </span>
                  }
                  name='instagram'
                  className='_mt-4'
                  validateTrigger='onSubmit'
                >
                  <Input
                    placeholder='e.g. slerp.official'
                    data-testid='onboarding-instagram-input'
                  />
                </Form.Item>

                <Form.Item
                  label={
                    <span>
                      What is your registered business name? <br />
                      <span className='onboarding-optional _pb-2'>
                        (Slerp can only work with a registered company
                        incorporated in the UK)
                      </span>
                    </span>
                  }
                  rules={[...companySuffixRule]}
                  name='registered_company_name'
                  className='_mt-24'
                  validateTrigger='onSubmit'
                >
                  <Input
                    placeholder='e.g. Slerp, Ltd.'
                    data-testid='onboarding-registered-company-name-input'
                    className='_mt-8'
                  />
                </Form.Item>

                <Row justify='end' className='_mb-0'>
                  <Col>
                    <Button
                      className='_mt-24'
                      onClick={() => handleCurrentStep(1)}
                      data-testid='onboarding-create-your-slerpspace-next-button'
                      loading={isLoading}
                      disabled={isLoading}
                    >
                      Next
                    </Button>
                  </Col>
                </Row>
              </div>
            </Panel>
            <Panel
              key='step-2'
              id='onboarding-company-details'
              collapsible={
                stepState['step-1'] === 'finished' ? 'header' : 'disabled'
              }
              header={
                <Row
                  justify='space-between'
                  className='_mb-0 onboarding-header'
                  data-testid='onboarding-company-details'
                  ref={secondStepRef}
                >
                  <Col
                    className={`onboarding-title ${
                      stepState['step-1'] === 'finished' ? '' : '-disabled'
                    }`}
                    span={22}
                  >
                    <h2 className='_mb-0'>
                      Tell us a bit more about your company
                    </h2>
                  </Col>
                  <Col>
                    <StepIndicator
                      step={2}
                      active={activeKey === 'step-2'}
                      finished={stepState['step-2'] === 'finished'}
                    />
                  </Col>
                </Row>
              }
              showArrow={false}
            >
              <>
                <Form.Item
                  label={`How long have you been actively trading?`}
                  rules={[...noWhiteSpaceRule]}
                  name='trading_since'
                  className='_mt-4'
                >
                  <Select
                    placeholder='Please choose...'
                    data-testid='onboarding-trading-since'
                    autoFocus
                  >
                    <Select.Option
                      value={'0-2 years'}
                      data-testid='onboarding-trading-since-0-2-years'
                    >
                      0-2 years
                    </Select.Option>
                    <Select.Option
                      value={'3-5 years'}
                      data-testid='onboarding-trading-since-3-5-years'
                    >
                      3-5 years
                    </Select.Option>
                    <Select.Option
                      value={'5+ years'}
                      data-testid='onboarding-trading-since-5-years'
                    >
                      5+ years
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label={`How many locations do you currently have?`}
                  rules={requiredRule}
                  name='location_count'
                  className='_mt-4'
                  validateTrigger='onSubmit'
                >
                  <InputNumber
                    precision={0}
                    placeholder='0'
                    max={99}
                    min={0}
                    data-testid='onboarding-location-count'
                    className='onboarding-inputnumber'
                  />
                </Form.Item>
                <Form.Item
                  label={`Do you have plans to add more locations in the next 12 months?`}
                  rules={requiredRule}
                  name='has_expansion_plans'
                  className='_mt-4'
                >
                  <Radio.Group data-testid='onboarding-expansion-plans'>
                    <Space direction='vertical'>
                      <Radio
                        value='Yes'
                        data-testid='onboarding-expansion-plans-yes'
                      >
                        Yes
                      </Radio>
                      <Radio
                        value='No'
                        data-testid='onboarding-expansion-plans-no'
                      >
                        No
                      </Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  label={`Are you a franchisee of a group?`}
                  rules={requiredRule}
                  name='is_franchisee'
                  className='_mt-4'
                >
                  <Radio.Group data-testid='onboarding-is-franchisee'>
                    <Space direction='vertical'>
                      <Radio
                        value='Yes'
                        data-testid='onboarding-is-franchisee-yes'
                      >
                        Yes
                      </Radio>
                      <Radio
                        value='No'
                        data-testid='onboarding-is-franchisee-no'
                      >
                        No
                      </Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
                <Row justify='end' className='_mb-0'>
                  <Col>
                    <Button
                      className='_mt-24'
                      onClick={() => handleCurrentStep(2)}
                      data-testid='onboarding-company-details-next-button'
                      loading={isLoading}
                      disabled={isLoading}
                    >
                      Next
                    </Button>
                  </Col>
                </Row>
              </>
            </Panel>
            <Panel
              key='step-3'
              id='onboarding-digital-channels'
              collapsible={
                stepState['step-1'] === 'finished' ? 'header' : 'disabled'
              }
              header={
                <Row
                  justify='space-between'
                  className='_mb-0 onboarding-header'
                  data-testid='onboarding-digital-channels'
                  ref={thirdStepRef}
                >
                  <Col
                    className={`onboarding-title ${
                      stepState['step-1'] === 'finished' ? '' : '-disabled'
                    }`}
                    span={22}
                  >
                    <h2 className='_mb-0'>Your existing digital channels</h2>
                  </Col>
                  <Col>
                    <StepIndicator
                      step={3}
                      active={activeKey === 'step-3'}
                      finished={stepState['step-3'] === 'finished'}
                    />
                  </Col>
                </Row>
              }
              showArrow={false}
            >
              <>
                <Form.Item
                  label={
                    <span>
                      Do you sell on any marketplaces/delivery apps{' '}
                      <span className='onboarding-optional'>
                        (for example: Deliveroo, UberEats, JustEat)?
                      </span>
                    </span>
                  }
                  name='delivery_marketplaces'
                  className='_mt-4'
                  rules={requiredRule}
                >
                  <Radio.Group
                    onChange={(e) => {
                      setDeliveryMarketplaces(e.target.value)
                    }}
                  >
                    <Space
                      direction='vertical'
                      data-testid='onboarding-delivery-marketplaces'
                    >
                      <Radio
                        value='Yes'
                        data-testid='onboarding-delivery-marketplaces-yes'
                        autoFocus
                      >
                        Yes
                      </Radio>
                      <Radio
                        value='No'
                        data-testid='onboarding-delivery-marketplaces-no'
                      >
                        No
                      </Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
                {deliveryMarketplaces === 'Yes' && (
                  <>
                    <Form.Item
                      label={`How many orders do you do per week?`}
                      rules={requiredRule}
                      name='orders_per_week'
                      className='_mt-4'
                      validateTrigger='onSubmit'
                    >
                      <InputNumber
                        precision={0}
                        placeholder='0'
                        max={5000}
                        min={0}
                        data-testid='onboarding-orders-per-week'
                        className='onboarding-inputnumber'
                      />
                    </Form.Item>
                    <Form.Item
                      label={`What is the average value per order?`}
                      rules={requiredRule}
                      name='average_order_value'
                      className='_mt-4'
                      validateTrigger='onSubmit'
                    >
                      <InputNumber
                        precision={0}
                        placeholder='0'
                        max={5000}
                        min={0}
                        data-testid='onboarding-average-order-value'
                        className='onboarding-inputnumber onboarding-average-order-value'
                      />
                    </Form.Item>
                  </>
                )}
                <Form.Item
                  label={
                    <span>
                      Do you currently enable customers to order directly from
                      you (i.e. sell takeaway from your website)?{' '}
                      <span className='onboarding-optional'>
                        Select all that apply
                      </span>
                    </span>
                  }
                  name='existing_white_label_solution'
                  className='_mt-4'
                  rules={requiredRule}
                >
                  <Checkbox.Group
                    onChange={(e) => setWhiteLabelSolution(e)}
                    data-testid='onboarding-white-label'
                  >
                    <Row className='_mb-0 _mt-12'>
                      <Checkbox
                        value='Click and Collect'
                        disabled={whiteLabelSolution[0] === 'No'}
                        data-testid='onboarding-white-label-yes-click-collect'
                      >
                        Yes, I offer click & collect
                      </Checkbox>
                    </Row>
                    <Row className='_mb-0 _mt-4'>
                      <Checkbox
                        value='Delivery'
                        disabled={whiteLabelSolution[0] === 'No'}
                        data-testid='onboarding-white-label-yes-delivery'
                      >
                        Yes, I offer delivery
                      </Checkbox>
                    </Row>
                    <Row className='_mb-0 _mt-4'>
                      <Checkbox
                        value='No'
                        disabled={
                          whiteLabelSolution[0] !== 'No' &&
                          whiteLabelSolution.length > 0
                        }
                        data-testid='onboarding-white-label-no'
                      >
                        No
                      </Checkbox>
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
                <Row justify='end' className='_mb-0'>
                  <Col>
                    <Button
                      className='_mt-24'
                      onClick={() => handleCurrentStep(3)}
                      data-testid='onboarding-digital-channels-next-button'
                      loading={isLoading}
                      disabled={isLoading}
                    >
                      Next
                    </Button>
                  </Col>
                </Row>
              </>
            </Panel>
            <Panel
              key='step-4'
              id='onboarding-final-few-questions'
              collapsible={
                stepState['step-1'] === 'finished' ? 'header' : 'disabled'
              }
              header={
                <Row
                  justify='space-between'
                  className='_mb-0 onboarding-header'
                  data-testid='onboarding-create-admin-account'
                  ref={fourthStepRef}
                >
                  <Col
                    className={`onboarding-title ${
                      stepState['step-1'] === 'finished' ? '' : '-disabled'
                    }`}
                    span={22}
                  >
                    <h2 className='_mb-0'>Final few questions</h2>
                  </Col>
                  <Col>
                    <StepIndicator
                      step={4}
                      active={activeKey === 'step-4'}
                      finished={stepState['step-4'] === 'finished'}
                    />
                  </Col>
                </Row>
              }
              showArrow={false}
            >
              <>
                <Form.Item
                  label={`Do you have CRM system for storing customer data (i.e. Mailchimp)?`}
                  rules={requiredRule}
                  name='has_crm'
                  className='_mt-4'
                >
                  <Radio.Group data-testid='onboarding-crm-system'>
                    <Space direction='vertical'>
                      <Radio
                        value='Yes'
                        data-testid='onboarding-crm-system-yes'
                        autoFocus
                      >
                        Yes
                      </Radio>
                      <Radio value='No' data-testid='onboarding-crm-system-no'>
                        No
                      </Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  label={`Do you currently offer a loyalty programme to your customers?`}
                  rules={requiredRule}
                  name='has_loyalty'
                  className='_mt-4'
                >
                  <Radio.Group data-testid='onboarding-loyalty-programme'>
                    <Space direction='vertical' align='start'>
                      <Radio
                        value='Yes'
                        data-testid='onboarding-loyalty-programme-yes'
                      >
                        Yes
                      </Radio>
                      <Radio
                        value='No'
                        data-testid='onboarding-loyalty-programme-no'
                      >
                        No
                      </Radio>
                      <Radio
                        value='I would like to'
                        data-testid='onboarding-loyalty-programme-would-like-to'
                      >
                        I would like to
                      </Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  label={`Do you do any paid marketing on Google/Social Media?`}
                  rules={requiredRule}
                  name='has_paid_marketing_ads'
                  className='_mt-4'
                  validateTrigger='onSubmit'
                >
                  <Radio.Group data-testid='onboarding-paid-marketing-ads'>
                    <Space direction='vertical'>
                      <Radio
                        value='Yes'
                        data-testid='onboarding-paid-marketing-ads-yes'
                      >
                        Yes
                      </Radio>
                      <Radio
                        value='No'
                        data-testid='onboarding-paid-marketing-ads-no'
                      >
                        No
                      </Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  label={
                    <Col className='_mt-8 '>
                      Do you use any integrations? (Otter, Deliverect, Urban
                      Piper)
                    </Col>
                  }
                  rules={requiredRule}
                  name='pos_system'
                  className='_mt-4 _mb-24'
                >
                  <Radio.Group data-testid='onboarding-pos-system'>
                    <Space direction='vertical' align='start'>
                      <Radio
                        value='Otter'
                        data-testid='onboarding-pos-system-otter'
                      >
                        Otter
                      </Radio>
                      <Radio
                        value='Deliverect'
                        data-testid='onboarding-pos-system-deliverect'
                      >
                        Deliverect
                      </Radio>
                      <Radio
                        value='Urban Piper'
                        data-testid='onboarding-pos-system-urban-piper'
                      >
                        Urban Piper
                      </Radio>
                      <Radio
                        value='Other'
                        data-testid='onboarding-pos-system-other'
                      >
                        Other
                      </Radio>
                      <Radio
                        value='No, I do not use any'
                        data-testid='onboarding-pos-system-no'
                      >
                        No, I do not use any
                      </Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  label={
                    <span>What Point of Sale (POS) system do you use?</span>
                  }
                  rules={[...noWhiteSpaceRule]}
                  name='cyss_pos'
                  className='_mt-4'
                >
                  <Input
                    placeholder='Type here...'
                    data-testid='onboarding-cyss-pos'
                    maxLength={50}
                  />
                </Form.Item>
                <Form.Item
                  label={`Ideally how soon would you like to go live?`}
                  name='live_at'
                  className='_mt-4'
                  validateTrigger='onSubmit'
                  rules={[
                    {
                      required: true,
                      message: 'Please select an option.'
                    }
                  ]}
                >
                  <Select
                    placeholder='Please choose...'
                    data-testid='onboarding-live-at'
                  >
                    <Select.Option
                      value={'Within a month'}
                      data-testid='onboarding-within-a-month'
                    >
                      Within a month
                    </Select.Option>
                    <Select.Option
                      value={'2-3 months'}
                      data-testid='onboarding-2-3-months'
                    >
                      2-3 months
                    </Select.Option>
                    <Select.Option
                      value={'3 months +'}
                      data-testid='onboarding-3-months'
                    >
                      3 months+
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label={
                    <span>
                      What else should we know?{' '}
                      <span className='onboarding-optional'>optional</span>
                    </span>
                  }
                  name='how_can_we_help'
                  className='_mt-4'
                >
                  <TextArea
                    placeholder='Type here...'
                    data-testid='onboarding-how-can-we-help'
                    maxLength={200}
                  />
                </Form.Item>

                <Row justify='end' className='_mb-0'>
                  <Col>
                    <Button
                      className='_mt-24'
                      onClick={() => handleCurrentStep(4)}
                      data-testid='onboarding-final-few-questions-next-button'
                      loading={isLoading}
                      disabled={isLoading}
                    >
                      Next
                    </Button>
                  </Col>
                </Row>
              </>
            </Panel>
            <Panel
              key='step-5'
              id='onboarding-enter-your-password'
              collapsible={
                stepState['step-1'] === 'finished' ? 'header' : 'disabled'
              }
              header={
                <Row
                  justify='space-between'
                  className='_mb-0 onboarding-header'
                  data-testid='onboarding-enter-your-password'
                  ref={fifthStepRef}
                >
                  <Col
                    className={`onboarding-title ${
                      stepState['step-1'] === 'finished' ? '' : '-disabled'
                    }`}
                    span={19}
                  >
                    <h2 className='_mb-0'>Enter your password</h2>
                    <div className='onboarding-subtitle'>
                      <h5>This creates the login to your platform.</h5>
                    </div>
                  </Col>
                  <Col>
                    <StepIndicator
                      step={5}
                      active={activeKey === 'step-5'}
                      finished={stepState['step-5'] === 'finished'}
                    />
                  </Col>
                </Row>
              }
              showArrow={false}
            >
              <>
                <Form.Item
                  label={`Password`}
                  rules={[...noWhiteSpaceRule]}
                  name='password'
                  className='_mt-4'
                >
                  <Input.Password
                    placeholder='password'
                    visibilityToggle
                    data-testid='password-input'
                    autoComplete='new-password'
                    autoFocus
                  />
                </Form.Item>
                <Form.Item
                  rules={requiredRule}
                  name='has_consented'
                  className='_mt-40'
                >
                  <Checkbox.Group data-testid='onboarding-has-consented'>
                    <Checkbox
                      data-testid='onboarding-has-consented-checkbox'
                      className='onboarding-optional'
                      value='Yes'
                    >
                      I agree to receive marketing emails from Slerp Limited.
                      You may unsubscribe from these communications at any time.
                      For more information on how to unsubscribe, our privacy
                      practices, and how we are committed to protecting and
                      respecting your privacy, please review our Privacy Policy.
                      By clicking submit, you consent to allow Slerp Limited to
                      store and process the personal information submitted above
                      to provide you with the content requested.
                    </Checkbox>
                  </Checkbox.Group>
                </Form.Item>
                <Row justify='end' className='_mb-0'>
                  <Col>
                    <Button
                      htmlType='submit'
                      className='_mt-24'
                      name='submit'
                      loading={isLoading}
                      data-testid='onboarding-submit-button'
                    >
                      {isLoading
                        ? 'Starting your slerp journey...'
                        : 'Start your slerp journey'}
                    </Button>
                  </Col>
                </Row>
              </>
            </Panel>
          </Collapse>
        </Form>
      </div>
    </div>
  )
}

export default OnboardingForm
