import {
  AttributionFormObject,
  AttributionKeys,
  Location,
  InvoiceStatus,
  SelectFilterVariant
} from './types'
import { Attribution } from '@slerp/controls'
import { Decimal } from 'decimal.js'
import omit from 'lodash/omit'
import moment from 'moment'

const DATE_FORMAT = 'DD/MM/YYYY'

export const getInvoiceParams = (params: {
  [key: string]: string | string[]
}) => {
  const filters = Object.keys(params).reduce((acc: string[], key: string) => {
    if (!params[key]) {
      return [...acc, key]
    }

    return acc
  }, [])

  if (params.paymentStatus.includes('all')) {
    filters.push('paymentStatus')
  } else {
    params.paymentStatus = params.paymentStatus[0]
  }

  return omit(params, filters)
}

export const getFilterLabelOptions = (variant: SelectFilterVariant) => {
  const allOption = {
    label: `All ${getFilterLabel(variant)}`,
    value: 'all'
  }

  const options = {
    [SelectFilterVariant.status]: [
      allOption,
      { label: 'Paid', value: InvoiceStatus.paid },
      { label: 'Unpaid', value: InvoiceStatus.unpaid }
    ]
  }

  return options[variant]
}

export const getFilterOptions = (variant: SelectFilterVariant) => {
  const options = {
    [SelectFilterVariant.status]: [InvoiceStatus.paid, InvoiceStatus.unpaid]
  }

  return options[variant]
}

export const getFilterLabel = (variant: SelectFilterVariant) => {
  const labels = {
    [SelectFilterVariant.status]: 'Statuses'
  }

  return labels[variant]
}

export const formatDate = (date: string) => {
  if (!date) {
    return 'N/A'
  }

  return moment(date).format(DATE_FORMAT)
}

export const getBillingPeriod = (start: string | null, end: string | null) => {
  if (!start || !end) {
    return 'N/A'
  }

  if (start === end || !end) {
    return start
  }

  if (!start) {
    return end
  }

  return `${formatDate(start)} - ${formatDate(end)}`
}

export const getStatusColor = (status: InvoiceStatus) => {
  const statusColors = {
    [InvoiceStatus.paid]: '#2F54EB',
    [InvoiceStatus.unpaid]: '#F38A19'
  }

  return statusColors[status] || '#4A4A68'
}

export const ATTRIBUTIONS_MAP: { [key in AttributionKeys]: string } = {
  delivery: 'Managed delivery',
  pickup: 'Click & Collect',
  selfDelivery: 'Delivery with Own Drivers/Couriers',
  table: 'Table Ordering',
  nationwideDelivery: 'Nationwide Delivery'
}

export const chunkArray = <T>(arr: Array<T>, size = 2) => {
  const chunkedArray = []
  for (let i = 0; i < arr.length; i++) {
    const last = chunkedArray[chunkedArray.length - 1]
    if (!last || last.length === size) {
      chunkedArray.push([arr[i]])
    } else {
      last.push(arr[i])
    }
  }
  return chunkedArray
}

interface Options {
  setDefault: boolean
}

export const attributionToFormObject = (
  attribution: Attribution,
  { setDefault }: Options = { setDefault: true }
): AttributionFormObject => {
  return Object.keys(ATTRIBUTIONS_MAP).reduce(
    (acc, cur) => {
      const value = attribution[cur as AttributionKeys]

      if (!value) {
        return setDefault ? { ...acc, [cur]: 0 } : { ...acc, [cur]: '-' }
      }

      const rawValue = parseFloat(value) * 100
      const parsedValue =
        rawValue.toFixed(2).split('.')[1] === '00'
          ? rawValue.toFixed()
          : rawValue.toFixed(2)

      return { ...acc, [cur]: parseFloat(parsedValue) }
    },
    { storeIds: [attribution?.store?.id] }
  ) as AttributionFormObject
}

export const getUnconnectedLocations = (
  locations: Location[],
  ids: string[]
) => {
  return locations.filter(
    (location) => ids.includes(location.id) && !location.hasStripeId
  )
}

export const netCommissionRate = (value: string, vatPercentage: string) => {
  const decimalGrossValue = new Decimal(Number(value || 0))
  const decimalVat = new Decimal(vatPercentage || 0)
  const decimalNetPrice = decimalGrossValue.dividedBy(decimalVat)

  return decimalNetPrice.times(100).toFixed(2)
}
