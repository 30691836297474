import React, { useEffect, useState } from 'react'
import {
  PageHeader,
  Divider,
  Row,
  Col,
  Button,
  Modal,
  Input,
  message,
  Popconfirm,
  Tag
} from 'antd'
import {
  CreditCardOutlined,
  PlusOutlined,
  ClockCircleOutlined,
  DeleteOutlined
} from '@ant-design/icons'
import { Merchant } from 'types'
import StripeConnectSettings from '../StripeConnectSettings'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe, Stripe } from '@stripe/stripe-js'
import CardDetails from './CardDetails'
import { QUERY_STRIPE_PUBLISHABLE_KEY } from '../SettingsQueries'
import { useMutation, useQuery } from '@apollo/client'
import {
  DELETE_CARD_FROM_STRIPE_CUSTOMER_ACCOUNT,
  UPDATE_DEFAULT_SOURCE
} from '../actions'
import Loading from '../../Utils/Loading'
import { Card } from '../../Utils/useGlobalQuery'
import { useGlobalQuery } from 'components/Utils/useGlobalQuery'

interface Props {
  merchant: Merchant
}

const Payments = (props: Props) => {
  const { cards, isLoading } = useGlobalQuery()
  const { merchant } = props
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>(
    new Promise((resolve) => resolve(null))
  )

  const [showModal, setShowModal] = useState(false)

  const { data, loading: stripePubKeyLoading } = useQuery(
    QUERY_STRIPE_PUBLISHABLE_KEY,
    {
      variables: {
        merchantId: merchant?.id
      }
    }
  )

  const [deleteStripeCard, { loading: deleteStripeCardLoading }] = useMutation(
    DELETE_CARD_FROM_STRIPE_CUSTOMER_ACCOUNT,
    { refetchQueries: ['merchantConnectedStripeCards'] }
  )

  const [updateDefaultSource, { loading: defaultSourceLoading }] = useMutation(
    UPDATE_DEFAULT_SOURCE,
    {
      refetchQueries: ['merchantConnectedStripeCards']
    }
  )

  useEffect(() => {
    if (!data?.getStripePublishableKey) return

    setStripePromise(loadStripe(data?.getStripePublishableKey))
  }, [data?.getStripePublishableKey])

  const showModalHandler = (show: boolean) => setShowModal(show)

  const updateDefaultSourceHandler = (card: Card) => {
    updateDefaultSource({
      variables: {
        merchantId: merchant?.id,
        cardId: card.id
      }
    })
      .then(() => {
        message.success(
          `Successfully set card ending in ${card.last4} as default source`,
          5
        )
      })
      .catch((err) =>
        message.error(`Unable to update default source due to: ${err}`, 5)
      )
  }

  return (
    ((isLoading || stripePubKeyLoading) && <Loading />) || (
      <Col span={16}>
        <PageHeader title='Card Details' />
        <Col className='_mb-12'>
          {!!(cards || []).length && (
            <Row gutter={16} className='_mb-0'>
              <Col span={8}>
                <span>Credit card ending in:</span>
              </Col>
              <Col span={5}>
                <span>Expiry:</span>
              </Col>
            </Row>
          )}
          {cards ? (
            cards.map((card) => (
              <div key={card.id}>
                <Row gutter={16} className='_mt-8 _mb-0' align='middle'>
                  <Col span={8} className='card-input'>
                    <Input
                      value={`**** ${card.last4}`}
                      disabled
                      prefix={<CreditCardOutlined />}
                      data-testid={`connected-card-${card.last4}`}
                      className={`${card.expired && 'card-expired'} card-input`}
                    />
                  </Col>
                  <Col span={5}>
                    <Input
                      value={`${card.expMonth}/${card.expYear
                        .toString()
                        .slice(-2)}`}
                      disabled
                      prefix={<ClockCircleOutlined />}
                      className={`${card.expired && 'card-expired'} card-input`}
                    />
                  </Col>
                  <Col>
                    <Popconfirm
                      disabled={cards.length < 2 || deleteStripeCardLoading}
                      title='Are you sure you want to delete this card?'
                      cancelButtonProps={{
                        type: 'ghost'
                      }}
                      onConfirm={() =>
                        deleteStripeCard({
                          variables: {
                            merchant_id: merchant?.id,
                            card_id: card.id,
                            stripe_customer_id:
                              merchant?.subscription?.stripe_customer_id
                          }
                        })
                          .then((result) => {
                            message.success(
                              result?.data?.deleteStripeCustomerCard,
                              5
                            )
                          })
                          .catch((error) =>
                            message.error(
                              `Unable to delete card due to: ${error}`,
                              5
                            )
                          )
                      }
                    >
                      <Button
                        icon={<DeleteOutlined />}
                        disabled={cards.length < 2 || deleteStripeCardLoading}
                        loading={deleteStripeCardLoading}
                        className='delete-card'
                      />
                    </Popconfirm>
                  </Col>
                </Row>
                {
                  <Row className='_mt-8 default-row'>
                    {(card.expired && (
                      <i
                        className='expired-text'
                        data-testid={`expired-card-${card.last4}`}
                      >
                        This card has expired.
                      </i>
                    )) ||
                      (card.defaultSource === card.id && (
                        <Tag className='default-source'>DEFAULT</Tag>
                      )) || (
                        <Button
                          size='small'
                          type='text'
                          className='default-source'
                          loading={defaultSourceLoading}
                          onClick={() => updateDefaultSourceHandler(card)}
                        >
                          set as default
                        </Button>
                      )}
                  </Row>
                }
              </div>
            ))
          ) : (
            <span className='add-card'>
              In order to connect to Stripe you will need to add your
              <br /> Credit Card details first
            </span>
          )}
        </Col>
        <Button
          type='dashed'
          icon={<PlusOutlined />}
          className='_mt-8 _mb-16'
          onClick={() => setShowModal(true)}
          loading={stripePubKeyLoading}
        >
          Add card
        </Button>
        <Divider />
        <StripeConnectSettings merchant={merchant} />
        <Modal
          visible={showModal}
          onCancel={() => setShowModal(false)}
          title='Enter your card details:'
          className='card-modal'
          centered
          footer={false}
        >
          {stripePromise && (
            <Elements stripe={stripePromise}>
              <CardDetails
                merchant={merchant}
                showModalHandler={showModalHandler}
              />
            </Elements>
          )}
        </Modal>
      </Col>
    )
  )
}

export default Payments
