import React from 'react'
import { Form, Select } from 'antd'
import { requiredRule, fulfillmentTypeRule } from './Rules'
import { QuestionCircleOutlined } from '@ant-design/icons'
import Tooltip from 'antd/lib/tooltip'

type Props = {
  formName: string
  defaultValue: string[]
  options: FulfillmenTypeOption[]
}

interface FulfillmenTypeOption {
  label: string
  value: 'delivery' | 'pickup' | 'order_at_table'
  disabled: boolean
}

const FulfillmentTypeSelect = ({ formName, defaultValue, options }: Props) => {
  return (
    <React.Fragment>
      <Form.Item
        label='Limit by fulfillment type'
        label={
          <>
            <span>Limit by fulfillment type</span>
            <Tooltip
              title={
                'Specify whether a discount can only be used for delivery, pickup or order at table.'
              }
            >
              <QuestionCircleOutlined className='_ml-8 _mr-8' />
            </Tooltip>
          </>
        }
        name={formName}
        rules={[...requiredRule, ...fulfillmentTypeRule]}
      >
        <Select
          allowClear
          data-testid='discount-fulfillment-types-dropdown'
          placeholder='Select fulfillment types'
          mode='multiple'
          dropdownClassName='select-multiple'
          defaultValue={defaultValue}
        >
          {options.map((type) => (
            <Select.Option
              disabled={type.disabled}
              value={type.value}
              key={type.value}
              className='_px-8 _my-2'
              data-testid={`fulfillment-type-option-${type.value}`}
            >
              {type.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </React.Fragment>
  )
}

export default FulfillmentTypeSelect
