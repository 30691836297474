import React from 'react'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Button from 'antd/lib/button'
import Divider from 'antd/lib/divider'
import message from 'antd/lib/message'
import { OrderInfo } from '../type'
import { useMutation } from '@apollo/client'
import { useSession } from '@slerp/accounts'
import {
  COMPLETE_ORDER,
  UPDATE_FULFILLMENT_STATUS
} from 'components/Orders/OrderQueries'
import errorReducer from 'errors/errorReducer'
import { isFullyRefunded } from '../utils'

interface DeliveryStatusProps {
  order: OrderInfo
  successCallback: () => void
}

const DeliveryStatus = ({ order, successCallback }: DeliveryStatusProps) => {
  const { user } = useSession()

  const [updateFulfillmentStatus, { loading: updatingFulfillmentStatus }] =
    useMutation(UPDATE_FULFILLMENT_STATUS, {
      fetchPolicy: 'no-cache'
    })

  const [completeOrder, { loading: completingOrder }] = useMutation(
    COMPLETE_ORDER,
    {
      fetchPolicy: 'no-cache'
    }
  )

  const outForDelivery = () => {
    message.loading('Updating fulfillment status... Please wait.', 1)
    updateFulfillmentStatus({
      variables: {
        transactionId: order.transaction_id,
        fulfillmentStatus: 'DELIVERING'
      }
    })
      .then((response) => {
        const { updateFulfillmentStatus } = response?.data || {}
        message.success(
          `Order ${updateFulfillmentStatus.transaction_id} fulfillment status updated!`,
          1
        )
        successCallback()
      })
      .catch((error: Error) => {
        throw errorReducer({
          origin: 'Order',
          data: {
            error: error,
            message: error.message
          }
        })
      })
      .catch((error) => {
        message.error(`${error.message}`)
      })
  }

  const completeOrderHandler = () => {
    message.loading('Completing order... Please wait.', 1)
    completeOrder({
      variables: { transactionId: order.transaction_id, userId: user.id }
    })
      .then((response) => {
        const { completeOrder } = response?.data || {}
        message.success(`Order ${completeOrder.transactionId} completed!`, 1)
        successCallback()
      })
      .catch((error: Error) => {
        throw errorReducer({
          origin: 'Order',
          data: {
            error: error,
            message: error.message
          }
        })
      })
      .catch((error) => {
        message.error(`${error.message}`)
      })
  }

  return order?.delivery?.service === 'merchant' &&
    !isFullyRefunded(order) &&
    order?.delivery?.delivery_status !== 'fulfilled manually' &&
    !['rejected', 'pending'].includes(order.status || '') ? (
    <>
      <Divider
        style={{
          background: 'rgba(140, 140, 161, 0.50)',
          margin: '16px 0'
        }}
      />
      <Row
        justify='space-between'
        align='middle'
        className='delivery-status _mb-4'
      >
        <span className='delivery-status'>Update Delivery Status</span>
        <Row className='_mb-0' justify='end' align='middle' gutter={16}>
          <Col>
            <Button
              title='out for delivery'
              type='primary'
              onClick={outForDelivery}
              style={
                order?.delivery?.delivery_status === 'delivering'
                  ? {
                      opacity: '0.3',
                      background: '#D092DD',
                      color: '#FFF'
                    }
                  : {}
              }
              disabled={
                updatingFulfillmentStatus ||
                order?.delivery?.delivery_status === 'delivering'
              }
            >
              Out for delivery
            </Button>
          </Col>
          <Col>
            <Button
              data-testid='order-info-actions-refund'
              title='delivery completed'
              type='primary'
              onClick={completeOrderHandler}
              disabled={completingOrder}
              ghost={order?.delivery?.delivery_status !== 'delivering'}
            >
              Delivery Complete
            </Button>
          </Col>
        </Row>
      </Row>
    </>
  ) : (
    <></>
  )
}

export default DeliveryStatus
