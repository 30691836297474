import { SplitHour } from '@slerp/controls'

export enum PickupStatuses {
  pending = 'pending',
  preparing = 'preparing',
  ready_for_collection = 'ready_for_collection',
  collected = 'collected'
}

interface CutoffDaySetting {
  id: string
  time: string
  enabled: boolean
  day_diff: number
  cutoff_date: string | null
}

interface Cutoff {
  id: string
  monday: CutoffDaySetting
  tuesday: CutoffDaySetting
  wednesday: CutoffDaySetting
  thursday: CutoffDaySetting
  friday: CutoffDaySetting
  saturday: CutoffDaySetting
  sunday: CutoffDaySetting
}

interface DeliveryTimes {
  id: string
  end: string
  start: string
  enabled: boolean
  special_date: string | null
}

interface DeliverySchedule {
  id: string
  monday: DeliveryTimes
  tuesday: DeliveryTimes
  wednesday: DeliveryTimes
  thursday: DeliveryTimes
  friday: DeliveryTimes
  saturday: DeliveryTimes
  sunday: DeliveryTimes
}

interface PreOrderSetting {
  cutoffs: Cutoff[]
  special_cutoffs: null
  fixed_delivery_times: DeliveryTimes
  fixed_delivey_times_enabled: boolean
  delivery_schedule: DeliverySchedule
  delivery_interval: number
}

export interface OrderMerchant {
  name: string
  pre_order_settings: PreOrderSetting[]
  order_custom_field_enabled: boolean
}

interface StoreOperatingSchedules {
  id: string
  monday: DeliveryTimes
  tuesday: DeliveryTimes
  wednesday: DeliveryTimes
  thursday: DeliveryTimes
  friday: DeliveryTimes
  saturday: DeliveryTimes
  sunday: DeliveryTimes
}

interface Address {
  geom: {
    type: string
    crs: {
      type: string
      properties: {
        name: string
      }
    }
    coordinates: number[]
  }
  updated_at?: string
}

export interface AddressCache {
  address: string
  city: string
  lat: string
  lng: string
  zip: string
  line_2?: string
  flat_number?: string
  updated_at?: string
}

type AdditionalFeeAmountTypes = 'fixed' | 'percentage'
interface AdditionalFeeFulfillmentTypes {
  id: string
  pickup: boolean
  delivery: boolean
  table_ordering: boolean
}

interface StoreAdditionalFee {
  id: string
  name: null | string
  amount: number
  is_active: boolean
  amount_type: null | AdditionalFeeAmountTypes
  fulfillment_types: null | AdditionalFeeFulfillmentTypes
}

interface StoreSettings {
  id: string
  timezone: string
  asap_only: boolean
  prep_mode: string
  table_count: number
  asap_enabled: boolean
  delivery_area: {
    lat: number
    lng: number
  }[]
  table_choices: string
  table_enabled: boolean
  additional_fee: StoreAdditionalFee
  delivery_radius: number
  pickup_interval: number
  same_day_pickup: boolean
  pre_order_pickup: boolean
  preparation_time: number
  scheduled_pickup: boolean
  delivery_interval: number
  same_day_delivery: boolean
  busy_delivery_area: {
    lat: number
    lng: number
  }[]
  deliverect_link_id: string
  pre_order_delivery: boolean
  scheduled_delivery: boolean
  delivery_validation: string
  minimum_order_value: string
  table_counter_pickup: boolean
  busy_preparation_time: number
  custom_delivery_area: boolean
  stock_control_enabled: boolean
  table_welcome_message: string
  quiet_preparation_time: number
  advanced_order_interval: null | string
  automatically_open_store: boolean
  is_delivering_nationwide: boolean
  automatically_close_store: boolean
  moderate_preparation_time: number
  busy_delivery_area_enabled: boolean
  pickup_timeslot_order_limit: number
  table_counter_pickup_message: string
  delivery_timeslot_order_limit: number
  enable_advanced_order_timeslots: boolean
  same_day_delivery_courier_partner: boolean
  advanced_timeslots_delivery_interval: number
  same_day_delivery_courier_custom_fixed_fee: number
  same_day_delivery_fixed_courier_fee_enabled: boolean
  delivery_tipping_enabled: boolean
  pickup_tipping_enabled: boolean
  oat_tipping_enabled: boolean
}

export interface Driver {
  id: string
  first_name: string
  last_name: string
  phone: string
  transport_type: string
  location: {
    id: string
    lat: number
    lng: number
  }
}

export interface Delivery {
  id: string
  proxy: null | string
  driver: null | Driver
  job_id: null | string
  order_reference: string
  service: string
  quote_id: null | string
  updated_at: string
  initial_fee: string
  inserted_at: string
  pickup_time: null | string
  cancelled_by: null | string
  dropoff_time: null | string
  proxy_job_id: null | string
  delivery_status: null | string
  default_transport_type: string
}

export interface Store {
  id: string
  name: string
  email: string
  split_hours: SplitHour[]
  settings: StoreSettings
  address: Address
  pre_order_settings: {
    delivery_courier_partner: boolean
    fixed_delivery_times_enabled: boolean
    delivery_interval: number
    delivery_schedule: null
    delivery_times: string
  }[]
  address_cache: string
}

export interface DiscountCache {
  id: string
  code: string
  type: 'fixed' | 'percentage'
  value: string
  target: 'all_products' | 'delivery_fee'
  trigger: 'manual' | 'automatic'
  target_meta: null
  product_limit: null
  variants_only: boolean
  total_discount: string
  discount_breakdown: {
    id: string
    item: string
    amount: string
    item_id: null | string
    discount: string
    quantity_applied: null
  }[]
}

export interface CustomerAddress {
  lat: number
  lng: number
  zip: string
  city: string
  coords: {
    lat: number
    lng: number
  }
  line_1: string
  line_2: string
  country: string
  country_num: string
  flat_number: null | string
  is_new_version: boolean
}

export interface CustomerDetails {
  id: string
  email: string
  address: CustomerAddress
  last_name: string
  first_name: string
  contact_num: string
}

export interface RecipientDetails {
  id: string
  last_name: string
  first_name: string
  contact_num: string
}

interface PaymentDataSource {
  brand: string
  last4: string
}

export interface PaymentData {
  id: string
  paid: boolean
  amount: number
  created: number
  description: null | string
  refunded: boolean
  outcome: {
    authorized: null | string
    reason: null | string
    seller_message: null | string
  }
  source?: PaymentDataSource
  balance_transaction?: {
    fee: number
  }
}

interface Payment {
  id: string
  data: PaymentData
  service: string
  reference: string
}

type OrderStatus =
  | 'picking'
  | 'cancelled'
  | 'rejected'
  | 'pending'
  | 'fulfilled'
  | 'refunded'
  | 'accepted'

interface AppliedModifier {
  quantity: number
  modifier: {
    id: string
    name: string
  }
  modifier_group: {
    id: string
    name: string
    modifier_arrangement: {
      [key: string]: string
    }
  }
}

interface OrderItem {
  applied_modifiers: AppliedModifier[]
  product_variant: {
    image: null | string
    name: string
    product: {
      modifier_group_arrangement: {
        [key: string]: string
      }
    }
  }
  quantity: number
  vat: number
  amount: number
}

export interface AdditionalCourierAddress {
  city: string
  contact_num: string
  country: string
  flat_number: null | string
  id: string
  line_1: string
  line_2: string
  location_type: null | string
  zip: string | null
}

export interface AdditionalCourier {
  address: AdditionalCourierAddress
  delivery_status: string
  dropoff_notes: null | string
  fulfillment_date: null | string
  recipient_details: RecipientDetails
  job_id: string
  store_id: string
  data: Delivery
  store: {
    id: string
    name: string
  }
}

export interface AdditionalStoreFeeCache {
  id: string
  name: string
  amount: string
  is_active: boolean
  removable: boolean
  amount_type: string
  fulfillment_types: {
    id: string
    pickup: boolean
    delivery: boolean
    table_ordering: boolean
  }
}

export interface Refund {
  amount: number
  inserted_at: string
  id: string
}

export interface Charge {
  id: string
  inserted_at: string
  reverted_at: string | null
  charge_type: string | null
  charge_type_reason: string | null
  amount: string
  transaction_log: {
    application_fee_amount: number
  }
  order: {
    transaction_logs: {
      application_fee_amount: number
    }[]
  }
  refund: Refund | null
}

export interface OrderInfo {
  id: string
  transaction_id: string
  merchant: OrderMerchant
  store: Store
  delivery: Delivery
  delivery_charge: number
  delta_delivery_charge: number
  delivery_charge_reduction: number
  delivery_charge_reduction_reason: string
  delivery_pricing_band: DeliveryPricingBand
  delivery_eta: number
  fulfillment_date: null | string
  fulfillment_type: 'delivery' | 'pickup'
  fulfillment_time_range: string
  inserted_at: string
  discounts_cache: null | DiscountCache
  customer_details: null | CustomerDetails
  recipient_details: null | RecipientDetails
  payment: Payment
  payment_status: null | 'captured' | 'partially_refunded' | 'fully_refunded'
  refunds: Refund[]
  dropoff_notes: null | string
  order_notes: null | string
  additional_notes: null | string
  gift_wrap_price: number
  gift_wrap_messagge: null | string
  note_updated_at: null | string
  status: null | OrderStatus
  order_items: OrderItem[]
  misc_fees: any
  tip_value: number
  metadata: any
  additional_couriers: AdditionalCourier[]
  additional_store_fee: number
  additional_store_fee_cache: AdditionalStoreFeeCache
  subtotal: number
  total: number
  custom_field_value: null | string
  custom_field_name: null | string
  gift_wrap_message: null | string
  merchant_settings: OrderMerchantSettings
  create_device_category: null | string
  create_platform: null | string
  create_os_type: null | string
  pickup_status: null | PickupStatuses
}

interface DeliveryPricingBand {
  lower_threshold: number
  upper_threshold: number
  percentage_discount: number
}

export type DeliveryFeeRecipient = 'default' | 'merchant' | 'slerp'

export interface OrderMerchantSettings {
  id: string
  updated_at: string
  inserted_at: string
  is_direct_payer: boolean
  money_flow_version: 1 | 2
  hosts_own_preorders: boolean
  hosts_own_deliveries: boolean
  delivery_courier_partner: boolean
  same_day_rate_cards_enabled: boolean
  pre_order_rate_cards_enabled: boolean
  same_day_delivery_fee_recipient: DeliveryFeeRecipient
  pre_order_delivery_fee_recipient: DeliveryFeeRecipient
  same_day_delivery_courier_partner: boolean
  pre_order_to_sameday_quote_enabled: boolean
}

export interface RefundItem {
  name: string
  reason: string
  amount: string
  value: number
  created: string
}
export interface AdditionalCharge {
  name: string
  description?: string | null
  tooltip?: any
  amount: string
  amountBefore?: string
}
