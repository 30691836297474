import React, { useState, useEffect } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import 'chart.js'
import moment from 'moment'
import Chart from 'react-google-charts'
import { AreaChart, ColumnChart } from 'react-chartkick'
import { Row, Col, Statistic, Select, Radio, Divider, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { useSession, isAdmin } from '@slerp/accounts'
import OrderMap from './OrderMap'
import ProductOverview from './ProductOverview'

import {
  QUERY_ORDER_STATS,
  QUERY_MERCHANT_STORES,
  QUERY_ORDER_HOURLY_SUMMARY,
  QUERY_ORDER_DAILY_SUMMARY
} from './StatsQueries'

import Loading from '../Utils/Loading'

const { Option } = Select

interface StoreStatsProps {
  merchantId: string
  storeId: string | null
  period: number
}

interface Store {
  archived_at: string | null
  id: string
  name: string
}

const BreakdownChart = (props: any) => {
  const { data, colors } = props

  const slices: { [key: number]: {} } = {}
  colors.forEach((color: string, i: number) => {
    slices[i] = { color: color }
  })

  return (
    <Chart
      width={'200px'}
      height={'200px'}
      chartType='PieChart'
      data={[['Type', 'Orders'], ...data]}
      options={{
        legend: 'none',
        slices,
        chartArea: { width: '90%', height: '90%' }
      }}
      style={{ margin: '0 auto 28px auto' }}
    />
  )
}

const DATE_FORMAT = 'YYYY-MM-DD'
const PERIOD_BOUNDARIES = [
  [moment().format(DATE_FORMAT), moment().format(DATE_FORMAT)],
  [
    moment().add(-1, 'd').format(DATE_FORMAT),
    moment().add(-1, 'd').format(DATE_FORMAT)
  ],
  [moment().add(-1, 'w').format(DATE_FORMAT), moment().format(DATE_FORMAT)],
  [moment().add(-1, 'M').format(DATE_FORMAT), moment().format(DATE_FORMAT)]
]

const zeroIfNaN = (value: number) => (isNaN(value) ? 0 : value)

const TODAY = 0
const YESTERDAY = 1

const hourlySummary = (period: number) => {
  return period in [TODAY, YESTERDAY]
}

const summaryQuery = (period: number) => {
  if (hourlySummary(period)) return QUERY_ORDER_HOURLY_SUMMARY
  else return QUERY_ORDER_DAILY_SUMMARY
}

const StoreStats = (props: StoreStatsProps) => {
  const { merchantId, storeId, period } = props
  const [startDate, endDate] = PERIOD_BOUNDARIES[period]
  let allDayHour: { [key: string]: number } = {}

  const { data: stats, refetch } = useQuery(QUERY_ORDER_STATS, {
    variables: { merchantId, startDate, endDate, storeId }
  })

  useEffect(() => {
    if (!stats) return

    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stats])

  const { data: summary } = useQuery(summaryQuery(period), {
    variables: { merchantId, startDate, endDate, storeId }
  })

  const statusCounts: { [key: string]: number } = {}
  const summaryData: { [key: string]: number | null } = {}

  if (stats) {
    stats.statusCounts.forEach((sc: { status: string; count: number }) => {
      statusCounts[sc.status] = sc.count
    })
  }

  if (summary) {
    const format = hourlySummary(period) ? 'ha' : 'D MMM YYYY'

    // define boundaries if series is empty
    if (summary.series.length === 0) {
      summaryData[moment(startDate).format(format)] = null
      summaryData[moment(endDate).format(format)] = null
    }

    // generate all hours in the day if period is today || yesterday
    if (hourlySummary(period)) {
      for (let hour = 0; hour < 24; hour++) {
        allDayHour[moment({ hour }).format('ha')] = 0
      }
      allDayHour[moment(2359, 'hmm').format('h:mm a')] = 0
    }

    // NOTE: data is returned in UTC, and is parsed by moment into local time
    summary.series.forEach((s: { ts: string; gmv: number }) => {
      summaryData[moment(s.ts).format(format)] = s.gmv
    })
  }

  const StatsBreakdown = () => (
    <Row justify='space-between' className='_mb-24'>
      <Col flex='0 0 calc(50% - 12px)' className='container-style _pl-0 _pr-0'>
        <Col span={24} className='_pt-12 _pr-16 _pb-12 _pl-16'>
          <span className='_mt-0'>Order types</span>
          <Tooltip
            title={
              <span>
                The split of all orders by{' '}
                <a
                  href='https://support.slerp.com/en_gb/order-types-Hk5at4p_'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  order types
                </a>
                .
              </span>
            }
            className='_ml-8'
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </Col>
        <Divider className='_mt-0 _mb-0' />
        <div
          className='_pt-24 _pr-16 _pb-16 _pl-16'
          style={{ width: '100%', paddingTop: '8px' }}
        >
          <BreakdownChart
            data={[
              ['ASAP', stats.accepted.asap],
              ['Same Day', stats.accepted.ssd],
              ['Pre-order', stats.accepted.pre]
            ]}
            colors={['#898', '#8b8', '#8d8']}
          />
          <ul className='breakdown-legend'>
            <li key='asap'>
              <span
                className='breakdown-legend-indicator'
                style={{ backgroundColor: '#898' }}
              />
              <span>{`ASAP (${stats.accepted.asap})`}</span>
            </li>
            <li key='ssd'>
              <span
                className='breakdown-legend-indicator'
                style={{ backgroundColor: '#8b8' }}
              />
              <span>{`Same Day (${stats.accepted.ssd})`}</span>
            </li>
            <li key='pre'>
              <span
                className='breakdown-legend-indicator'
                style={{ backgroundColor: '#8d8' }}
              />
              <span>{`Pre-order (${stats.accepted.pre})`}</span>
            </li>
          </ul>
        </div>
      </Col>
      <Col flex='0 0 calc(50% - 12px)' className='container-style _pl-0 _pr-0'>
        <Col span={24} className='_pt-12 _pr-16 _pb-12 _pl-16'>
          <span className='_mt-0'>Fulfilment types</span>
          <Tooltip
            title={
              <span>
                The split of all orders by{' '}
                <a
                  href='https://support.slerp.com/knowledge/fulfillment-types'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  the method they're received
                </a>
                .
              </span>
            }
            className='_ml-8'
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </Col>
        <Divider className='_mt-0 _mb-0' />
        <div
          className='_pt-24 _pr-16 _pb-16 _pl-16'
          style={{ width: '100%', paddingTop: '8px' }}
        >
          <BreakdownChart
            data={[
              ['Pickup', stats.accepted.pickup],
              ['Delivery', stats.accepted.delivery],
              ['Table Ordering', stats.accepted.table]
            ]}
            colors={['#88c', '#88e', '#89a']}
          />
          <ul className='breakdown-legend'>
            <li key='pickup'>
              <span
                className='breakdown-legend-indicator'
                style={{ backgroundColor: '#88c' }}
              />
              <span>{`Pickup (${stats.accepted.pickup})`}</span>
            </li>
            <li key='delivery'>
              <span
                className='breakdown-legend-indicator'
                style={{ backgroundColor: '#88e' }}
              />
              <span>{`Delivery (${stats.accepted.delivery})`}</span>
            </li>
            <li key='table-ordering'>
              <span
                className='breakdown-legend-indicator'
                style={{ backgroundColor: '#89a' }}
              />
              <span>{`Table Ordering (${stats.accepted.table})`}</span>
            </li>
          </ul>
        </div>
      </Col>
    </Row>
  )

  const OrdersChart = () => (
    <Row className='container-style _mb-0'>
      <Col className='container-style _pl-0 _pr-0' span={24}>
        <Col span={12} className='_pt-12 _pr-16 _pb-12 _pl-16'>
          <span className='_mt-0'>Orders Overview</span>
          <Tooltip
            title={
              <span>
                This graph gives you a trend overview of your orders revenue by
                day or hour so you can identify peak trading periods.
                <br />
                <br />
                Why not test your stores' opening times or capacity to see if
                you can generate more sales during your busy windows?
              </span>
            }
            className='_ml-8'
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </Col>
        <Divider className='_mt-0 _mb-0' />
        <Col
          span='24'
          style={{ marginTop: '32px' }}
          className='_pt-24 _pr-16 _pb-16 _pl-16'
        >
          {summary && (
            <AreaChart
              data={{ ...allDayHour, ...summaryData }}
              prefix='£'
              colors={['#D15BEE', '#fef0ff', '#C221EA']}
              thousands=','
              height='250px'
              library={{ responsive: true }}
            />
          )}
        </Col>
      </Col>
    </Row>
  )

  const PlatformStats = () => (
    <Col flex='0 0 calc(50% - 12px)' className='container-style _pl-0 _pr-0'>
      <Col span={24} className='_pt-12 _pr-16 _pb-12 _pl-16'>
        <span className='_mt-0'>Platforms</span>
      </Col>
      <Divider className='_mt-0 _mb-0' />
      <div
        className='_pt-24 _pr-16 _pb-16 _pl-16'
        style={{ width: '100%', paddingTop: '8px' }}
      >
        <BreakdownChart
          data={[
            ['Web - Mobile', stats.platform.web_mobile],
            ['Web - Desktop', stats.platform.web_desktop],
            ['iOS App - Mobile', stats.platform.ios_app_mobile],
            ['Android App - Mobile', stats.platform.android_app_mobile],
            ['Unknown', stats.platform.unknown]
          ]}
          colors={['#461507', '#a33210', '#eb592e', '#a33210', '#fdede8']}
        />
        <ul className='breakdown-legend'>
          <li key='web-mobile'>
            <span
              className='breakdown-legend-indicator'
              style={{ backgroundColor: '#461507' }}
            />
            <span>{`Web - Mobile (${stats.platform.web_mobile})`}</span>
          </li>
          <li key='web-desktop'>
            <span
              className='breakdown-legend-indicator'
              style={{ backgroundColor: '#a33210' }}
            />
            <span>{`Web - Desktop (${stats.platform.web_desktop})`}</span>
          </li>
          <li key='ios_app_mobile'>
            <span
              className='breakdown-legend-indicator'
              style={{ backgroundColor: '#eb592e' }}
            />
            <span>{`iOS - App (${stats.platform.ios_app_mobile})`}</span>
          </li>
          <li key='android_app_mobile'>
            <span
              className='breakdown-legend-indicator'
              style={{ backgroundColor: '#a33210' }}
            />
            <span>{`Android - App (${stats.platform.android_app_mobile})`}</span>
          </li>
          <li key='unknown'>
            <span
              className='breakdown-legend-indicator'
              style={{ backgroundColor: '#fdede8' }}
            />
            <span>{`Unknown (${stats.platform.unknown})`}</span>
          </li>
        </ul>
      </div>
    </Col>
  )

  const StatCounts = () => {
    // const statusKeys = Object.keys(statusCounts)
    const statusKeys = [
      'accepted',
      'fulfilled',
      'pending',
      'refunded',
      'rejected'
    ]
    const chartData = statusKeys.map((key) => {
      return [key, statusCounts[key]]
    })

    return (
      <Col className='container-style _pl-0 _pr-0' span={24}>
        <Col span={24} className='_pt-12 _pr-16 _pb-12 _pl-16'>
          <span className='_mt-0'>Order status</span>
          <Tooltip
            title={
              <span>
                <a
                  href='https://support.slerp.com/knowledge/accepting-rejecting-orders-via-slerp-app'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  A breakdown of all orders by status
                </a>
                . To rectify a disgruntled customer refund, why not send them a
                personalised discount code to win them back?
              </span>
            }
            className='_ml-8'
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </Col>
        <Divider className='_mt-0 _mb-0' />
        <Col className='_pt-24 _pr-16 _pb-16 _pl-16' span={24}>
          <ColumnChart
            data={chartData}
            colors={[['#E1F887', '#F5AB9A', '#9AE0F5', '#F5EC9A', '#E5E5E5']]}
            dataset={{ borderWidth: 0, borderSkipped: false }}
          />
        </Col>
      </Col>
    )
  }

  const StatCards = () => (
    <Row justify='space-between' className='_mb-0'>
      <Col className='container-style _pl-0 _pr-0' flex='0 0 calc(50% - 12px)'>
        <Col span={24} className='_pt-12 _pr-16 _pb-12 _pl-16'>
          <span className='_mt-0'>Accepted and Fulfilled Orders</span>
          <Tooltip
            title={
              <span>
                Here's an aggregated overview of the orders you've fulfilled
                from the above time frame.
              </span>
            }
            className='_ml-8'
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </Col>
        <Divider className='_mt-0 _mb-0' />
        <Row className='_pt-24 _pr-16 _pb-16 _pl-16 _mb-0'>
          <Col span={8} className='_text-center'>
            <Statistic
              value={stats.accepted.gmv}
              precision={2}
              prefix={'£'}
              className='_mb-16'
            />
            <div className='_cl-text-secondary'>SALES</div>
          </Col>
          <Col span={8} className='_text-center'>
            <Statistic value={stats.accepted.count} className='_mb-16' />
            <div className='_cl-text-secondary'>ORDERS</div>
          </Col>
          <Col span={8} className='_text-center'>
            <Statistic
              value={zeroIfNaN(stats.accepted.gmv / stats.accepted.count)}
              precision={2}
              prefix={'£'}
              className='_mb-16'
            />
            <div className='_cl-text-secondary'>AVG ORDER VALUE</div>
          </Col>
        </Row>
      </Col>
      <Col className='container-style _pl-0 _pr-0' flex='0 0 calc(50% - 12px)'>
        <Col span={24} className='_pt-12 _pr-16 _pb-12 _pl-16'>
          <span className='_mt-0'>Submitted Orders</span>
          <Tooltip
            title={
              <span>
                An overview of the orders you've received including all pending
                and refunded orders.
                <br />
                <br /> We suggest doing a mini audit each month to see why
                orders were refunded or <strong>rejected</strong>.
              </span>
            }
            className='_ml-8'
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </Col>
        <Divider className='_mt-0 _mb-0' />
        <Row className='_pt-24 _pr-16 _pb-16 _pl-16 _mb-0'>
          <Col span={8} className='_text-center'>
            <Statistic
              value={stats.submitted.gmv}
              precision={2}
              prefix={'£'}
              className='_mb-16'
            />
            <div className='_cl-text-secondary'>SALES</div>
          </Col>
          <Col span={8} className='_text-center'>
            <Statistic value={stats.submitted.count} className='_mb-16' />
            <div className='_cl-text-secondary'>ORDERS</div>
          </Col>
          <Col span={8} className='_text-center'>
            <Statistic
              value={zeroIfNaN(stats.submitted.gmv / stats.submitted.count)}
              precision={2}
              prefix={'£'}
              className='_mb-16'
            />
            <div className='_cl-text-secondary'>AVG ORDER VALUE</div>
          </Col>
        </Row>
      </Col>
    </Row>
  )

  return (
    <Row gutter={[0, 24]} justify='space-between' className='_w-100'>
      {stats ? (
        <>
          <Col span={24}>
            <StatCards />
          </Col>
          <Col span={24}>
            <OrdersChart />
          </Col>
          <Col span={24}>
            <Row
              data-testid='stats-component'
              justify='space-between'
              className='_mb-0'
              gutter={[24, 24]}
            >
              <Col span={12} flex='1 1 50%'>
                <StatsBreakdown />
                <StatCounts />
              </Col>
              <Col span={12} flex='1 1 50%'>
                <ProductOverview />
              </Col>
            </Row>
            <Row className='_mt-24'>
              <PlatformStats />
            </Row>
          </Col>
        </>
      ) : (
        <Col span={24}>
          <Row align='middle' justify='center'>
            <Loading />
          </Row>
        </Col>
      )}
    </Row>
  )
}

const Stats = () => {
  const { merchant, user } = useSession()
  const adminUser = isAdmin(user)

  const { data } = useQuery(QUERY_MERCHANT_STORES, {
    variables: {
      merchantId: merchant.id,
      email: user.email
    }
  })

  let history = useHistory()

  const stores = data?.stores || []
  const [storeId, setStoreId] = useState(null)
  const [period, setPeriod] = useState(2)
  const [defaultStore, setDefaultStore] = useState<string>(
    adminUser ? 'all' : (stores[0] || {}).id
  )

  const periods = ['Today', 'Yesterday', 'Last 7 Days', 'Last 30 Days']

  const handleStoreChange = (value: any) => {
    setStoreId(value === 'all' ? null : value)
  }

  const handlePeriodChange = (period: number) => {
    setPeriod(parseInt(period))
  }

  useEffect(() => {
    if (!stores) return
    setDefaultStore(adminUser ? 'all' : (stores[0] || {}).id)
    setStoreId(adminUser ? null : (stores[0] || {}).id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stores])

  return (
    <Row data-testid='stats-component' gutter={[0, 24]}>
      <Col span={24}>
        <Row justify='space-between' align='middle' className='_mb-0 _pt-4'>
          {defaultStore && (
            <Col span='auto' style={{ minWidth: '200px' }}>
              <Select
                className='stats-component__select'
                onChange={handleStoreChange}
                defaultValue={defaultStore}
              >
                {adminUser && (
                  <Option key='all' value='all'>
                    All Locations
                  </Option>
                )}
                {data &&
                  data.stores.map(({ id, name }: Store) => (
                    <Option key={id} value={id}>
                      {name}
                    </Option>
                  ))}
              </Select>
            </Col>
          )}
          <Col span='auto'>
            <Select
              className='stats-component__select'
              onChange={handlePeriodChange}
              defaultValue={period}
              style={{ marginLeft: '8px', minWidth: '136px' }}
            >
              {periods.map((period, i) => (
                <Radio.Button key={i} value={i}>
                  {period}
                </Radio.Button>
              ))}
            </Select>
          </Col>
          <Col span='auto' style={{ marginLeft: 'auto', minWidth: '280px' }}>
            <Radio.Group
              defaultValue={history.location.pathname}
              buttonStyle='solid'
              className='analytics__heat-map'
            >
              <Radio.Button
                value='/'
                name='analytics-heatmap'
                onChange={() => history.push('/')}
                checked={history.location.pathname === '/'}
                defaultChecked
              >
                <Col className='_mt-2'>
                  <span>Analytics</span>
                </Col>
              </Radio.Button>
              <Radio.Button
                value='/heat-map'
                name='analytics-heatmap'
                onChange={() => history.push('/heat-map')}
                checked={history.location.pathname === '/heat-map'}
              >
                <Col className='_mt-2'>
                  <span>Heat Map</span>
                </Col>
              </Radio.Button>
            </Radio.Group>
          </Col>
        </Row>
      </Col>

      <Switch>
        <Route
          path={'/'}
          exact
          render={(props: any) => (
            <StoreStats
              {...props}
              merchantId={merchant.id}
              storeId={storeId}
              period={period}
            />
          )}
        />
        <Route
          path={`/heat-map`}
          component={() => (
            <OrderMap
              merchantId={merchant.id}
              storeId={storeId === 'all' ? null : storeId}
              period={period}
              periodBoundaries={PERIOD_BOUNDARIES}
            />
          )}
        />
      </Switch>
    </Row>
  )
}

export default Stats
