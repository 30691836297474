import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Tooltip from 'antd/lib/tooltip'
import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useSession } from '@slerp/accounts'
import { usePageViewTracker } from 'packages/@slerp/tracker'
import { useGlobalQuery } from 'components/Utils/useGlobalQuery'
import { IoArrowBack } from 'react-icons/io5'
import styled from '@emotion/styled'

interface PageHeaderType {
  isShown?: boolean
  title?: string
  tooltipTitle?: JSX.Element | string
}

interface Props {
  pageHeader: PageHeaderType | null
}

const LayoutTitle = ({ pageHeader }: Props) => {
  usePageViewTracker()
  const { merchant } = useSession()
  const { hasExpiredDefaultCard } = useGlobalQuery()
  const location = useLocation()
  const history = useHistory()

  const isAvailabilityRules = location?.pathname?.match(
    /\/availability-rules(?:\/|$)/
  )

  const isAvailabilityRulesForm =
    location?.pathname?.endsWith('/availability-rules/create') ||
    (location?.pathname?.includes('/availability-rules/') &&
      location.pathname.split('/availability-rules/rule/')[1]?.length > 0)

  // useParams() does not seem to work in this case
  const getStoreNameFromPath = () => {
    const merchantSlug = merchant?.slug
    const matchStoreSlug =
      location?.pathname?.match(/\/location\/(.*?)\/availability-rules/) ?? []
    const storeSlug =
      isAvailabilityRules && matchStoreSlug ? matchStoreSlug[1] : ''

    const storeName = storeSlug
      .replace(`${merchantSlug}-`, '')
      .replace(/-/g, ' ')

    return storeName
  }

  const handleBackToLocation = () => {
    const path = isAvailabilityRules
      ? location?.pathname?.replace('/availability-rules', '')
      : ''

    return history.push(path)
  }

  return isAvailabilityRulesForm ? (
    <></>
  ) : (
    <PageTitle withExpired={hasExpiredDefaultCard}>
      {isAvailabilityRules && (
        <BackToStore align='middle' onClick={handleBackToLocation}>
          <IoArrowBack size='16' />
          <StoreName>{getStoreNameFromPath()}</StoreName>
        </BackToStore>
      )}
      <Row align='middle' justify='space-between'>
        <Col>
          <Tooltip
            placement='right'
            title={pageHeader?.tooltipTitle}
            overlayStyle={{
              visibility: `${pageHeader?.tooltipTitle ? 'visible' : 'hidden'}`
            }}
          >
            <h1>{pageHeader?.title}</h1>
          </Tooltip>
        </Col>
        <Col id='layout-actions'></Col>
      </Row>
    </PageTitle>
  )
}

const PageTitle = styled(Row)<{ withExpired: boolean | null }>`
  margin: ${({ withExpired }) => (withExpired ? '48px' : '36px')} 48px 0 48px;

  > .ant-row {
    width: 100%;
  }

  h1 {
    position: relative;

    display: inline-block;
    margin: 0;

    cursor: default;
    color: #4a4a68;
    font-weight: 700;
    font-size: 36px;
    line-height: 54px;
    letter-spacing: -0.02em;
  }

  h1::after {
    display: inline-block;
    content: '';
    margin-left: 2px;
    height: 8px;
    width: 8px;
    background-color: #d092dd;
    border-radius: 100%;
  }
`

const BackToStore = styled(Row)`
  cursor: pointer;
  margin-bottom: 4px;
`

const StoreName = styled.span`
  margin-left: 8px;
  padding-top: 2px;

  color: #77778e;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-transform: capitalize;
`

export default LayoutTitle
