import React from 'react'
import { Form, Input, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

interface MobileAppStoreInputProps {
  label: string
  tooltipTitle: string
  type: 'apple_store_url' | 'google_play_url'
}

const MobileAppStoreInput = ({
  type,
  label,
  tooltipTitle
}: MobileAppStoreInputProps) => {
  const testId = (type || '').replace('_', '-')
  return (
    <Form.Item
      name={type}
      label={
        <>
          {label}
          <Tooltip title={<span>{tooltipTitle}</span>}>
            <QuestionCircleOutlined className='_ml-8' />
          </Tooltip>
        </>
      }
    >
      <Input disabled data-testid={testId} />
    </Form.Item>
  )
}

export default MobileAppStoreInput
