/* eslint-disable camelcase */
import isEmpty from 'lodash/isEmpty'

// Find a better solution to fill later on
export const extractGoogleAddressComponents = (components) => {
  const isNonEssential = (componentType) => {
    return [
      'administrative_area_level_1',
      'administrative_area_level_2',
      'administrative_area_level_3',
      'administrative_area_level_4',
      'administrative_area_level_5'
    ].includes(componentType)
  }

  const { premise, subpremise } = components.reduce(
    (acc, component) => {
      const { types, long_name } = component
      if (types.includes('premise')) {
        acc.premise = long_name
      }
      if (types.includes('subpremise')) {
        acc.subpremise = long_name
      }
      return acc
    },
    { premise: '', subpremise: '' }
  )

  const reducer = (address, component) => {
    const { types } = component

    if (types.includes('route')) {
      return { ...address, line2: component.long_name }
    }
    if (types.includes('premise') || types.includes('subpremise')) {
      // Combine premise and subpremise
      const line1 = [premise, subpremise].filter(Boolean).join(' ')
      return { ...address, line1 }
    }
    if (types.includes('street_number')) {
      return { ...address, streetNumber: component.long_name }
    }
    if (types.includes('postal_code')) {
      return { ...address, zip: component.long_name }
    }
    if (types.includes('country')) {
      return { ...address, country: component.long_name }
    }
    if (types.includes('locality') || types.includes('postal_town')) {
      return { ...address, city: component.long_name }
    }
    if (types.some(isNonEssential)) return address

    return address
  }

  return components.reduce(reducer, {
    line1: '',
    line2: '',
    country: '',
    zip: '',
    city: '',
    streetNumber: ''
  })
}

const addressDataExists = (addressComponents, field) => {
  return (
    addressComponents.findIndex(({ types }) => {
      return types.includes(field)
    }) !== -1
  )
}

export const hasZipCode = (components) => {
  return addressDataExists(components, 'postal_code')
}

export const hasStreetAddress = (components) => {
  return (
    addressDataExists(components, 'street_number') ||
    addressDataExists(components, 'line1')
  )
}

export const getStreetAddress = ({ line1, streetNumber, line2, city }) => {
  return (
    `${line1 ? line1 + ' ' : ''}` +
    `${streetNumber ? streetNumber + ' ' : ''}` +
    `${line2 ? line2 + ' ' : ''}` +
    `${city || ''}`
  )
}

export const buildAddressFields = (
  components,
  metadata,
  defaultAddressDetails
) => {
  const fieldValues = extractGoogleAddressComponents(components)
  const hasStreetNumber = addressDataExists(components, 'street_number')

  if (metadata.address) {
    const { address } = metadata
    const { flatNumber, building, streetNumber } = address
    const line1 =
      flatNumber === '' && building === '' ? '' : `${flatNumber} ${building}`
    return {
      ...fieldValues,
      line1: line1,
      streetNumber: hasStreetNumber ? '' : streetNumber
    }
  }

  if (!isEmpty(defaultAddressDetails)) {
    const { flat_number, line_1, zip } = defaultAddressDetails

    // follows premise over user input if it exists
    const line1 = fieldValues.line1 === '' ? line_1 : fieldValues.line1

    // the zip from the customer has been used to geocode already
    // but sometimes google returns an incomplete zip code
    // so we use the more complete one from the customer details

    return {
      ...fieldValues,
      line1: line1 || '',
      flatNumber: flat_number || '',
      zip: zip
    }
  }

  return fieldValues
}

export const hasDefaultZip = (defaultAddressDetails) => {
  if (isEmpty(defaultAddressDetails)) return false

  return defaultAddressDetails.zip !== ''
}
