import React, { ReactNode, useState } from 'react'
import { Drawer } from 'antd'
import OrderInformation from './Info'
import styled from '@emotion/styled'

interface OrderDrawerProps {
  order: any
  onClose: any
  visible: boolean
  ordersRefetch?: () => {}
  ordersDataRefetch?: () => {}
}

const OrderDrawer = (props: OrderDrawerProps) => {
  const { order, onClose, ordersRefetch, visible, ordersDataRefetch } = props

  const [footerEntries, setFooterEntries] = useState<
    ReactNode | ReactNode[] | null
  >(null)

  return (
    <StyledDrawer
      onClose={onClose}
      visible={visible}
      className='order-drawer'
      footer={
        <>
          {footerEntries}
          <p className='order-drawer-footer'>
            * Slerp fees will be deducted in your monthly statement
          </p>
          <p className='order-drawer-footer'>
            * Delivery fee is based on the provided delivery location, in the
            case of an incorrect delivery location, the courier company may
            change the delivery estimate, contact the customer or subsequently
            cancel the delivery.
          </p>
        </>
      }
    >
      {order && (
        <OrderInformation
          transactionId={order.transaction_id}
          refetchCallback={() => {
            ordersRefetch && ordersRefetch()
            ordersDataRefetch && ordersDataRefetch()
          }}
          setFooterEntries={setFooterEntries}
        />
      )}
    </StyledDrawer>
  )
}

const StyledDrawer = styled(Drawer)`
  div.ant-drawer-header-no-title button.ant-drawer-close {
    margin: 24px 24px 0 auto;
    padding: 9px;

    span[role='img'].anticon.anticon-close {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 18px;
      height: 18px;
    }

    span[role='img'].anticon.anticon-close svg {
      width: 12px;
      height: 12px;
    }
  }
`

export default OrderDrawer
