import React, { Dispatch, SetStateAction } from 'react'
import { SelectFilterVariant } from './types'
import {
  getFilterOptions,
  getFilterLabel,
  getFilterLabelOptions
} from './utils'
import { SelectFilter } from '../../Common'

interface SelectFilterProps {
  setSelected: Dispatch<SetStateAction<string[]>>
  selected: string[]
  variant: SelectFilterVariant
  categoryOptions?: Array<{ value: string; label: string }>
}

const SelectFilterWrapper = ({
  setSelected,
  selected,
  variant,
  categoryOptions
}: SelectFilterProps) => {
  return (
    <SelectFilter<SelectFilterVariant>
      setSelected={setSelected}
      selected={selected}
      variant={variant}
      categoryOptions={categoryOptions}
      getFilterOptions={getFilterOptions}
      getFilterLabel={getFilterLabel}
      getFilterLabelOptions={getFilterLabelOptions}
    />
  )
}

export default SelectFilterWrapper
