import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { createClient } from '@slerp/client'
import { SettingOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import {
  Badge,
  Button,
  Col,
  message,
  Row,
  Select,
  Table,
  Tabs,
  Tag,
  Tooltip,
  Space
} from 'antd'
import { useParams, useHistory } from 'react-router-dom'
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
import { useSession } from '@slerp/accounts'
import { GET_ADMIN_USER_EMAIL, GET_MERCHANT_STORE } from '../LocationQueries'
import {
  SUBMITTED_ORDER_STATS,
  GET_STORE_ORDERS,
  GET_MERCHANT_ORDER_STORES,
  GET_DATES_WITH_ORDERS_BY_MONTH,
  QUERY_GET_STORE_SLUG_LOG,
  GET_MERCHANT_CATEGORIES
} from './DashboardQueries'
import { UPDATE_SETTINGS } from './../actions'
import Inventories from './Inventories'
import { LocationIcon, SelectDownArrowIcon } from '@slerp/assets'
import { SelectPrefixWrapper } from './../../Widgets/'
import OrderDrawer from '../../Orders/OrderDrawer'
import NotFound from '../../Misc/NotFound'
import { print } from '../../Orders/utils'
import { EyeOutlined, PrinterOutlined } from '@ant-design/icons'
import { RangeValue } from 'rc-picker/lib/interface'
import DatePicker from './DatePicker'
import SpecialAvailabilityDrawer from './SpecialAvailability'
import { Category } from './types'
import { createPortal } from 'react-dom'

interface StoreBasicInfo {
  id: string
  name: string
  slug: string
  isOpen: boolean
  settings: {
    prep_mode: string
  }
}

interface GetOrderStatsParams {
  merchantId: string
  startDate: string
  endDate: string
  storeId: string
}

interface OrdersData {
  transaction_id: string
  subtotal: number
  fulfillment_time_range: string
  fullfillment_type: string
  status: string
}

interface MerchantOrderStoresData {
  id: string
  slug: string
  name: string
}

const { TabPane } = Tabs
const DATE_FORMAT = 'YYYY-MM-DD'
const DEFAULT_ORDER_STATUSES = [
  'pending',
  'accepted',
  'fulfilled',
  'refunded',
  'cancelled',
  'rejected'
]
const ORDER_STATUSES_TAG_COLORS: { [key: string]: string } = {
  pending: 'orange',
  accepted: 'blue',
  fulfilled: 'green',
  refunded: 'purple',
  cancelled: 'red',
  rejected: 'magenta'
}

const PREP_TYPES = [
  {
    value: 'quiet',
    label: 'Quiet'
  },
  {
    value: 'moderate',
    label: 'Moderate'
  },
  {
    value: 'busy',
    label: 'Busy'
  }
]

const LocationDashboard = () => {
  const { slug } = useParams()
  const { user, merchant } = useSession()
  const history = useHistory()
  const apiKey = localStorage.getItem('token') || ''
  const client = createClient(apiKey)

  const [startDate, endDate] = [
    moment().format(DATE_FORMAT),
    moment().format(DATE_FORMAT)
  ]

  const [categories, setCategories] = useState<Category[]>([])
  const [currentTab, setCyrrentTab] = useState<string>('1')
  const [isSpecialAvailabilityDrawerOpen, setIsSpecialAvailabilityDrawerOpen] =
    useState<boolean>(false)
  const [ordersToday, setOrdersToday] = useState<number>(0)
  const [productSales, setProductSales] = useState<number>(0)
  const [orderStatusFilter, setOrderStatusFilter] = useState<string>('pending')
  const [filterStartDate, setFilterStartDate] = useState<moment.Moment>(
    moment()
  )
  const [filterEndDate, setFilterEndDate] = useState<moment.Moment>(
    moment().add('days', 1)
  )
  const [storeBasicInfo, setStoreBasicInfo] = useState<StoreBasicInfo>({
    id: '',
    name: '',
    slug: '',
    isOpen: false,
    settings: {
      prep_mode: ''
    }
  })

  const [merchantOrderStores, setMerchantOrderStores] = useState<
    Array<MerchantOrderStoresData>
  >([])
  const [prepModeLoading, setPrepModeLoading] = useState(false)

  const { loading: isLoadingCategories } = useQuery<{
    categories: Category[]
  }>(GET_MERCHANT_CATEGORIES, {
    variables: { merchantId: merchant.id },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setCategories(data.categories)
    }
  })

  const datesWithOrdersByMonth = useQuery(GET_DATES_WITH_ORDERS_BY_MONTH, {
    skip: true,
    fetchPolicy: 'no-cache'
  })
  const fetchDatesWithOrdersByMonth = datesWithOrdersByMonth.refetch
  const [datesWithOrders, setDatesWithOrders] = useState<{
    [key: string]: string[]
  }>({})

  const storeId = storeBasicInfo.id
  const merchantId = merchant.id

  const [updateSettings] = useMutation(UPDATE_SETTINGS, {
    fetchPolicy: 'no-cache'
  })

  // Checks slug has been change
  const [getLog, { data }] = useLazyQuery(QUERY_GET_STORE_SLUG_LOG, {
    variables: { storeSlug: slug, merchantId: merchantId }
  })

  useEffect(() => {
    getLog()
  }, [getLog])

  useEffect(() => {
    if (data?.slug_logs?.length) {
      const store = data.slug_logs[0].store
      if (store.slug !== slug) {
        // redirects to new slug
        history.replace(`/location/${store.slug}`)
      }
    }
  }, [data])

  const [activeOrder, setActiveOrder] = useState({})
  const [drawerVisible, setDrawerVisible] = useState(false)

  const showDrawer = (order: OrdersData) => {
    setDrawerVisible(true)
    setActiveOrder(order)
  }

  const onDrawerClose = () => {
    setDrawerVisible(false)
    setActiveOrder({})
  }

  const ORDER_TABLE_COLUMNS = [
    {
      title: 'Order Id',
      dataIndex: 'transaction_id',
      key: 'transaction_id',
      render: (id: string) => {
        return <strong className='_cl-primary'>{id}</strong>
      }
    },
    {
      title: 'Order For',
      dataIndex: 'fulfillment_time_range',
      key: 'fulfillment_time_range'
    },
    {
      title: 'Delivery | Pickup',
      dataIndex: 'fulfillment_type',
      key: 'fulfillment_type',
      render: (text: string) => {
        return <span className='_capitalize'>{text}</span>
      }
    },
    {
      title: 'Total',
      dataIndex: 'subtotal',
      key: 'subtotal',
      render: (text: number) => {
        return <span>£{text.toFixed(2)}</span>
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text: string = 'pending') => {
        return <Tag color={ORDER_STATUSES_TAG_COLORS[text]}>{text}</Tag>
      }
    },
    {
      title: 'Actions',
      dataIndex: 'transaction_id',
      align: 'right',
      render: (transaction_id: string, order: OrdersData) => {
        return (
          <Space>
            <Button
              type='default'
              icon={<EyeOutlined />}
              onClick={() => showDrawer(order)}
            />
            <Button
              icon={<PrinterOutlined className='_no-pointer-events' />}
              role='print'
              onClick={() => print(transaction_id)}
            />
          </Space>
        )
      }
    }
  ]

  const handlePrepModeChange = (mode: string) => {
    setPrepModeLoading(true)
    updateSettings({
      variables: {
        merchant_id: merchant.id,
        id: storeBasicInfo.id,
        settings: { prep_mode: mode }
      }
    }).then(() => {
      refetch()
      setPrepModeLoading(false)
    })
  }

  const orderDateOnchangeHandler = (dateRange: RangeValue<moment.Moment>) => {
    const start = (dateRange && dateRange[0]) || moment()
    const end = (dateRange && dateRange[1]) || moment()
    if (start.isSame(end, 'date')) {
      end.add('days', 1)
    }
    setFilterStartDate(start)
    setFilterEndDate(end)
  }

  const getOrderStats = async (params: GetOrderStatsParams) => {
    const { merchantId, startDate, endDate, storeId } = params
    const variables = {
      merchantId,
      startDate,
      endDate,
      storeId
    }

    return await client
      .query({
        query: SUBMITTED_ORDER_STATS,
        variables
      })
      .then((response) => {
        const { submitted } = response.data
        const { count = 0, gmv = 0 } = submitted || {}
        setProductSales(gmv)
        setOrdersToday(count)
      })
      .catch((error) => {
        message.destroy()
        message.error(`Unable to get order stats. Due to ${error.message}`, 3)
      })
  }

  const { data: ordersData, refetch: ordersDataRefetch } = useQuery(
    GET_STORE_ORDERS,
    {
      variables: {
        storeId: storeBasicInfo.id,
        status: orderStatusFilter,
        startDate: filterStartDate.format(DATE_FORMAT),
        endDate: filterEndDate.format(DATE_FORMAT)
      },
      skip: !storeBasicInfo.id
    }
  )

  let orders =
    ordersData && ordersData?.orders.map((order: OrdersData) => order)

  const isSuperAdmin = user.role === 'superadmin'

  const { data: adminUserData, loading: isLoadingAdminUserData } = useQuery<{
    users: Array<{ email: string }>
  }>(GET_ADMIN_USER_EMAIL, {
    variables: {
      merchant_id: merchant.id
    },
    skip: !isSuperAdmin
  })

  const {
    data: storeData,
    loading: storeDataLoading,
    refetch
  } = useQuery(GET_MERCHANT_STORE, {
    variables: {
      merchant_id: merchant.id,
      store_slug: slug,
      email: isSuperAdmin ? adminUserData?.users[0]?.email : user.email
    },
    fetchPolicy: 'no-cache',
    skip: isSuperAdmin && isLoadingAdminUserData && !adminUserData
  })

  const { data: merchantOrderStoresData } = useQuery(
    GET_MERCHANT_ORDER_STORES,
    {
      variables: {
        merchantId: merchant.id,
        userEmail: user.email
      }
    }
  )

  useEffect(() => {
    if (storeData && storeData.stores?.length) {
      const { id, name, slug, is_open, settings } = storeData.stores[0]
      setStoreBasicInfo({ id, name, slug, isOpen: is_open, settings })
      getOrderStats({
        merchantId: merchant.id,
        storeId: id,
        startDate,
        endDate
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeData])

  useEffect(() => {
    if (storeBasicInfo.id) {
      ordersDataRefetch({
        storeId: storeBasicInfo.id,
        status: orderStatusFilter,
        startDate: filterStartDate.format(DATE_FORMAT),
        endDate: filterEndDate.format(DATE_FORMAT)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStartDate, filterEndDate, orderStatusFilter, storeBasicInfo])

  useEffect(() => {
    if (merchantOrderStoresData) {
      const { stores } = merchantOrderStoresData
      setMerchantOrderStores(stores)
    }
  }, [merchantOrderStoresData])

  const moveToStore = (storeSlug: string) => {
    window.location.pathname = `/location/${storeSlug}`
  }

  if (!storeDataLoading && storeData && !storeData.stores?.length) {
    return <NotFound />
  }

  const portalTarget = document.getElementById('layout-actions')

  return (
    <>
      {portalTarget &&
        createPortal(
          <Row justify='end' align='middle' gutter={12} className='_mb-0'>
            <Col>
              <Button
                className='_ht-36'
                onClick={() => setIsSpecialAvailabilityDrawerOpen(true)}
                data-testid='inventory-dashboard-special-availability-btn'
              >
                Special Availability
              </Button>
            </Col>
            <Col>
              <Button
                className='_ht-36'
                onClick={() =>
                  history.push(`/location/${slug}/availability-rules`)
                }
                data-testid='inventory-dashboard-special-availability-btn'
              >
                Category cut-offs
              </Button>
            </Col>
          </Row>,
          portalTarget
        )}
      <Row justify='space-between' align='middle' className='_mb-8'>
        <Row gutter={8} align='middle'>
          {merchantOrderStores.length > 1 && (
            <Col>
              {storeBasicInfo.id && merchantOrderStores && (
                <SelectPrefixWrapper prefixicon={<LocationIcon />}>
                  <Select
                    suffixIcon={<SelectDownArrowIcon />}
                    defaultValue={storeBasicInfo.slug}
                    onChange={moveToStore}
                    style={{
                      minWidth: 'max-content'
                    }}
                    dropdownStyle={{
                      minWidth: '200px'
                    }}
                  >
                    {merchantOrderStores.map((store) => (
                      <Select.Option key={store.id} value={store.slug}>
                        {store.name}
                      </Select.Option>
                    ))}
                  </Select>
                </SelectPrefixWrapper>
              )}
            </Col>
          )}
          <Col>
            <Tooltip
              title={
                <span>
                  Your{' '}
                  <a
                    href='https://support.slerp.com/knowledge/changing-your-preparation-time'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    activity mode
                  </a>{' '}
                  determines your prep time for each order and is reflected on
                  your tablet.
                </span>
              }
            >
              <Select
                className='stats-component__select'
                onChange={handlePrepModeChange}
                value={storeBasicInfo?.settings?.prep_mode}
                loading={prepModeLoading}
              >
                {PREP_TYPES.map((type: { value: string; label: string }) => (
                  <Select.Option key={type.value} value={type.value}>
                    {type.label}
                  </Select.Option>
                ))}
              </Select>
            </Tooltip>
          </Col>
        </Row>
        <Col>
          <Row justify='end'>
            <Tooltip
              title={
                <span>
                  Manage your store's opening hours, users and order types here.
                </span>
              }
            >
              <Button
                icon={<SettingOutlined style={{ verticalAlign: '0.125em' }} />}
                href={`/location/${slug}/settings/general`}
                className='_center-vertical _pb-0 _uppercase'
              >
                Settings
              </Button>
            </Tooltip>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Tabs type='card' defaultActiveKey='1'>
            <TabPane
              tab={<a data-testid='location-orders-tab-pane'>ORDERS</a>}
              key='1'
            >
              <>
                <Row align='middle' gutter={[8, 8]} justify='space-between'>
                  <Col>
                    <Row justify='start' align='middle' gutter={40}>
                      <Col className='orders-dashboard-insights'>
                        <Badge
                          color='purple'
                          status='processing'
                          text={
                            <b className='badge-text -xxxl'>{ordersToday}</b>
                          }
                        />
                        <span className='badge -padding -margin'>
                          Orders Today
                        </span>
                      </Col>
                      <Col>
                        <Badge
                          color='purple'
                          status='processing'
                          text={
                            <b className='badge-text -xxxl'>{`£${productSales}`}</b>
                          }
                        />
                        <span className='badge -padding'>Product Sales</span>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row justify='end' align='middle' gutter={8}>
                      <Col span={12}>
                        <DatePicker
                          {...{
                            datesWithOrders,
                            fetchDatesWithOrdersByMonth,
                            merchantId,
                            storeId,
                            setDatesWithOrders,
                            orderDateOnchangeHandler
                          }}
                        />
                      </Col>
                      <Col>
                        <Select
                          className='_capitalize'
                          defaultValue={`${orderStatusFilter} Orders`}
                          onChange={(val: string) => setOrderStatusFilter(val)}
                        >
                          {DEFAULT_ORDER_STATUSES.map((status) => (
                            <Select.Option
                              key={status}
                              value={status}
                              className='_capitalize'
                            >
                              {status} Orders
                            </Select.Option>
                          ))}
                        </Select>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row justify='center'>
                  <Col span={24}>
                    <Table
                      onRow={(record: OrdersData) => ({
                        onClick: (e) => {
                          const el = e.target as HTMLElement
                          const role =
                            el && el.getAttribute && el.getAttribute('role')
                          if (role !== 'print') showDrawer(record)
                        }
                      })}
                      columns={ORDER_TABLE_COLUMNS}
                      dataSource={orders}
                      rowClassName='_pointer'
                    />
                  </Col>
                </Row>
                <OrderDrawer
                  order={activeOrder}
                  visible={drawerVisible}
                  onClose={onDrawerClose}
                  ordersRefetch={refetch}
                  ordersDataRefetch={ordersDataRefetch}
                />
              </>
            </TabPane>
            <TabPane
              tab={
                <Tooltip
                  title={
                    <span>
                      This is where you manage your store's product stock
                      levels. Learn more about inventories{' '}
                      <a
                        href='https://support.slerp.com/knowledge/understanding-inventories'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        here
                      </a>
                      .
                    </span>
                  }
                >
                  <a data-testid='location-inventory-tab-pane'>INVENTORY</a>
                </Tooltip>
              }
              key='2'
            >
              <Col className='_mt-16'>
                <Tabs onChange={(value: string) => setCyrrentTab(value)}>
                  <Tabs.TabPane
                    tab={
                      <>
                        Same Day
                        <Tooltip
                          title={
                            <a
                              href='https://support.slerp.com/knowledge/understanding-inventories'
                              target='_blank'
                              rel='noreferrer'
                            >
                              Same day inventory
                            </a>
                          }
                        >
                          <QuestionCircleOutlined className='_ml-8' />
                        </Tooltip>
                      </>
                    }
                    key='1'
                  >
                    <Col className='_mt-24'>
                      <Inventories
                        storeInfo={storeBasicInfo}
                        isLoadingCategories={isLoadingCategories}
                        categories={categories}
                        categoryIds={categories?.map((category) => ({
                          category_id: { _eq: category.id }
                        }))}
                      />
                    </Col>
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={
                      <>
                        Pre-Orders
                        <Tooltip
                          title={
                            <a
                              href='https://support.slerp.com/knowledge/pre-order-inventory'
                              target='_blank'
                              rel='noreferrer'
                            >
                              Pre-orders Inventory
                            </a>
                          }
                        >
                          <QuestionCircleOutlined className='_ml-8' />
                        </Tooltip>
                      </>
                    }
                    key='2'
                  >
                    <Col className='_mt-24'>
                      <Inventories
                        storeInfo={storeBasicInfo}
                        isLoadingCategories={isLoadingCategories}
                        categories={categories}
                        categoryIds={categories?.map((category) => ({
                          category_id: { _eq: category.id }
                        }))}
                        isPreorder
                      />
                    </Col>
                  </Tabs.TabPane>
                </Tabs>
              </Col>
            </TabPane>
          </Tabs>
        </Col>
      </Row>

      <SpecialAvailabilityDrawer
        isVisible={isSpecialAvailabilityDrawerOpen}
        onClose={() => setIsSpecialAvailabilityDrawerOpen(false)}
        categories={categories}
        storeId={storeBasicInfo.id}
        stores={merchantOrderStores}
        isPreorder={currentTab === '2'}
      />
    </>
  )
}

export default LocationDashboard
