import React from 'react'
import { Row, Col, Button, Upload } from 'antd'
import { LatLng } from '@slerp/controls'
import { useSession } from '@slerp/accounts'
import { kml } from '@tmcw/togeojson'
import { DOMParser } from 'xmldom'
import { ASSET_HOST } from '@slerp/client'

type Coordinate = [number, number, number]

interface Props {
  onUpload: (path: LatLng[]) => void
  storeId: string
  preorder?: boolean
  busyDelivery?: boolean
}

const ReadKml = ({
  onUpload,
  storeId,
  preorder = false,
  busyDelivery = false
}: Props) => {
  const { user, merchant } = useSession()
  const exportKmlUrl = `https://${merchant.slug}.${ASSET_HOST}/controls/store/${storeId}/delivery_area/export?pre_order=${preorder}&busy_area=${busyDelivery}&token=${user.api_key}`

  const readKML = (file: File) => {
    const reader = new FileReader()

    reader.readAsText(file)
    reader.onload = () => {
      const parser = new DOMParser()
      const kmlFromFile = parser.parseFromString(reader.result)
      const geoJSON = kml(kmlFromFile)
      const deliveryPaths = geoJSON.features[0].geometry.coordinates[0].map(
        (coords: Coordinate) => {
          return { lng: coords[0], lat: coords[1] }
        }
      )

      onUpload(deliveryPaths)
    }
  }

  const handleBeforeUpload = (file: File) => {
    readKML(file)
  }

  return ['superadmin'].includes(user?.role) ? (
    <Row className='_my-12' justify='center'>
      <Col className='_mx-6'>
        <Upload itemRender={() => {}} beforeUpload={handleBeforeUpload}>
          <Button>UPLOAD KML</Button>
        </Upload>
      </Col>
      <Col className='_mx-6'>
        <Button href={exportKmlUrl} target='_blank' rel='noopener noreferrer'>
          DOWNLOAD KML
        </Button>
      </Col>
    </Row>
  ) : null
}

export default ReadKml
