import React from 'react'
import { useMutation } from '@apollo/client'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Button from 'antd/lib/button'
import Tooltip from 'antd/lib/tooltip'
import message from 'antd/lib/message'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useSession } from '@slerp/accounts'
import { OrderInfo } from '../type'
import errorReducer from 'errors/errorReducer'
import { REJECT_ORDER, ACCEPT_ORDER } from '../../OrderQueries'

interface PendingType {
  order: OrderInfo
  successCallback: () => void
}

const Pending = ({ order, successCallback }: PendingType) => {
  const { user } = useSession()
  const [rejectOrder, { loading: rejectingOrder }] = useMutation(REJECT_ORDER, {
    fetchPolicy: 'no-cache'
  })

  const [acceptOrder, { loading: acceptingOrder }] = useMutation(ACCEPT_ORDER, {
    fetchPolicy: 'no-cache'
  })

  const rejectOrderHandler = (e: React.MouseEvent) => {
    message.loading('Rejecting order... Please wait.', 1)
    rejectOrder({
      variables: { transactionId: order.transaction_id, userId: user.id }
    })
      .then((response) => {
        const { rejectOrder } = response?.data || {}
        message.success(`Order ${rejectOrder.transactionId} rejected!`, 1)
        successCallback()
      })
      .catch((error: Error) => {
        throw errorReducer({
          origin: 'Order',
          data: {
            error: error,
            message: error.message
          }
        })
      })
      .catch((error) => {
        message.error(`${error.message}`)
      })
  }

  const acceptOrderHandler = (e: React.MouseEvent) => {
    message.loading('Accepting order... Please wait.', 1)
    acceptOrder({
      variables: { transactionId: order.transaction_id, userId: user.id }
    }).then((response) => {
      const { acceptOrder } = response?.data || {}
      message.success(`Order ${acceptOrder.transactionId} accepted!`, 1)
      successCallback()
    })
  }

  return (
    <Row className='_mb-0' justify='end' align='middle' gutter={16}>
      <Col>
        <Tooltip
          title={
            <a
              href='https://support.slerp.com/knowledge/accepting-rejecting-orders-via-slerp-app'
              target='_blank'
              rel='noopener noreferrer'
            >
              Accepting & Rejecting Orders
            </a>
          }
          color='#8c8ca1'
        >
          <QuestionCircleOutlined className='_mr-16 _cl-white' />
        </Tooltip>
        <Button
          data-testid='order-info-actions-reject'
          title='reject'
          type='primary'
          disabled={acceptingOrder || rejectingOrder}
          ghost
          onClick={rejectOrderHandler}
        >
          Reject
        </Button>
      </Col>
      <Col>
        <Button
          data-testid='order-info-actions-accept'
          title='accept'
          type='primary'
          disabled={acceptingOrder || rejectingOrder}
          onClick={acceptOrderHandler}
        >
          Accept
        </Button>
      </Col>
    </Row>
  )
}

export default Pending
