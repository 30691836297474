import React, { Dispatch, SetStateAction } from 'react'
import { Select } from 'antd'
import styled from '@emotion/styled'
import checkIcon from 'packages/@slerp/assets/check.svg'

interface SelectFilterProps<Variants> {
  setSelected: Dispatch<SetStateAction<string[]>>
  selected: string[]
  variant: Variants
  categoryOptions?: Array<{ value: string; label: string }>
  getFilterOptions: (
    variant: Variants,
    categoryOptions?: Array<{ value: string; label: string }>
  ) => string[]
  getFilterLabel: (variant: Variants) => string
  getFilterLabelOptions: (
    variant: Variants,
    categoryOptions?: Array<{ value: string; label: string }>
  ) => Array<{ value: string; label: string }>
  tagRenderer?: () => { [key: string]: any }
}

export const SelectFilter = <T,>({
  setSelected,
  selected,
  variant,
  categoryOptions,
  getFilterOptions,
  getFilterLabel,
  getFilterLabelOptions,
  tagRenderer
}: SelectFilterProps<T>) => {
  const handleSelect = (value: string) => {
    // @ts-ignore
    const isAllSelected = value === 'all' ?? false
    const options = getFilterOptions(variant, categoryOptions)

    if (isAllSelected) {
      setSelected(['all', ...options])
    } else {
      // selected type + 1(the recently selected type)
      if (selected.length + 1 === options.length) {
        setSelected((prev) => ['all', ...prev, value as string])
      } else {
        setSelected((prev) => [...prev, value as string])
      }
    }
  }

  const handleDeselect = (value: string) => {
    // @ts-ignore
    const isAllSelected = value === 'all' ?? false

    if (isAllSelected) {
      setSelected([])
    } else {
      // any option that is being deselected will subsequently remove 'all'
      setSelected((prev) =>
        prev.filter((type) => type !== value && type !== 'all')
      )
    }
  }

  const defaultTagRenderer = () => {
    if (selected.includes('all')) {
      return {
        tagRender: (props: any) => {
          if (props.value === 'all') {
            return <SelectTag>{props.label}</SelectTag>
          }

          return <></>
        }
      }
    }

    return {
      maxTagCount: 1,
      maxTagPlaceholder: (ommittedValues: string[]) => (
        <div>{ommittedValues.length}</div>
      ),
      tagRender: (props: any) => {
        return <SelectTag>{props.label}</SelectTag>
      }
    }
  }

  return (
    <StyledSelect
      mode='multiple'
      options={getFilterLabelOptions(variant, categoryOptions)}
      defaultValue={['all']}
      onSelect={(value) => handleSelect(value as string)}
      onDeselect={(value) => handleDeselect(value as string)}
      value={selected}
      placeholder={`All ${getFilterLabel(variant)}`}
      dropdownRender={(menu) => <SelectDropDown>{menu}</SelectDropDown>}
      tokenSeparators={[',']}
      dropdownMatchSelectWidth={false}
      showSearch={false}
      showArrow
      {...(tagRenderer ? tagRenderer() : defaultTagRenderer())}
    />
  )
}

const StyledSelect = styled(Select)`
  min-width: 160px;
  height: 36px;

  .ant-select-selection-placeholder {
    left: unset;
    right: unset;

    line-height: 1.5;
    font-size: 14px;
    font-weight: 500;
    color: #4a4a68;
  }

  .ant-select-selection-overflow-item-rest {
    margin-left: 8px;
  }
`

const SelectDropDown = styled.div`
  .ant-select-item-option:first-of-type {
    font-weight: 600;
  }
  .ant-select-item-option {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
    &::before {
      content: '';
      display: block;
      height: 16px;
      width: 16px;
      border: 1px solid #dbd9df;
      border-radius: 4px;
      box-shadow: 0px 2px 2px -1px #4a4a681a inset;
    }
  }
  .ant-select-item-option-selected {
    &::before {
      content: '';
      background-image: url(${checkIcon});
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
`

const SelectTag = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 1.5;
  color: #4a4a68;
`
