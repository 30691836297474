export enum SelectFilterVariant {
  type = 'TYPE',
  category = 'CATEGORY',
  status = 'STATUS'
}

export enum RuleType {
  recurring = 'RECURRING',
  special = 'SPECIAL_DAY'
}

export enum AssociatedModel {
  merchant = 'MERCHANT',
  store = 'STORE'
}

export enum Status {
  active = 'ACTIVE',
  draft = 'DRAFT',
  archived = 'ARCHIVED'
}

enum Days {
  monday = 'MONDAY',
  tuesday = 'TUESDAY',
  wednesday = 'WEDNESDAY',
  thursday = 'THURSDAY',
  friday = 'FRIDAY',
  saturday = 'SATURDAY',
  sunday = 'SUNDAY'
}

export type CutoffDay = {
  id: string
  day: Days
  cutoff_days: number
  time: string
  enabled: boolean
}

export type AvailabilityRule = {
  associated_with: AssociatedModel
  status: Status
  categories: {
    id: string
    name: string
  }[]
  stores: {
    id: string
    name: string
  }[]
  cut_off_days: CutoffDay[]
  end_date: string
  id: string
  start_date: string
  type: RuleType
}
