import React, { useEffect } from 'react'
import { useMutation } from '@apollo/client'
import Button from 'antd/lib/button'
import message from 'antd/lib/message'
import { useSession } from '@slerp/accounts'
import { OrderInfo } from '../type'
import errorReducer from 'errors/errorReducer'
import { COMPLETE_ORDER } from '../../OrderQueries'
import { isFullyRefunded } from '../utils'
import { useGlobalQuery } from 'components/Utils/useGlobalQuery'

interface DeliveryCompleteButtonProps {
  order: OrderInfo
  successCallback?: () => void
}

const DeliveryCompleteButton = ({
  order,
  successCallback
}: DeliveryCompleteButtonProps) => {
  const { user } = useSession()
  const { setIsChangingOrderStatus } = useGlobalQuery()

  const [completeOrder, { loading }] = useMutation(COMPLETE_ORDER, {
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    setIsChangingOrderStatus(loading)

    return () => {
      setIsChangingOrderStatus(false)
    }
  }, [loading, setIsChangingOrderStatus])

  const completeOrderHandler = (e: React.MouseEvent) => {
    message.loading('Completing order... Please wait.', 1)
    completeOrder({
      variables: { transactionId: order.transaction_id, userId: user.id }
    })
      .then((response) => {
        const { completeOrder } = response?.data || {}
        message.success(`Order ${completeOrder.transactionId} completed!`, 1)

        if (successCallback) {
          successCallback()
        }
      })
      .catch((error: Error) => {
        throw errorReducer({
          origin: 'Order',
          data: {
            error: error,
            message: error.message
          }
        })
      })
      .catch((error) => {
        message.error(`${error.message}`)
      })
  }

  if (
    order?.delivery?.service === 'merchant' ||
    order?.fulfillment_type !== 'delivery' ||
    ['pending', 'rejected'].includes(order?.status || '')
  ) {
    return <></>
  }

  const isDisabled =
    loading ||
    order?.delivery?.delivery_status === 'fulfilled manually' ||
    isFullyRefunded(order) ||
    !['accepted', 'refunded'].includes(order.status || '')

  return (
    <Button
      data-testid='order-info-actions-complete'
      title='fulfill'
      type='primary'
      disabled={isDisabled}
      onClick={completeOrderHandler}
    >
      Delivery Complete
    </Button>
  )
}

export default DeliveryCompleteButton
