import React from 'react'
import { Divider, Tag, Tooltip } from 'antd'

/** For status filtering  */
const OrderStatusesFilter = () => {
  return [
    {
      text: 'Completed',
      value: 'fulfilled'
    },
    {
      text: 'Accepted',
      value: 'accepted'
    },
    {
      text: 'Fulfilled',
      value: 'fulfilled'
    },
    {
      text: 'Cancelled',
      value: 'cancelled'
    },
    {
      text: 'Pending',
      value: 'pending'
    }
  ]
}

const getStatusColor = (status: string) => {
  switch (status) {
    case 'accepted':
      return 'green'
    case 'fulfilled':
      return 'blue'
    case 'refunded':
      return 'gray'
    case 'cancelled':
      return 'red'
    case 'pending':
      return 'yellow'
    case 'rejected':
      return 'magenta'
    default:
      return 'lime'
  }
}

const getStatusTooltip = (status: string) => {
  switch (status) {
    case 'accepted':
      return (
        <span>
          The order has been taken on and is in the process of being fulfilled.
        </span>
      )
    case 'fulfilled':
      return <span>The order had been completed and is with the customer.</span>
    case 'refunded':
      return <span>The order has been refunded.</span>
    case 'cancelled':
      return <span>The order has been cancelled by your courier partner.</span>
    case 'pending':
      return <span>The order has yet to be accepted and processed.</span>
    case 'rejected':
      return <span>The order was not accepted by a member of your team.</span>
    default:
      return <></>
  }
}

const formatOrderStatus = (status: string, paymentStatus: string) => {
  return status !== 'refunded' && paymentStatus === 'partially_refunded' ? (
    <div style={{ textAlign: 'center' }}>
      <span>{status}</span>
      <Divider style={{ margin: '4px 0' }} />
      <span style={{ color: '#F38A19' }}>Partially Refunded</span>
    </div>
  ) : (
    status
  )
}

const OrderStatusTag = (status: string, paymentStatus: string) => {
  return (
    <Tooltip title={getStatusTooltip(status)}>
      <Tag
        color={getStatusColor(status)}
        style={{ textTransform: 'capitalize' }}
      >
        {formatOrderStatus(status, paymentStatus)}
      </Tag>
    </Tooltip>
  )
}

const OrderPaymentStatusTag = (paymentStatus: string) => {
  switch (paymentStatus) {
    case 'captured':
      return <Tag color='green'>Paid</Tag>
    case 'partially_refunded':
      return <Tag color='orange'>Partially Refunded</Tag>
    case 'fully_refunded':
      return <Tag color='gray'>Refunded</Tag>
    default:
      return <Tag color='orange'>Unpaid</Tag>
  }
}

export { OrderStatusesFilter, OrderStatusTag, OrderPaymentStatusTag }
