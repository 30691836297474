import React, { useState } from 'react'
import { Layout, Avatar, Dropdown, Row, Col } from 'antd'
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined
} from '@ant-design/icons'
import env from 'env'
import Sidebar from 'components/Layout/Sidebar'
import { DownArrowIcon, UpArrowIcon } from '@slerp/assets'
import LayoutTitle from './LayoutTitle'

const { Header } = Layout

interface Props {
  children: React.ReactNode
  userMenu: React.ReactElement<any>
  merchant?: {
    name: string
    slug: string
  }
  user: {
    firstname: string
  }
  pageHeader: PageHeader
}

interface PageHeader {
  isShown: boolean
  title?: string
  tooltipTitle?: JSX.Element | string
}

const LayoutContainer = (props: Props) => {
  const { children, userMenu, merchant, user, pageHeader } = props
  const [collapsed, setCollapsed] = useState(true)
  const [arrowDown, setDropdownToggle] = useState(false)
  const merchantStoreUrl = `https://${merchant?.slug}.${env.ASSET_HOST}`

  return (
    <Layout hasSider>
      <Sidebar collapsed={collapsed} />
      <Layout
        className='site-layout'
        style={{ marginLeft: collapsed ? 65 : 240 }}
      >
        <Header className='site-layout-background main-header'>
          <Row align='middle' justify='space-between'>
            <Col>
              {React.createElement(
                collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: 'trigger',
                  onClick: () => setCollapsed(!collapsed)
                }
              )}
              <a
                href={merchantStoreUrl}
                target='_blank'
                rel='noopener noreferrer'
                className='location-link'
              >
                Visit your location
              </a>
            </Col>
            <Col className='user-notifications'>
              <Dropdown
                overlay={userMenu}
                trigger={['click']}
                onClick={() => setDropdownToggle(!arrowDown)}
              >
                <div className='user-block'>
                  <Avatar
                    className='ant-menu-item-selected ant-menu-item-active'
                    size='large'
                    icon={<UserOutlined style={{ color: '#fff' }} />}
                    style={{ cursor: 'pointer', marginLeft: '16px' }}
                  />
                  <div>
                    <p>{merchant?.name} </p>
                    <span>{`${user.firstname}`}</span>
                  </div>
                  <div>{arrowDown ? <UpArrowIcon /> : <DownArrowIcon />}</div>
                </div>
              </Dropdown>
            </Col>
          </Row>
        </Header>
        <LayoutTitle pageHeader={pageHeader} />

        {children}
      </Layout>
    </Layout>
  )
}

export default LayoutContainer
