import React from 'react'
import { Col, Row, Table, Typography, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import {
  AdditionalCharge,
  AppliedModifier,
  OrderInfo,
  OrderItem
} from 'components/Orders/Info/type'
import { useSession } from '@slerp/accounts'
import AppliedModifiers from './AppliedModifiers'
import MiscFeeForm from './Forms/MiscFeeForm'
import {
  getAdditionalCharges,
  isSelfHosted
} from 'components/Orders/Info/utils'
import AppliedDiscount from './AppliedDiscount'
import { isEmpty } from 'lodash'

interface OrderItemsDetailsProps {
  order: OrderInfo
  reloadOrder: () => void
}

const { Column } = Table
const { Text } = Typography

const OrderItemsDetails = ({ order, reloadOrder }: OrderItemsDetailsProps) => {
  const { merchant } = useSession()
  const { order_items } = order
  const additionalCharges = getAdditionalCharges(order)

  const getTotalCharges = (order: OrderInfo): AdditionalCharge[] => {
    const isRefunded = !isEmpty(order.refunds)
    const isSubsidized =
      order.delta_delivery_charge &&
      order.delta_delivery_charge > 0 &&
      !isSelfHosted(order)
    const refundsTotal = isRefunded
      ? order.refunds.reduce((acc, curr) => acc + curr.amount, 0)
      : 0
    const trueTotal = order.total - refundsTotal

    return [
      ...(isRefunded
        ? [
            {
              name: `Total`,
              amount: `£${order.total.toFixed(2)}`
            },
            {
              name: `Refunds (${order.refunds?.length})`,
              amount: `-£${refundsTotal.toFixed(2)}`
            }
          ]
        : []),
      {
        name: 'Net Total*',
        amount: `£${trueTotal.toFixed(2)}`
      },
      ...(isSubsidized
        ? [
            {
              name: 'Delivery Subsidisation',
              description: 'Charged to partner',
              tooltip: (
                <Tooltip
                  title={
                    <span>
                      The Delivery Subsidisation (charged to partner) is the
                      quoted delivery fee from the courier partner minus the
                      Delivery fee (charged to customers)
                    </span>
                  }
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              ),
              amount: `£${order.delta_delivery_charge.toFixed(2)}`
            }
          ]
        : [])
    ]
  }

  return (
    <>
      <Table dataSource={order_items} pagination={false}>
        <Column
          width={210}
          title='Product'
          className='-plain _text-weight-600'
          dataIndex='product_variant.name'
          key='product'
          render={(text: string, record: OrderItem) => {
            return (
              <span>
                {record.quantity} x {record.product_variant.name}
              </span>
            )
          }}
        />
        <Column
          width={310}
          title='Applied modifiers'
          className='-plain'
          dataIndex='applied_modifiers'
          key='applied_modifiers'
          render={(value: AppliedModifier[], record: OrderItem) => {
            return value && value.length > 0 ? (
              <AppliedModifiers
                orderQuantity={record.quantity}
                appliedModifiers={value}
                arrangement={
                  record.product_variant.product.modifier_group_arrangement
                }
              />
            ) : (
              <span>N/A</span>
            )
          }}
        />
        <Column
          title='Price'
          className='-plain align-right'
          dataIndex='amount'
          key='amount'
          render={(price: number) => {
            return <span>£{price.toFixed(2)}</span>
          }}
        />
      </Table>
      {!!additionalCharges.length && (
        <Row justify='end' className='_mb-0'>
          <Table
            dataSource={additionalCharges}
            pagination={false}
            showHeader={false}
          >
            <Column
              width={520}
              title='Charge name'
              dataIndex='name'
              key='charge_name'
              render={(name: string, record: AdditionalCharge) => (
                <>
                  <Text strong>{name} </Text>
                  <span className='_cl-text-secondary-75'>
                    {record.description && `(${record.description}): `}
                    {record.tooltip}
                  </span>
                </>
              )}
            />
            <Column
              title='Charge amount'
              dataIndex='amount'
              key='charge_amount'
              className='align-right'
              width={130}
              render={(amount: string, record: AdditionalCharge) => (
                <span>
                  {record.amountBefore && <s>{record.amountBefore}</s>} {amount}
                </span>
              )}
            />
          </Table>
          {order?.discounts_cache?.code && (
            <AppliedDiscount
              discountCode={order.discounts_cache.code}
              merchantId={merchant.id}
            ></AppliedDiscount>
          )}
        </Row>
      )}
      <Row justify='end' className='_mb-0'>
        <Table
          dataSource={getTotalCharges(order)}
          showHeader={false}
          pagination={false}
        >
          <Column
            width={520}
            title='Charge name'
            dataIndex='name'
            key='name'
            render={(name: string, record: AdditionalCharge) => (
              <>
                <Text
                  strong
                  className={
                    ['Net Total*', 'Total'].includes(name) ? '_cl-primary' : ''
                  }
                >
                  {name}{' '}
                </Text>
                <span className='_cl-text-secondary-75'>
                  {record.description && `(${record.description}): `}
                  {record.tooltip}
                </span>
              </>
            )}
          />
          <Column
            title='Charge amount'
            dataIndex='amount'
            key='amount'
            className='align-right'
            width={130}
            render={(amount: string, record: AdditionalCharge) => (
              <span>{amount}</span>
            )}
          />
        </Table>
      </Row>
      <MiscFeeForm order={order} refetch={reloadOrder} />
    </>
  )
}

export default OrderItemsDetails
