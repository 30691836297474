import React, { useEffect, useState } from 'react'
import { useSession } from '@slerp/accounts'
import { useQuery } from '@apollo/client'
import { Tabs, Tooltip } from 'antd'
import { QUERY_MERCHANT_STRIPE_ACCOUNT_SESSION } from '../../Merchants/MerchantQueries'
import Loading from '../../Utils/Loading'
import Payments from './Payments'
import Attribution from './Attribution'
import CommissionRates from './CommissionRates'
import { Merchant } from 'types'
import Invoices from './Invoices'
import { useLocation } from 'react-router-dom'

interface Props {
  merchant: Merchant
}

const { TabPane } = Tabs

const AccountsAndBilling = ({ merchant: parentMerchant }: Props) => {
  const { merchant } = useSession()
  const location = useLocation()
  const [activeTab, setActiveTab] = useState<string>('accounts')
  const { data, loading } = useQuery(QUERY_MERCHANT_STRIPE_ACCOUNT_SESSION, {
    variables: { merchantSlug: merchant.slug }
  })

  useEffect(() => {
    const { clientSecret, publicKey } =
      data?.getMerchantStripeAccountSession || {}

    if (clientSecret && publicKey) {
      window.StripeConnect = window.StripeConnect || {}
      ;(async () => {
        window.StripeConnect.onLoad = () => {
          window.StripeConnect.init({
            publishableKey: publicKey,
            clientSecret: clientSecret,
            appearance: {
              colors: {
                primary: '#625afa',
                textLink: '#625afa'
              }
            },
            uiConfig: {
              overlay: 'dialog'
            }
          })
        }
      })()
    }
  }, [data])

  useEffect(() => {
    const currentPath = location.pathname

    if (currentPath.includes('/invoices')) {
      setActiveTab('invoices')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const tabChangeHandler = (key: string) => {
    const currentPath = location.pathname

    if (key !== 'invoices') {
      const path = currentPath.replace('/invoices', '')
      window.history.replaceState(null, '', path)
    }

    if (key === 'invoices') {
      window.history.replaceState(null, '', `${currentPath}/invoices`)
    }

    setActiveTab(key)
  }

  const isLoading = loading && data

  return (
    <Tabs activeKey={activeTab} onChange={tabChangeHandler} className='_mb-8'>
      <TabPane
        key='accounts'
        tab={
          <Tooltip title='Payments'>
            <span data-testid='stripe-accounts-tab-pane'>Accounts</span>
          </Tooltip>
        }
      >
        <Payments merchant={parentMerchant} />
      </TabPane>

      {parentMerchant?.setting?.franchise_enabled && (
        <TabPane
          key='franchise-attribution'
          tab={
            <Tooltip title='Franchise Attribution'>
              <span data-testid='stripe-franchise-attribution-tab-pane'>
                Franchise Attribution
              </span>
            </Tooltip>
          }
        >
          <Attribution merchant={parentMerchant} />
        </TabPane>
      )}

      {merchant?.stripe_account_type === 'slerp_pay' && (
        <>
          <TabPane
            key='payments'
            tab={
              <Tooltip title='Payments'>
                <span data-testid='stripe-payment-tab-pane'>Payments</span>
              </Tooltip>
            }
          >
            {isLoading ? (
              <Loading />
            ) : (
              <div className='container'>
                {/* @ts-ignore */}
                <stripe-connect-payments />
              </div>
            )}
          </TabPane>
          <TabPane
            key='payouts'
            tab={
              <Tooltip title='Payouts'>
                <span data-testid='stripe-payout-tab-pane'>Payouts</span>
              </Tooltip>
            }
          >
            {isLoading ? (
              <Loading />
            ) : (
              <div className='container'>
                {/* @ts-ignore */}
                <stripe-connect-payouts />
              </div>
            )}
          </TabPane>
          <TabPane
            key='invoices'
            tab={
              <Tooltip title='Invoices'>
                <span data-testid='invoices-tab-pane'>Invoices</span>
              </Tooltip>
            }
          >
            <Invoices />
          </TabPane>
          <TabPane
            key='pricing'
            tab={
              <Tooltip title='Pricing'>
                <span data-testid='stripe-pricing-tab-pane'>Pricing</span>
              </Tooltip>
            }
          >
            {isLoading ? (
              <Loading />
            ) : (
              <CommissionRates
                merchantCurrentPlanSlug={parentMerchant?.current_plan || ''}
                zeroOrderRateId={parentMerchant?.zero_order_rate_id}
              />
            )}
          </TabPane>
        </>
      )}
    </Tabs>
  )
}

export default AccountsAndBilling
