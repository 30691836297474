import React from 'react'
import { OrderInfo } from './type'
import { isFullyRefunded } from './utils'
import { Steps } from 'antd'
import { ExclamationCircleFilled } from '@ant-design/icons'
import styled from '@emotion/styled'

const { Step } = Steps

const GOOD_ORDER_STATES = ['pending', 'accepted', 'fulfilled']
const ACCEPTED_DELIVERY_STATUSES = [
  'pending',
  'searching',
  'scheduled',
  'picking',
  'almost_picking'
]
const OUT_FOR_DELIVERY_STATUSES = ['delivering', 'almost_delivering']

const READY_FOR_COLLECTION_STATUS = 'ready_for_collection'
const COMPLETED_STATUS = 'completed'

const OrderSteps = ({ order }: OrderInfo) => {
  const { status, delivery, fulfillment_type, pickup_status } = order

  const STEPS = [
    { title: 'Pending' },
    { title: 'Accepted' },
    ...(fulfillment_type === 'delivery'
      ? [{ title: 'Out for Delivery' }]
      : [{ title: 'Ready for Collection' }]),
    { title: 'Completed' }
  ]

  const getOrderCurrentStep = (
    orderStatus: string,
    deliveryStatus: string
  ): number => {
    if (orderStatus === 'pending') return 0
    if (orderStatus === 'fulfilled') return 3

    // Handle 'accepted' status
    if (orderStatus === 'accepted') {
      if (fulfillment_type === 'pickup') {
        if (pickup_status === READY_FOR_COLLECTION_STATUS) return 2
        if (pickup_status === COMPLETED_STATUS) return 3
        return 1
      } else {
        // For delivery orders, check delivery status
        if (ACCEPTED_DELIVERY_STATUSES.includes(deliveryStatus)) return 1
        if (OUT_FOR_DELIVERY_STATUSES.includes(deliveryStatus)) return 2
      }
    }

    return 0
  }

  const specificRefundStatusText = isFullyRefunded(order)
    ? 'Fully Refunded'
    : 'Partially Refunded'

  if (GOOD_ORDER_STATES.includes(status)) {
    return (
      <StepsContainer data-testid='order-info-steps-good-states'>
        <StyledSteps
          current={getOrderCurrentStep(
            status,
            delivery?.delivery_status ?? status
          )}
          size='small'
          labelPlacement='vertical'
        >
          {STEPS.map((step, index) => (
            <Step title={step.title} key={index} />
          ))}
        </StyledSteps>
      </StepsContainer>
    )
  }

  return (
    <div className='fallback-order-step' data-testid='order-info-steps-refund'>
      <p className='icon'>
        <ExclamationCircleFilled />
      </p>
      {status === 'refunded' ? specificRefundStatusText : status}
    </div>
  )
}

const StepsContainer = styled.div`
  padding: 24px 85px;
`

const StyledSteps = styled(Steps)`
  &.ant-steps.ant-steps-horizontal.ant-steps-label-vertical {
    justify-content: space-between;

    .ant-steps-item-container {
      display: flex;
      flex-flow: column;
    }

    .ant-steps-item {
      flex: none;
    }
  }

  .ant-steps-item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin: 0 !important;

    width: 42px;
    height: 42px;
  }

  .ant-steps-item-container div.ant-steps-item-tail {
    top: 15px;
    left: 11px;

    &::after {
      height: 4px;
      background-color: #e7e5e9;
    }
  }

  .ant-steps-item-finish {
    .ant-steps-item-icon {
      background-color: #4a4a68;
      border-color: #4a4a68;
      box-shadow: 0px 1px 2px 0px #4a4a681a;

      svg path {
        fill: #fff;
      }
    }

    .ant-steps-item-container div.ant-steps-item-tail::after {
      background-color: #4a4a68;
    }
  }

  .ant-steps-item-active .ant-steps-item-icon span.ant-steps-icon,
  .ant-steps-item-wait .ant-steps-item-icon span.ant-steps-icon {
    height: auto;

    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 0.02em;
  }

  .ant-steps-item-active {
    .ant-steps-item-icon {
      background-color: white;
      box-shadow: 0px 0px 0px 4px #d092dd33;
      border: 2px solid #d092dd;
    }

    .ant-steps-item-icon span.ant-steps-icon {
      color: #d092dd;
    }
  }

  .ant-steps-item-wait {
    .ant-steps-item-icon {
      background-color: #f3f2f4;
      box-shadow: none;
      border: 2px solid #e7e5e9;
    }

    .ant-steps-item-icon span.ant-steps-icon {
      color: #8c8ca1;
    }
  }

  .ant-steps-icon {
    width: 14px;
    height: 10px;
  }

  .ant-steps-item-title {
    color: #4a4a68 !important;
    font-weight: 600;
    font-size: 10px;
    line-height: 1;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }

  .ant-steps-item-content {
    margin-top: 10px;
  }
`

export default OrderSteps
