interface addressType {
  flat_number: null | string
  line_1: null | string
  line_2: string
  city: string
  zip: string
  lat: string
  lng: string
  country?: string
  coords?: {
    lat: number
    lng: number
  }
}

export interface CustomerDetailsType {
  id: string
  email: string
  address: null | addressType
  last_name: string
  first_name: string
  contact_num: string
}

export interface RecipientType {
  id: string
  last_name: string
  first_name: string
  contact_num: string
}

interface DriverType {
  first_name: string
  last_name: string
  phone: string
  transport_type: string
}

export interface DeliveryType {
  cancelled_by: null | string
  default_transport_type: string
  delivery_status: null | string
  driver: null | DriverType
  dropoff_time: null | string
  id: string
  initial_fee: string
  inserted_at: string
  job_id: null
  pickup_time: null | string
  proxy: null
  proxy_job_id: null
  quote_id: null
  service: string
  updated_at: string
}

interface DiscountBreakdownType {
  amount: string
  discount: string
  id: string
  item: string
  item_id: null | string
}

interface DiscountsCacheType {
  code: string
  discount_breakdown: Array<DiscountBreakdownType>
  id: string
  target: string
  target_meta: null
  total_discount: string
  type: string
  value: string
}

interface MerchantType {
  name: string
  pre_order_settings: any
}

interface AppliedModifierType {
  modifier: {
    id: string
    name: string
  }
  modifier_group: {
    id: string
    modifier_group_arrangement: {
      [key: string]: string
    }
    name: string
  }
  quatity: number
}

interface ProductVariantType {
  image: string
  name: string
  product: {
    modifier_group_arrangement: {
      [key: string]: string
    }
  }
}

interface OrderItemType {
  applied_modifiers: Array<AppliedModifierType>
  product_variant: ProductVariantType
  amount: number
  quantity: number
  vat: number
}

interface FeeDetailType {
  amount: number
  application: null
  currency: string
  description: string
  type: string
}

interface BalanceTransactionType {
  amount: number
  available_on: number
  currency: string
  description: string
  exchange_rate: null
  fee: 32
  fee_details: Array<FeeDetailType>
  id: string
  net: number
  object: string
  source: string
  status: string
  type: string
}

interface BillingDetailsType {
  address: {
    city: null
    country: null
    line1: null
    line2: null
    postal_code: null
    state: null
  }
  email: string
  name: string
  phone: string
}
interface PaymentBrandType {
  brand: string
  last4: string
}
interface PaymentDataType {
  amount: number
  amount_refunded: number
  application: string
  application_fee: null
  application_fee_amount: null
  balance_transaction: BalanceTransactionType
  billing_details: BillingDetailsType
  captured: boolean
  created: number
  currency: string
  customer: null
  description: string
  dispute: null
  failure_code: null
  failure_message: null
  fraud_details: any
  id: string
  invoice: null
  livemode: boolean
  metadata: {
    cart_id: string
  }
  object: string
  on_behalf_of: null
  order: null
  outcome: {
    network_status: string
    reason: null | string
    risk_level: string
    risk_score: number
    seller_message: string
    type: string
    authorized: string
  }
  paid: boolean
  payment_intent: string
  payment_method: string
  payment_method_details: {
    card: {
      brand: string
      checks: {
        address_line1_check: null
        address_postal_code_check: null
        cvc_check: string
      }
      country: string
      exp_month: number
      exp_year: number
      fingerprint: string
      funding: string
      installments: null
      last4: string
      network: string
      three_d_secure: null
      wallet: null
    }
    type: string
  }
  receipt_email: null
  receipt_number: null
  receipt_url: string
  refunded: boolean
  refunds: {
    url: string
    data: RefundData[]
  }
  review: null
  shipping: null
  source: null | PaymentBrandType
  source_transfer: null
  statement_descriptor: null
  statement_descriptot_suffix: null
  status: string
  transfer: null
  transfer_data: null
  transfer_group: null
}

interface RefundData {
  id: string
  amount: number
  charge: string
  object: string
  reason: null | string
  status: string
  created: number
  currency: string
  metadata: {
    reason: string
  }
  failure_reason: null | string
  transfer_reversal: string
  balance_transaction: string
}

interface PaymentType {
  data: PaymentDataType
  id: string
  reference: string
  service: string
}

export interface OperatingHours {
  enabled: boolean
  start: string
  end: string
  special_date?: string
}

export interface OperatingSchedules {
  monday: OperatingHours
  tuesday: OperatingHours
  wednesday: OperatingHours
  thursday: OperatingHours
  friday: OperatingHours
  saturday: OperatingHours
  sunday: OperatingHours
}

export interface PreOrderSettings {
  fixed_delivery_times_enabled: boolean
  fixed_delivery_times: OperatingHours
  cutoffs: CutoffSchedule
  special_cutoffs: Cutoff[]
  delivery_schedule: OperatingSchedules
  delivery_interval: number
}

export interface Cutoff {
  cutoff_date: string | null
  enabled: boolean
}

export interface CutoffSchedule {
  monday: Cutoff
  tuesday: Cutoff
  wednesday: Cutoff
  thursday: Cutoff
  friday: Cutoff
  saturday: Cutoff
  sunday: Cutoff
}

export interface StoreSettings {
  prep_mode?: 'busy' | 'quiet' | 'moderate'
  quiet_preparation_time: number
  moderate_preparation_time: number
  busy_preparation_time: number
  pickup_interval: number
  delivery_interval: number
}

export interface StoreType {
  id: string
  name: string
  address_cache: string
  settings: StoreSettings
  email: string
  address: {
    geom: {
      coordinates: Array<number>
      crs: {
        type: string
        properties: {
          name: string
        }
      }
      type: string
    }
  }
  pre_order_settings: {
    delivery_courier_partner: boolean
    fixed_delivery_times_enabled: boolean
    delivery_interval: number
    delivery_schedule: OperatingSchedules
    delivery_times: 'opening_hours' | 'custom_hours'
  }[]
}

export interface MiscFee {
  id: string
  type:
    | 'waiting_fees'
    | 'courier_discount'
    | 'slerp_adjustment_delivery'
    | 'slerp_adjustment_product'
    | 'slerp_adjustment_total'
  amount: number
  note?: string
  coupon_code?: string
}

export interface OrderDetailsProps {
  order: Order
}

export interface AdditionalCourierType {
  address: {
    city: string
    contact_num: string
    country: string
    flat_number: string | null
    id: string
    line_1: string
    line_2: string
    location_type: string | null
    zip: string
  }
  fulfillment_date: string | null
  job_id: string
  delivery_status: string
  store_id: string
  dropoff_notes: string | null
}

export interface AdditionalCouriersType {
  additionalCouriers: AdditionalCourierType[]
}

export interface Refund {
  id: string
  amount: number
}

export interface Order {
  id: string
  delivery_charge: null | number
  delta_delivery_charge: number
  delivery_charge_reduction: number
  delivery_charge_reduction_reason: string
  delivery_pricing_band: DeliveryPricingBand
  additional_notes: string
  customer_details: CustomerDetailsType
  recipient_details: null | RecipientType
  delivery: null | DeliveryType
  delivery_eta: null | string
  discounts_cache: null | DiscountsCacheType
  dropoff_notes: null | string
  fulfillment_date: null | string
  fulfillment_time_range: null | string
  fulfillment_type: string
  gift_wrap_message: null | string
  gift_wrap_price: number
  inserted_at: string
  merchant: MerchantType
  order_items: Array<OrderItemType>
  order_notes: null | string
  payment: null | PaymentType
  payment_status: null | 'captured' | 'partially_refunded' | 'fully_refunded'
  refunds: Refund[]
  status: string
  store: StoreType
  transaction_id: string
  misc_fees: MiscFee[]
  additional_couriers: AdditionalCourierType[]
  total: number
  subtotal: number
  note_updated_at: string | null
  is_preorder: boolean
  merchant_settings: OrderMerchantSettings
  delivery_vat_charge: number
  delivery_charge_vat: number
  delta_delivery_charge_vat: number
}

export interface OrderMerchantSettings {
  delivery_courier_partner: boolean
  same_day_delivery_courier_partner: boolean
}

export interface DeliveryPricingBand {
  lower_threshold: number
  upper_threshold: number
  percentage_discount: number
}

export enum FEE_TYPE_NAMES {
  waiting_fees = 'Waiting Fee',
  courier_discount = 'Courier credit',
  slerp_adjustment_delivery = 'Slerp Adjustment (Delivery)',
  slerp_adjustment_product = 'Slerp Adjustment (Product)',
  slerp_adjustment_total = 'Slerp Adjustment (Total)'
}
