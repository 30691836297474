import React from 'react'
import { OrderInfo } from '../type'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import RefundButton from './RefundButton'

interface RefundedType {
  order: OrderInfo
  successCallback?: () => void
  setShowRefundForm: (value?: any) => void
}

const Refunded = ({ order, setShowRefundForm }: RefundedType) => {
  return (
    <Row className='_mb-0' justify='end' align='middle' gutter={16}>
      <Col>
        <RefundButton order={order} onClick={() => setShowRefundForm(true)} />
      </Col>
    </Row>
  )
}

export default Refunded
