import { gql } from '@apollo/client'

const QUERY_MERCHANT_INVOICES = gql`
  query listInvoices(
    $merchantId: ID!
    $paymentStatus: InvoicePaymentStatus
    $after: String
    $before: String
    $sortBy: InvoiceSortBy
    $sortDirection: InvoiceSortDirection
  ) {
    listInvoices(
      merchantId: $merchantId
      paymentStatus: $paymentStatus
      after: $after
      before: $before
      sortBy: $sortBy
      sortDirection: $sortDirection
    ) {
      invoices {
        due
        date
        amount
        status
        invoiceReference
        billingPeriodStart
        billingPeriodEnd
        downloadLink
      }
      pagination {
        totalPages
        totalItems
        perPage
        after
        before
      }
    }
  }
`

const QUERY_MERCHANT_SIGNATURE_AND_TERMS = gql`
  query getMerchantSignatureAndTerms($merchantId: uuid!) {
    signatures(
      where: { merchant_id: { _eq: $merchantId } }
      order_by: { inserted_at: desc_nulls_last }
    ) {
      first_name
      last_name
      inserted_at

      term {
        terms_and_conditions
        inserted_at
        updated_at
      }
    }
  }
`

const QUERY_STRIPE_PUBLISHABLE_KEY = gql`
  query getPublishableKey($merchantId: uuid!) {
    getStripePublishableKey(merchantId: $merchantId)
  }
`

const QUERY_MERCHANT_FRANCHISE_ATTRIBUTIONS = gql`
  query franchiseAttribution($merchantId: uuid!) {
    franchiseAttribution(merchantId: $merchantId) {
      delivery
      pickup
      selfDelivery
      nationwideDelivery
      table
      store {
        id
        name
        slug
      }
      user {
        id
        firstname
        lastname
      }
      stripeUserId
      lastUpdatedAt
    }
  }
`

export {
  QUERY_MERCHANT_INVOICES,
  QUERY_MERCHANT_SIGNATURE_AND_TERMS,
  QUERY_STRIPE_PUBLISHABLE_KEY,
  QUERY_MERCHANT_FRANCHISE_ATTRIBUTIONS
}
