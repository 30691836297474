import React from 'react'
import { OrderInfo } from './type'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Button from 'antd/lib/button'
import {
  PendingCTA,
  AcceptedCTA,
  FulfilledCTA,
  RefundedCTA,
  DeliveryStatusCTA,
  PickupStatusCTA
} from './CallToAction'
import { getIcon } from 'components/Orders/Info/utils'
import UserFlow from 'components/Utils/Userflow'
import { OrderPaymentStatusTag } from '../OrderStatuses'

interface OrderActionsType {
  order: OrderInfo
  successCallback: () => void
  setShowRefundForm: () => void
}

const OrderActions = ({
  order,
  successCallback,
  setShowRefundForm
}: OrderActionsType) => {
  const { transaction_id, status } = order
  const { userflow } = UserFlow()

  const print = (orderId: string) => {
    userflow.remount()
    userflow.setBaseZIndex(0)
    const url = `${window.location.origin}/print-order/${orderId}`
    const printPage = window.open(url, '_blank')

    userflow.remount()
    userflow.setBaseZIndex(99999)
    if (printPage) printPage.onafterprint = printPage.close
  }

  return (
    <Row className='order-actions-container'>
      <Col span={24}>
        <Row
          justify='space-between'
          align='middle'
          className='order-actions'
          data-testid='order-info-actions'
        >
          <span
            className='transaction'
            data-testid='order-info-actions-transaction-id'
          >
            Order{' '}
            <span className='id' data-testid='order-details-transaction-no'>
              #{transaction_id}
            </span>
            {order.status !== 'refunded' &&
              order.payment_status === 'partially_refunded' && (
                <div style={{ display: 'flex' }}>
                  {OrderPaymentStatusTag(order.payment_status)}
                </div>
              )}
          </span>
          <Button
            data-testid='order-info-actions-print'
            onClick={() => print(order.transaction_id)}
            className='print'
            type='primary'
            ghost
            icon={getIcon('printer')}
          >
            Print
          </Button>
          <Row className='_mb-0' align='middle'>
            {status === 'pending' && (
              <PendingCTA order={order} successCallback={successCallback} />
            )}
            {status === 'accepted' && (
              <AcceptedCTA
                order={order}
                successCallback={successCallback}
                setShowRefundForm={setShowRefundForm}
              />
            )}
            {status === 'fulfilled' && (
              <FulfilledCTA
                order={order}
                setShowRefundForm={setShowRefundForm}
              />
            )}
            {status === 'refunded' && (
              <RefundedCTA
                order={order}
                successCallback={successCallback}
                setShowRefundForm={setShowRefundForm}
              />
            )}
          </Row>
        </Row>
        <DeliveryStatusCTA order={order} successCallback={successCallback} />
        <PickupStatusCTA order={order} successCallback={successCallback} />
      </Col>
    </Row>
  )
}

export default OrderActions
