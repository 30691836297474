import React from 'react'
import { ReactNode } from 'react'
import { AdditionalCharge, OrderInfo, RefundItem } from './type'
import moment from 'moment-timezone'
import { isEmpty, compact } from 'lodash'
import {
  UserOutlined,
  MailOutlined,
  PhoneOutlined,
  PrinterOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
  FormOutlined,
  ShopOutlined,
  HomeOutlined,
  CarOutlined,
  EnvironmentOutlined,
  SnippetsOutlined,
  PushpinOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons'
import { RadarIcon } from '@slerp/assets'
import { Tooltip } from 'antd'

const FEE_TYPE_NAMES = {
  waiting_fees: 'Waiting fee',
  courier_discount: 'Courier credit',
  slerp_adjustment_delivery: 'Slerp Adjustment (Delivery)',
  slerp_adjustment_product: 'Slerp Adjustment (Product)',
  slerp_adjustment_total: 'Slerp Adjustment (Total)'
}

const ICONS = {
  printer: <PrinterOutlined />,
  person: <UserOutlined />,
  phone: <PhoneOutlined />,
  email: <MailOutlined />,
  store: <ShopOutlined />,
  location: <EnvironmentOutlined />,
  datestamp: <CalendarOutlined />,
  timestamp: <ClockCircleOutlined />,
  note: <FormOutlined />,
  destination: <HomeOutlined />,
  vehicle: <CarOutlined />,
  courier: <EnvironmentOutlined />,
  custom_field: <SnippetsOutlined />,
  pin: <PushpinOutlined />,
  radar: <RadarIcon />
}

export const getIcon = (type: string) => {
  return ICONS[type]
}

export const getRefunds = (order: OrderInfo) => {
  if (isEmpty(order.payment?.data?.refunds?.data)) {
    return []
  }

  const {
    payment: {
      data: { refunds }
    }
  } = order
  const { data: refundsData = [] } = refunds
  let refundItems = (refundsData || []).map((refund) => {
    const { metadata, amount, created } = refund
    const formattedAmount = (amount || 0) / 100
    return {
      name: 'Refund',
      reason: metadata?.reason,
      amount: `-£${formattedAmount.toFixed(2)}`,
      value: formattedAmount,
      created: created && moment.unix(created).format('DD MMM YYYY h:mma')
    }
  })
  return refundItems
}

export const isFullyRefunded = (order: OrderInfo) => {
  const { payment, status } = order
  const refundsTotal = getRefunds(order).reduce(
    (total: number, refundItem: RefundItem) => total + refundItem.value,
    0
  )
  const paymentTotal = (payment?.data?.amount || 0) / 100

  return refundsTotal >= paymentTotal && status === 'refunded'
}

export const isSelfHosted = (order: OrderInfo) => {
  const { isPreOrder } = orderAbilities(order)
  const { merchant_settings, fulfillment_date, inserted_at } = order
  const deliveryFeeRecipent = isPreOrder
    ? merchant_settings.pre_order_delivery_fee_recipient
    : merchant_settings.same_day_delivery_fee_recipient
  const moneyFlowVersion = merchant_settings.money_flow_version

  switch (moneyFlowVersion) {
    // serves as a fallback for when we switch a merchant back to version 1
    case 1:
      if (
        merchant_settings.is_direct_payer ||
        (!fulfillment_date &&
          !merchant_settings.same_day_delivery_courier_partner)
      )
        return true
      if (!fulfillment_date) return merchant_settings.hosts_own_deliveries
      if (
        fulfillment_date &&
        inserted_at &&
        !merchant_settings.same_day_delivery_courier_partner
      )
        return isPreOrder ? merchant_settings.hosts_own_preorders : true

      return isPreOrder
        ? merchant_settings.hosts_own_preorders
        : merchant_settings.hosts_own_deliveries
      break

    case 2:
      switch (deliveryFeeRecipent) {
        case 'default':
          if (merchant_settings.is_direct_payer) return true
          if (!fulfillment_date) return false

          return isPreOrder
            ? !merchant_settings.delivery_courier_partner
            : !merchant_settings.same_day_delivery_courier_partner
          break

        case 'merchant':
          return true
          break

        default:
          // 'slerp'
          return false
      }
      break

    default:
      return false
  }
}

const getSubtotal = (order: OrderInfo): AdditionalCharge | null => {
  const { subtotal, discounts_cache } = order

  if (!subtotal && subtotal !== 0) {
    return null
  }

  const { discount } =
    discounts_cache?.discount_breakdown?.find(({ item }) => item === 'all') ||
    {}

  if (discount) {
    return {
      name: 'Subtotal',
      amountBefore: `£${subtotal.toFixed(2)}`,
      amount: `£${(subtotal - +discount).toFixed(2)}`
    }
  }

  return {
    name: 'Subtotal',
    amount: `£${subtotal.toFixed(2)}`
  }
}

const getDeliveryFee = (order: OrderInfo): AdditionalCharge | null => {
  const { delivery_charge, delivery_charge_reduction } = order

  if (!delivery_charge && delivery_charge !== 0) {
    return null
  }

  if (delivery_charge_reduction) {
    return {
      name: 'Delivery fee',
      description: 'Charged to customer',
      amount: `£${delivery_charge.toFixed(2)}`,
      amountBefore: `£${(delivery_charge + delivery_charge_reduction).toFixed(
        2
      )}`
    }
  }

  return {
    name: 'Delivery fee',
    description: 'Charged to customer',
    amount: `£${delivery_charge.toFixed(2)}`
  }
}

const getStoreFee = (order: OrderInfo): AdditionalCharge | null => {
  const { additional_store_fee, additional_store_fee_cache, discounts_cache } =
    order

  if (additional_store_fee === null || additional_store_fee === undefined) {
    return null
  }

  const { discount, amount } =
    discounts_cache?.discount_breakdown?.find(
      ({ item }) => item === 'store_fee'
    ) || {}

  if (discount && amount) {
    return {
      name: 'Additional store fee',
      description: additional_store_fee_cache?.name || '',
      amountBefore: `£${(+amount).toFixed(2)}`,
      amount: `£${(+amount - +discount).toFixed(2)}`
    }
  }

  return {
    name: 'Additional store fee',
    description: additional_store_fee_cache?.name || '',
    amount: `£${additional_store_fee.toFixed(2)}`
  }
}

export const getAdditionalCharges = (order: OrderInfo): AdditionalCharge[] => {
  const {
    tip_value,
    delta_delivery_charge,
    gift_wrap_price,
    gift_wrap_message,
    misc_fees
  } = order

  const charges = [
    getSubtotal(order),
    getDeliveryFee(order),
    getStoreFee(order)
  ]

  if (gift_wrap_price && gift_wrap_price > 0) {
    charges.push({
      name: 'Gifting',
      description: gift_wrap_message,
      amount: `£${gift_wrap_price.toFixed(2)}`
    })
  }
  if (misc_fees && !isEmpty(misc_fees)) {
    misc_fees.forEach((fee) => {
      const prefix = fee.amount < 0 ? '-£' : '£'
      charges.push({
        name: FEE_TYPE_NAMES[fee.type],
        description: fee.note || '',
        amount: `${prefix}${Math.abs(fee.amount)}`
      })
    })
  }
  if (isTipsEnabledOnOrders(order)) {
    charges.push({
      name: 'Tips',
      description: 'Customer Tips',
      amount: `£${tip_value.toFixed(2)}`
    })
  }

  return charges.filter(Boolean) as AdditionalCharge[]
}

export const isTipsEnabledOnOrders = (order: OrderInfo) => {
  const {
    store: { settings },
    fulfillment_type,
    metadata
  } = order

  const {
    delivery_tipping_enabled,
    pickup_tipping_enabled,
    oat_tipping_enabled
  } = settings

  if (fulfillment_type === 'delivery') return delivery_tipping_enabled
  if (fulfillment_type === 'pickup') {
    if (metadata?.pickup_type && metadata.pickup_type === 'table')
      return oat_tipping_enabled
    return pickup_tipping_enabled
  }
}

export const formatFulfillmentDate = (order: OrderInfo) => {
  const { fulfillment_time_range, fulfillment_date, inserted_at } = order

  if (fulfillment_time_range === 'ASAP')
    return moment(inserted_at).format('ddd MMM DD YYYY')
  if (fulfillment_date)
    return moment(fulfillment_date).format('ddd MMM DD YYYY')
  return 'N/A'
}

export const formatFulfillmentTime = (order: OrderInfo) => {
  const { fulfillment_time_range } = order

  return fulfillment_time_range === 'ASAP' ? 'ASAP' : fulfillment_time_range
}

export const orderAbilities = (order: OrderInfo) => {
  const {
    status,
    merchant_settings,
    fulfillment_time_range,
    fulfillment_date,
    inserted_at,
    fulfillment_type
  } = order
  const {
    hosts_own_deliveries,
    same_day_delivery_courier_partner,
    delivery_courier_partner
  } = merchant_settings
  const isPreOrderUnderScp = !!delivery_courier_partner
  const isSamedayUnderScp = !!same_day_delivery_courier_partner
  const isAsap = fulfillment_time_range === 'ASAP'
  const deliveryStatus = order.delivery?.delivery_status
  const isPreOrder =
    fulfillment_date &&
    !!!moment
      .tz(fulfillment_date, 'YYYY-MM-DD', 'Europe/London')
      .isSame(moment.tz(inserted_at, 'YYYY-MM-DD', 'Europe/London'), 'day')
  const isSamedayOrder =
    fulfillment_date &&
    !!moment
      .tz(fulfillment_date, 'YYYY-MM-DD', 'Europe/London')
      .isSame(moment.tz(inserted_at, 'YYYY-MM-DD', 'Europe/London'), 'day')
  const isOrderUnderScp =
    fulfillment_type !== 'pickup' && (isSamedayOrder || isAsap)
      ? isSamedayUnderScp
      : isPreOrderUnderScp

  const finalStatusAdditionalCouriers = order.additional_couriers?.some(
    (additional_courier) => {
      return [
        'scheduled',
        'picking',
        'almost_picking',
        'delivering',
        'driver_delivering',
        'almost_delivering',
        'delivered',
        'failed'
      ].includes(additional_courier.delivery_status)
    }
  )

  return {
    isAsap,
    isPreOrder,
    canManageAdditionalNotes: status !== 'pending',
    canManageCourierBookings: ['accepted', 'fulfilled'].includes(status),
    canCancelCourier: !hosts_own_deliveries && isOrderUnderScp,
    canManageFulfillmentDate:
      (isPreOrder || isSamedayOrder) &&
      !isAsap &&
      ['pending', 'accepted', 'searching'].includes(status) &&
      ![
        'scheduled',
        'picking',
        'almost_picking',
        'delivering',
        'driver_delivering',
        'almost_delivering',
        'delivered',
        'failed'
      ].includes(deliveryStatus) &&
      fulfillment_type === 'delivery' &&
      !finalStatusAdditionalCouriers
  }
}

export const formatFullname = (firstname: string, lastname: string) => {
  return compact([firstname, lastname]).join(' ')
}

export const conditionalTooltip = (
  content: ReactNode,
  tooltip: ReactNode | string,
  condition: boolean
): ReactNode => {
  return condition ? <Tooltip title={tooltip}>{content}</Tooltip> : content
}

export const getDeliveryCharge = (order: OrderInfo) => {
  const { delivery_charge } = order

  if (!delivery_charge) return 0

  return delivery_charge
}
