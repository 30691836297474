import React from 'react'
import { Tabs } from 'antd'
import { useHistory } from 'react-router-dom'
import styled from '@emotion/styled'

const { TabPane } = Tabs

const SettingsSidebar = () => {
  const history = useHistory()

  return (
    <>
      <Tabs
        type='card'
        defaultActiveKey={window.location.pathname.replace('/settings/', '')}
        className='_mb-8'
        onTabClick={(key) => {
          history.push(`/settings/${key}`)
        }}
      >
        <TabPane key='general' tab={<TabTitle>General</TabTitle>} />
        <TabPane key='delivery' tab={<TabTitle>Delivery</TabTitle>} />
        <TabPane
          key='accounts-and-billing'
          tab={<TabTitle>Accounts and Billing</TabTitle>}
        />
        <TabPane
          key='analytics'
          tab={<TabTitle>Marketing and Analytics</TabTitle>}
        />
        <TabPane key='admin_users' tab={<TabTitle>Users</TabTitle>} />
        <TabPane
          key='terms-and-conditions'
          tab={<TabTitle>Terms and Conditions</TabTitle>}
        />
      </Tabs>
    </>
  )
}

const TabTitle = styled.span`
  font-size: 14px !important;
`

export default SettingsSidebar
