import React, { useState } from 'react'
import { useQuery, useMutation, useApolloClient } from '@apollo/client'
import { GET_ARCHIVED_PRODUCT_OPTIONS, RESTORE_OPTION } from '../ProductQueries'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Button from 'antd/lib/button'
import message from 'antd/lib/message'
import Collapse from 'antd/lib/collapse'
import capitalize from 'lodash/capitalize'
import ReloadOutlined from '@ant-design/icons/ReloadOutlined'
import Loading from '../../../Utils/Loading'

interface ArchivedProductVariantsListProps {
  productId: string
}

interface ArchivedOption {
  name: string
  values: string[]
}

const { Panel } = Collapse

const ArchivedProductVariantsList = ({
  productId
}: ArchivedProductVariantsListProps) => {
  const client = useApolloClient()
  const [archivedOptions, setArchivedOptions] = useState<Array<ArchivedOption>>(
    []
  )
  const [restoreOption, { loading: restoringOption }] =
    useMutation(RESTORE_OPTION)
  const { loading: fetchingArchivedProductOptions } = useQuery(
    GET_ARCHIVED_PRODUCT_OPTIONS,
    {
      variables: {
        productId
      },
      onCompleted: (result) => {
        const { getArchivedProductOptions } = result

        if (!getArchivedProductOptions) return
        setArchivedOptions(getArchivedProductOptions)
      }
    }
  )

  const handleRestore = (optionName: string, optionValue: string) => {
    message.destroy()
    message.loading('Restoring product option..', 1)
    restoreOption({
      variables: {
        productId,
        optionName,
        optionValue
      }
    })
      .then((result) => {
        client.resetStore()
        message.destroy()
        message.success(`The option ${optionValue} has been restored.`, 3)
        client.resetStore()
      })
      .catch((error: Error) => {
        message.destroy()
        message.error(
          'Unable to restore options as you have reached the maximum number of variant groups',
          3
        )
      })
  }

  return (
    <>
      {fetchingArchivedProductOptions ? (
        <Loading />
      ) : (
        <Collapse>
          {archivedOptions.map((option, index) => (
            <Panel header={capitalize(option.name)} key={index}>
              {option.values.map((v) => (
                <Row justify='space-between' align='middle'>
                  <Col>{v}</Col>
                  <Col>
                    <Button
                      data-testid={`restore-product-variant-${option.name}-${v}`}
                      size='small'
                      disabled={restoringOption}
                      loading={restoringOption}
                      icon={<ReloadOutlined />}
                      onClick={() => handleRestore(option.name, v)}
                    />
                  </Col>
                </Row>
              ))}
            </Panel>
          ))}
        </Collapse>
      )}
    </>
  )
}

export default ArchivedProductVariantsList
