import React, { useState, useEffect } from 'react'
import { LoyaltyCustomer } from './LoyaltyOverview'
import { Button, Modal, Form, Select, message } from 'antd'
import { useMutation, useQuery } from '@apollo/client'
import {
  QUERY_EXCLUSIVE_LOYALTY_CARDS,
  ADD_EXCLUSIVE_CARDS_TO_CUSTOMER
} from './LoyaltyQueries'
import { requiredRule } from './Rules'
import { useSlerp } from 'packages/@slerp/client'
import { LoyaltyCardType } from './types'

const { useForm } = Form

interface ExclusiveCardModalProps {
  customer: LoyaltyCustomer | null
  visible: boolean
  onClose: () => void
}

const ExclusiveCardModal = (props: ExclusiveCardModalProps) => {
  const { customer, visible, onClose } = props
  const [form] = useForm()
  const { user } = useSlerp()
  const [exclusiveCards, setExclusiveCards] = useState<LoyaltyCardType[]>([])
  const [initial, setInitial] = useState<string[]>([])

  const { data: loyaltyCards } = useQuery(QUERY_EXCLUSIVE_LOYALTY_CARDS, {
    variables: {
      merchant_id: user.merchant.id
    },
    fetchPolicy: 'no-cache'
  })

  const [addCards, { loading: addCardsLoading }] = useMutation(
    ADD_EXCLUSIVE_CARDS_TO_CUSTOMER,
    {
      fetchPolicy: 'no-cache'
    }
  )

  useEffect(() => {
    if (loyaltyCards?.loyalty_cards?.length) {
      setExclusiveCards(loyaltyCards.loyalty_cards)
      setInitial(
        loyaltyCards.loyalty_cards
          .filter((lc: LoyaltyCardType) =>
            lc.exclusive_loyalty_customers.some(
              (elc) => elc.customer_id === customer?.id
            )
          )
          .map((lc: LoyaltyCardType) => lc.id)
      )
    }
  }, [loyaltyCards, customer])

  const modalTitle = (
    <div className='qr-modal-header'>
      <h2 className='title'>Configure exclusive loyalty card</h2>
    </div>
  )

  const modalFooter = [
    <Button type='ghost' onClick={onClose}>
      Close
    </Button>,
    <Button
      key='submit'
      htmlType='submit'
      onClick={() => form.submit()}
      loading={addCardsLoading}
    >
      Update
    </Button>
  ]

  const onFinish = ({ exclusive_card_ids }: { exclusive_card_ids: string }) => {
    addCards({
      variables: {
        customerId: customer?.id,
        loyaltyCardIds: exclusive_card_ids,
        merchantId: user.merchant.id,
        userId: user.id
      }
    })
      .then((res) => {
        message.destroy()
        message.success('Successfully updated exclusive cards')
      })
      .catch((err) => {
        message.destroy()
        if (err.message === 'exclusive_card_not_found') {
          message.error(
            `The loyalty card you have selected cannot be used, as it has expired. Please update the expiry date.`
          )
        } else {
          message.error(`Unable to update exclusive cards due to ${err}`)
        }
      })
  }

  return (
    <Modal
      onCancel={onClose}
      visible={visible}
      destroyOnClose
      className='loyalty-qr-modal'
      afterClose={() => form.resetFields()}
      title={modalTitle}
      footer={modalFooter}
    >
      <Form
        className='_pl-4'
        form={form}
        onFinish={onFinish}
        layout='vertical'
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 20 }}
      >
        <Form.Item
          name='exclusive_card_ids'
          label='Select/de-select exclusive loyalty card:'
        >
          <Select
            mode='multiple'
            allowClear
            className='_mt-10'
            placeholder='Select card'
            defaultValue={initial}
          >
            {exclusiveCards.map((card: LoyaltyCardType) => (
              <Select.Option key={card.id} value={card.id}>
                {card.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ExclusiveCardModal
