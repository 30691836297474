import React from 'react'
import { Collapse, Tag } from 'antd'
import PickupDetails from './PickupDetails'
import { OrderInfo } from '../../type'

const TAG_COLOR = {
  pending: 'orange',
  preparing: 'orange',
  ready_for_collection: 'blue',
  collected: 'green'
}

const PICKUP_STATUS = {
  pending: 'Pending',
  preparing: 'Preparing',
  ready_for_collection: 'Ready for collection',
  collected: 'Collected'
}

interface PickupInformationProps {
  order: OrderInfo
}

const { Panel } = Collapse

const PickupInformation = ({ order }: PickupInformationProps) => {
  return (
    <Collapse defaultActiveKey={['pickupDetails']} className='-plain'>
      <Panel
        header='Pickup details'
        extra={
          order.pickup_status ? (
            <Tag
              color={TAG_COLOR[order.pickup_status] || 'orange'}
              className='-plain_bg'
              style={{ textTransform: 'none' }}
            >
              {PICKUP_STATUS[order.pickup_status] || 'Pending'}
            </Tag>
          ) : null
        }
        key='pickupDetails'
        className='-compact'
      >
        <PickupDetails order={order} />
      </Panel>
    </Collapse>
  )
}

export default PickupInformation
