import React, { useEffect, useState } from 'react'
import { useSubscription } from '@apollo/client'
import { uuid } from 'uuidv4'
import { Tabs } from 'antd'
import { Result, Skeleton } from 'antd'
import OrderSteps from './OrderSteps'
import OrderActions from './OrderActions'
import {
  ChargesInformation,
  BasicInformation,
  DeliveryInformation,
  PaymentInformation,
  PickupInformation
} from './Tab'
import { SUBSCRIPTION_ORDER } from 'components/Orders/OrderQueries'
import { OrderInfo, PickupStatuses } from './type'
import RefundForm from 'components/Orders/RefundForm'
import { isFullyRefunded } from './utils'

interface OrderInformationProps {
  transactionId: string
  refetchCallback: () => void
  setFooterEntries?: (entries: React.ReactNode | React.ReactNode[]) => void
}

const { TabPane } = Tabs
const OrderInformation = ({
  transactionId,
  refetchCallback,
  setFooterEntries
}: OrderInformationProps) => {
  const [order, setOrder] = useState<OrderInfo>(null)
  // This is used to force a re-render of the order when the order is updated
  const [orderKey, setOrderKey] = useState<string>('0')
  const [showRefundForm, setShowRefundForm] = useState<boolean>(false)
  const { data: orderData, loading: isLoadingOrderData } = useSubscription(
    SUBSCRIPTION_ORDER,
    {
      variables: { transactionId },
      onSubscriptionData: ({ subscriptionData }) => {
        const newOrder = subscriptionData.data.orders[0]

        // If status or delivery status changed, update the key to force OrderSteps re-render
        if (
          order &&
          (order.status !== newOrder.status ||
            order.delivery?.delivery_status !==
              newOrder.delivery?.delivery_status)
        ) {
          setOrderKey((prev) => String(Number(prev) + 1))
        }

        setOrder(newOrder)
      }
    }
  )

  const handleTabsChange = (activeKey: string) => {
    if (!setFooterEntries) {
      return
    }

    if (activeKey === '4') {
      setFooterEntries(
        <p className='order-drawer-footer'>
          * Download the Additional Charges report for more details
        </p>
      )

      return
    }

    setFooterEntries(null)
  }

  if (isLoadingOrderData) {
    return <Skeleton active />
  }

  const isDeliveryTwoRow =
    order?.delivery?.service === 'merchant' &&
    order?.delivery?.delivery_status !== 'fulfilled manually'

  const isPickupTwoRow =
    order?.fulfillment_type === 'pickup' &&
    order?.pickup_status !== PickupStatuses.collected

  return (
    <>
      {orderData && orderData.message === null && (
        <Result status='500' title='500' />
      )}
      {order && (
        <>
          <div className='order-header' data-testid='order-info-steps-actions'>
            <OrderSteps order={order} key={orderKey} />
            <OrderActions
              order={order}
              successCallback={() => {
                refetchCallback()
              }}
              setShowRefundForm={setShowRefundForm}
            />
          </div>
          {showRefundForm && (
            <RefundForm
              key={uuid()}
              discardCallback={() => setShowRefundForm(false)}
              transactionId={order.transaction_id}
              deliveries={[order.delivery, ...order.additional_couriers]}
              successCallback={() => {
                setShowRefundForm(false)
                refetchCallback()
              }}
            />
          )}
          <Tabs
            className={
              !isFullyRefunded(order) &&
              (isDeliveryTwoRow || isPickupTwoRow) &&
              !['rejected', 'pending'].includes(order.status || '')
                ? '_mt-80'
                : '_mt-14'
            }
            defaultActiveKey='1'
            data-testid='order-info-tabs'
            onChange={handleTabsChange}
          >
            <TabPane tab='Info' key='1'>
              <BasicInformation
                order={order}
                reloadOrder={() => {
                  refetchCallback()
                }}
              />
            </TabPane>
            {order.fulfillment_type === 'delivery' && (
              <TabPane
                tab='Delivery'
                key='2'
                data-testid='order-info-tabs-delivery'
              >
                <DeliveryInformation
                  order={order}
                  reloadOrder={() => {
                    refetchCallback()
                  }}
                />
              </TabPane>
            )}
            {order.fulfillment_type === 'pickup' && (
              <TabPane tab='Pickup' key='2'>
                <PickupInformation order={order} />
              </TabPane>
            )}
            <TabPane tab='Payment' key='3'>
              <PaymentInformation
                order={order}
                reloadOrder={() => {
                  refetchCallback()
                }}
              />
            </TabPane>
            <TabPane tab='Additional Charges' key='4'>
              <ChargesInformation
                order={order}
                reloadOrder={() => {
                  refetchCallback()
                }}
              />
            </TabPane>
          </Tabs>
        </>
      )}
    </>
  )
}

export default OrderInformation
