import React from 'react'
import { Layout } from 'antd'
import { useLocation } from 'react-router-dom'
import { CONTENT_X_MARGIN_DESKTOP } from './constants'
import { CONTENT_X_MARGIN } from './constants'
import styled from '@emotion/styled'

const { Content } = Layout

interface Props {
  children: React.ReactNode
}

const LayoutContent = ({ children }: Props) => {
  const location = useLocation()

  const isAvailabilityRulesForm =
    location?.pathname?.endsWith('/availability-rules/create') ||
    (location?.pathname?.includes('/availability-rules/') &&
      location.pathname.split('/availability-rules/rule/')[1]?.length > 0)

  const fullView = location.pathname.match('designer|help-centre')

  const isAppStore = location?.pathname?.includes('/mobile-app/app-store')
  const isDesigner = location?.pathname?.includes('/designer')

  return (
    <StyledContent
      className={`site-content -main ${
        (window.location.pathname === '/' ||
          window.location.pathname === '/heat-map') &&
        '-dashboard'
      }`}
      style={{
        padding: fullView ? '0' : 24,
        overflow: fullView ? 'hidden' : 'auto',
        display: 'inline-block',
        background: `${
          isAvailabilityRulesForm || location?.pathname === '/'
            ? 'transparent'
            : '#fff'
        }`,
        paddingTop: isAvailabilityRulesForm || isDesigner ? '0' : '24px',
        marginBottom: isAppStore ? '0' : '24px'
      }}
    >
      {children}
    </StyledContent>
  )
}

const StyledContent = styled(Content)`
  margin: 24px ${CONTENT_X_MARGIN}px;

  @media (min-width: 1440px) {
    margin: 48px ${CONTENT_X_MARGIN_DESKTOP}px;
  }
`

export default LayoutContent
