import React from 'react'
import { useQuery } from '@apollo/client'
import { STRIPE_CONNECT_OAUTH_ENDPOINT } from '@slerp/client'
import { QUERY_STRIPE_CONNECT_URL } from './actions'
import { Merchant } from 'types'
import { StripeConnect } from '../Utils/Stripe'

const StripeConnectSettings = (props: { merchant: Merchant }) => {
  const { merchant } = props

  const redirect_uri = STRIPE_CONNECT_OAUTH_ENDPOINT

  const { data, loading: connectLoading } = useQuery(QUERY_STRIPE_CONNECT_URL, {
    variables: {
      redirectUri: redirect_uri,
      merchantId: merchant.id
    },
    fetchPolicy: 'no-cache'
  })

  return (
    <StripeConnect
      stripeId={merchant?.stripe_settings?.[0]?.stripe_user_id}
      loading={connectLoading}
      hasStripeCustomerId={!!merchant?.subscription?.stripe_customer_id}
      stripeConnectUrl={data?.stripeConnectUrl}
    />
  )
}

export default StripeConnectSettings
